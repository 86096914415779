import { Component, OnChanges, Input } from '@angular/core';
import { AllCategories, Category, Unit, CategoryGuideline } from '../api.abstract.service';
import { get } from 'lodash-es';
import { guideToWidth } from '../util';
``
@Component({
  selector: 'app-pyramide-vertical',
  templateUrl: './pyramide-vertical.component.html',
  styleUrls: ['./pyramide-vertical.component.scss']
})
export class PyramideVerticalComponent implements OnChanges {

  @Input()
  units: Unit[];

  @Input()
  guidelines: CategoryGuideline[];

  segments: {
    category: Category,
    value: number,
    width: string;
    guideLeft: string;
    guideWidth: string;
    min: number;
    max: number;
  }[];

  constructor() { }

  ngOnChanges() {
    this.segments = [];
    for (const category of AllCategories) {
      const guideline = this.guidelines.find(g => g.category === category);
      const value = get((this.units || []).find(u => u[0] === category), 1, 0);
      const min = get(guideline, 'min');
      const max = get(guideline, 'max');

      this.segments.push({
        category,
        value,
        width: guideToWidth(value, max),
        guideLeft: guideToWidth(min, max),
        guideWidth: guideToWidth(max - min, max),
        min,
        max
      });
    }
  }
}
