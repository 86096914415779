import { Pipe, PipeTransform } from '@angular/core';
import { GroupType } from './api.abstract.service';

@Pipe({
  name: 'groupType'
})
export class GroupTypePipe implements PipeTransform {

  protected names = new Map<GroupType, string>([
    [GroupType.Kindergarten, 'Vrtec'],
    [GroupType.ElementarySchool, 'Osnovna šola'],
    [GroupType.MiddleSchool, 'Srednja šola'],
    [GroupType.Adult, 'Odrasli'],
  ]);

  transform(value: GroupType): string {
    return this.names.get(value);
  }

}
