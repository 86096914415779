<div *ngIf="visible" class="container">
  <div class="dialog">
    <button class="btn-close" (click)="visible = false"></button>
    <h1>
      Kopiraj od
      <select (change)="setSourceYear($event)">
        <option *ngFor="let year of years$ | async" [selected]="year.yearId === sourceYearId" [value]="year.yearId">{{ year.year }}/{{ year.year + 1 }}</option>
      </select>
    </h1>
    <div class="content-container">
      <div class="month-container">
        <div class="month-picker">
          <button type="button" [class.invisible]="!prevMonth" (click)="setMonth(prevMonth)"></button>
          {{ monthStr }}
          <button type="button" [class.invisible]="!nextMonth" (click)="setMonth(nextMonth)"></button>
        </div>
        <div class="month">
          <div class="head" *ngFor="let day of weekDays">
            {{ day }}
          </div>
          <ng-container *ngFor="let day of availableDates; let index = index">
            <div class="day" [class.available]="day.available" [class.current]="day.current" (click)="setDate(day.day)" [class.selected]="selectedDay == day.day && day.current">
              {{ day.day }}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="menu-container">
        <div class="menu-title">Jedilnik</div>
        <ng-container *ngIf="mealSize">
          <div class="size-picker">
            <button type="button" [class.invisible]="availableSizes.indexOf(mealSize) == 0" (click)="setMealSize('prev')"></button>
            {{ mealSize | mealSize : 'name' }}:
            {{ mealSize | mealSize : 'ageGroup' }}
            <button type="button" [class.invisible]="availableSizes.indexOf(mealSize) == availableSizes.length - 1" (click)="setMealSize('next')"></button>
          </div>
          <div class="meals">
            <ng-container *ngFor="let meal of availableMeals">
              <div>{{ meal.mealCode | mealName: true }}</div>
              <div>
                <ng-container *ngFor="let food of meal.foods">
                  {{ food }}
                </ng-container>
              </div>
            </ng-container>
          </div>
          <button class="copy" type="button" (click)="submit()">Kopiraj</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
