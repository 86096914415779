import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CheckboxArrayOption } from '@app/shared';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio-array',
  templateUrl: './radio-array.component.html',
  styleUrls: ['./radio-array.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioArrayComponent),
      multi: true
    }
  ]
})
export class RadioArrayComponent implements ControlValueAccessor, OnChanges, OnInit {

  @ViewChild('container', {static: true})
  containerEl: ElementRef<HTMLDivElement>;

  @Input()
  options: CheckboxArrayOption[] = [];

  @Input()
  columns = 1;

  @Input()
  horizontal = false;

  @Input()
  disabled = false;

  @Output()
  select = new EventEmitter<any>();

  selected: any;

  name = 'form_' + Math.random().toString().replace('.', '');

  style = {};

  protected changeListeners = [];

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    if (this.columns) {
      this.style = {columns: this.columns};
    } else {
      this.style = {};
    }
  }

  onChange(value) {
    this.selected = value;
    this.changeListeners.forEach(cb => cb(value));
    this.select.emit(value);
  }

  writeValue(obj: any): void {
    this.selected = obj;
  }

  registerOnChange(fn: any): void {
    this.changeListeners = [fn];
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  scrollSelectedIntoView() {
    // NOTE: using this cause sticky issues with main-with-aside
    // const index = this.options.findIndex(opt => 'value' in opt && this.selected === opt.value);
    // if (index === -1 || !this.containerEl?.nativeElement) {
    //   return;
    // }
    //
    // const labelElements = this.containerEl.nativeElement.querySelectorAll('label');
    // if (index < labelElements.length) {
    //   labelElements.item(index).scrollIntoView({
    //     block: 'center'
    //   });
    // }
  }
}
