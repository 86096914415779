<label #host [class.variant-add]="variant === 'add'" [class.variant-search]="variant === 'search'">
  <div class="form-label">
    {{ label }}
    <span class="help-tooltip" [tooltip]="help" *ngIf="help"></span>
  </div>
  <div class="search-input">
    <input #search type="text" (keyup)="keyUp($event)" (keydown)="keyDown($event)" (blur)="blur()">
  </div>
  <ul #dropdown>
    <ng-container *ngFor="let result of results; let i = index">
      <li (click)="click(result)" [class.selected]="i === current">
        <div class="name">
          <span class="name--name">{{ result.name }}</span>
          <span class="name--unit">na 100 g</span>
        </div>
        <div class="values">
          <div><span>E</span>{{ result.componentEnergy / 4184 | number:'1.0-2' }} kcal</div>
          <div><span>M</span>{{ result.componentFat | number:'1.0-2' }} g</div>
          <div><span>OH</span>{{ result.componentTotalCarbohydrates | number:'1.0-2' }} g</div>
          <div><span>B</span>{{ result.componentProtein | number:'1.0-2' }} g</div>
        </div>
      </li>
    </ng-container>
    <ng-container *ngIf="results?.length === 0">
      <li>Ni zadetkov</li>
    </ng-container>
  </ul>
</label>
