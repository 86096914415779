import { Pipe, PipeTransform } from '@angular/core';
import { DietDetails } from '@app/shared/api.abstract.service';
import { DietCombinationPipe } from '@app/shared/diet-combination.pipe';

@Pipe({
  name: 'dietDetails'
})
export class DietDetailsPipe implements PipeTransform {

  constructor(protected dietCombination: DietCombinationPipe) {
  }

  transform(value: DietDetails, noDiet = 'Brez diet', long = true): string {
    if (value.noDiet) {
      return noDiet;
    } else {
      return  value.name;
    }
  }

}
