import { Component, OnChanges, Input, } from '@angular/core';
import { isValueOutside } from '../util';

@Component({
  selector: 'app-segmented-bar',
  templateUrl: './segmented-bar.component.html',
  styleUrls: ['./segmented-bar.component.scss']
})
export class SegmentedBarComponent implements OnChanges {

  @Input()
  value: number;

  @Input()
  min = 0;

  @Input()
  max: number;

  @Input()
  color: string;

  @Input()
  empty = false;

  ticks: string[];

  width: string;

  isOutside: boolean;

  constructor() { }

  ngOnChanges() {
    let divisor = Math.pow(10, Math.floor(Math.log10(this.max)));

    while (this.max / divisor < 5) {
      divisor /= 2;
    }
    while (this.max / divisor > 5) {
      divisor *= 2;
    }

    let i = divisor;
    this.ticks = [];
    while (i < this.max) {
      this.ticks.push(`${100 * i / this.max}%`);
      i += divisor;
    }

    this.width = `${100 * Math.min(this.value, this.max) / this.max}%`;

    this.isOutside = !this.empty && isValueOutside(this.value, this.min, this.max);
  }

}
