import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AllGroupTypes,
  ApiService,
  CreateGroupRequest,
  GroupResponse,
  SubscriptionSink,
  UpdateGroupRequest
} from '@app/shared';
import { GroupFormComponent, GroupFormValues } from '../group-form/group-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { ErrorComponent } from '@app/shared/error/error.component';
import { EMPTY } from 'rxjs';
import { minBy } from 'lodash-es';
import { SubmitButtonComponent } from '@app/shared/submit-button/submit-button.component';

@Component({
  selector: 'app-group-editor',
  templateUrl: './group-editor.component.html',
  styleUrls: ['./group-editor.component.scss']
})
export class GroupEditorComponent implements OnInit, OnDestroy {

  groups: GroupResponse[];

  editGroup: GroupResponse;

  yearId: number;

  isNew = false;

  @ViewChild(ErrorComponent, { static: true })
  error: ErrorComponent;

  @ViewChild('form', { static: false })
  formComponent: GroupFormComponent;

  @ViewChild('submit', {static: false})
  submitButton: SubmitButtonComponent;

  protected subscription = new SubscriptionSink();

  protected isDirty = false;

  constructor(protected api: ApiService, protected route: ActivatedRoute, protected router: Router) {
  }

  ngOnInit() {
    this.subscription.sink = this.route
      .data
      .pipe(
        tap((data: { groups?: GroupResponse[], editGroup?: [GroupResponse[], GroupResponse], yearId: number, isNew: boolean }) => {
          const { groups, editGroup, yearId } = data;

          let usedGroups: GroupResponse[] = [];
          if (editGroup) {
            usedGroups = editGroup[0];
            this.editGroup = editGroup[1];
          } else if (groups) {
            usedGroups = groups;
            this.editGroup = undefined;
          }

          this.groups = usedGroups;
          this.yearId = yearId;
          this.isNew = data.isNew;

          this.error.reset();
        }),
        switchMap(() => this.api.getHelpVisible('groups')),
        debounceTime(250),
        switchMap(visible => {
          if (visible || this.editGroup || this.isNew) {
            return EMPTY;
          }
          if (this.groups.length > 0) {
            const firstGroup = minBy(this.groups, g => AllGroupTypes.indexOf(g.type));
            return this.router.navigate(['groups', 'edit', firstGroup.id]);
          }
          return this.router.navigate(['groups', 'add']);
        })
      )
      .subscribe();

    this.subscription.sink = this.api.group$
      .pipe(
        switchMap(() =>
          this.api.calendarGetGroups({ yearId: this.yearId })
        ),
        tap(groups => this.groups = groups)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  insert(values: GroupFormValues) {
    this.error.reset();
    const params: CreateGroupRequest = {
      yearId: this.yearId,
      ...values,
    };
    this.subscription.sink = this.api
      .calendarCreateGroup(params)
      .pipe(this.error.operator({button: this.submitButton}))
      .subscribe(rv => {
        this.setDirty(false);
        this.router.navigate(['/groups/edit', rv.id])
      });
  }

  update(group: GroupResponse, values: GroupFormValues) {
    this.error.reset();
    const params: UpdateGroupRequest = {
      id: group.id,
      ...values
    };

    this.subscription.sink = this.api
      .calendarUpdateGroup(params)
      .pipe(this.error.operator({button: this.submitButton}))
      .subscribe(rv => {
        this.setDirty(false);
        if (this.editGroup?.id === rv.id) {
          this.editGroup = rv;
        } else {
          this.router.navigate(['/groups/edit', group.id])
        }
      });
  }

  cancelCreate() {
    this.router.navigate(['/groups']);
  }

  delete(group: GroupResponse) {
    if (!confirm(`Ste prepričani da želite izbrisati skupino ${group.name}?`)) {
      return;
    }
    this.subscription.sink = this.api.calendarDeleteGroup(group.id)
      .subscribe(() => {
        this.setDirty(false);
        this.router.navigate(['/groups']);
      });
  }

  setDirty(d: boolean) {
    this.isDirty = d;
    this.submitButton?.setStatus(d ? 'dirty' : 'initial');
  }

  checkDirty() {
    if  (!this.isDirty) {
      return true;
    }

    if  (confirm('Spremembe niso shranjene.\nKliknite prekliči, če želite nadaljevati z urejanjem.\nS klikom na potrdi bodo spremembe izgubljene.')) {
      this.isDirty = false;
      return true;
    }

    return false;
  }
}
