<form [formGroup]="form" (submit)="submit()" class="user-layout-form">
  <div class="instr">
    Pred začetkom uporabe si izberite geslo s katerim boste v prihodnje dostopali do aplikacije PISKR.
  </div>
  <input placeholder="Geslo" type="password" formControlName="password">
  <input placeholder="Geslo znova" type="password" formControlName="password2">
  <button type="submit" [disabled]="form.invalid">
    <i class="icon-accept_small"></i>
  </button>
  <div class="links">
    <a href="mailto:solskilonec@nijz.si">Kontakt</a>
    <a [routerLink]="['/login']">Nazaj na prijavo</a>
  </div>
</form>
