import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ApiService,
  AVAILABLE_MONTHS,
  CalendarYearResponse,
  Day,
  mapCalendarYearToDays,
  SubscriptionSink
} from '@app/shared';
import * as dayjs from 'dayjs';
import { tap } from 'rxjs/operators';
import { Observable, zip } from 'rxjs';

@Component({
  selector: 'app-calendar-editor',
  templateUrl: './calendar-editor.component.html',
  styleUrls: ['./calendar-editor.component.scss']
})
export class CalendarEditorComponent implements OnInit, OnDestroy {

  month: number;

  index: number;

  prev: number;

  next: number;

  allDays: Day[][];

  year: number;

  yearId: number;

  maxYear: number;

  help$: Observable<boolean>;

  showCreate = false;

  protected subscription = new SubscriptionSink();

  constructor(protected apiService: ApiService, protected route: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscription.sink = zip(
      this.route.data,
      this.route.paramMap
    )
      .pipe(tap(([{ year }, params]) => {
        this.setYear(year);

        let month = ~~params.get('month');
        if (!month) {
          month = (new Date()).getMonth() + 1;
        }
        this.selectMonth(month);
      }))
      .subscribe();

    this.help$ = this.apiService.getHelpVisible('calendar');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectMonth(month: number) {
    this.month = month;
    this.index = AVAILABLE_MONTHS.findIndex(m => m === month);
    if (this.index > 0) {

      this.prev = AVAILABLE_MONTHS[this.index - 1];
    } else {
      this.prev = 0;
    }
    if (this.index === this.allDays.length - 1) {
      this.next = 0;
    } else {
      this.next = AVAILABLE_MONTHS[this.index + 1];
    }
  }

  setActive([date, active]: [string, boolean]) {
    this.allDays.some(month => {
      const day = month.find(d => d.date === date);
      if (day) {
        day.active = active;
        return true;
      }
      return false;
    });

    // this.subscription.sink = this.apiService.calendarSetWorkday({
    //   yearId: this.yearId,
    //   date,
    //   isWorkday: active
    // })
    //   .pipe(
    //     map(response => this.mapCalendarYearToDays(response)),
    //     tap(allDays => this.allDays = allDays)
    //   )
    //   .subscribe();
  }

  create(fromTo: [Date, Date] | null) {
    this.showCreate = false;
    if (!fromTo) {
      return;
    }

    this.subscription.sink = this.apiService.calendarGenerate({
      yearId: this.yearId,
      fromDate: dayjs(fromTo[0]).format('YYYY-MM-DD'),
      toDate: dayjs(fromTo[1]).format('YYYY-MM-DD'),
    })
      .subscribe(response => this.setYear(response));
  }

  remove() {
    const dates = this.allDays[this.month].filter(d => d.current).map(d => d.date);
    this.subscription.sink = this.apiService.calendarRemoveWorkdays({
      yearId: this.yearId,
      fromDate: dates[0],
      toDate: dates[dates.length - 1],
    }).subscribe(rv => this.setYear(rv));
  }

  protected setYear(response: CalendarYearResponse) {
    this.yearId = response.id;
    this.year = response.year;
    const currentYear = new Date().getFullYear();
    this.maxYear = currentYear + 2;

    this.allDays = this.mapCalendarYearToDays(response);
  }

  protected mapCalendarYearToDays(response: CalendarYearResponse): Day[][] {
    return AVAILABLE_MONTHS.map(month => mapCalendarYearToDays(response, month));
  }
}
