import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor, OnChanges {

  @ViewChild('control', {static: true})
  controlEl: ElementRef<HTMLInputElement>;

  @Input()
  checked?: boolean | null | undefined;

  @Input()
  indeterminate: boolean;

  @Output()
  toggled = new EventEmitter<boolean | null>();

  @Input()
  disabled: boolean;

  isIndeterminate: boolean;

  protected changeListeners = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checked) {
      this.isIndeterminate = this.indeterminate && (changes.checked.currentValue === null || changes.checked.currentValue === undefined);
      this.controlEl.nativeElement.checked = !!changes.checked.currentValue;
    }
    if (changes.disabled) {
      this.controlEl.nativeElement.disabled = !!changes.disabled.currentValue;
    }

  }

  writeValue(obj: any): void {
    this.isIndeterminate = this.indeterminate && (obj === null || obj === undefined);
    this.controlEl.nativeElement.checked = !!obj;
  }

  registerOnChange(fn: any): void {
    this.changeListeners = [fn];
  }

  registerOnTouched(fn: any): void {
  }

  onChange(e: Event) {
    let checked = !!this.controlEl.nativeElement.checked;

    if (this.indeterminate) {
      if (this.isIndeterminate) {
        this.isIndeterminate = false;
      } else if (checked) {
        e.preventDefault();
        checked = null;
        this.isIndeterminate = true;
      } else {
        this.isIndeterminate = false;
      }
    }

    this.changeListeners.forEach(cb => cb(checked));
    this.toggled.next(checked);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.controlEl.nativeElement.disabled = isDisabled;
  }
}
