import * as dayjs from 'dayjs';
import { AVAILABLE_MONTHS, MenuDayStatus, MenuStatus } from '@app/shared';

export interface Day {
  index: number;
  date: string;
  active: boolean;
  status?: MenuStatus;
  isSunday: boolean;
  isToday: boolean;
  current: boolean;
  week: number;
}

export interface Month {
  first: dayjs.Dayjs;
  index: number;
  days: Day[];
}

export function dayClass(day: Day) {
  if (day.current) {
    if (day.status) {
      return `day ${day.status}`;
    }
    return 'day current';
  }
  return 'day';
}

export function mapDayStatusToMonths(dayStatuses: MenuDayStatus[], selectedYear: number) {
  const today = dayjs().format('YYYY-MM-DD');

  return AVAILABLE_MONTHS.map(month => {
    const monthStart = dayjs(new Date(selectedYear + (month < 9 ? 1 : 0), month - 1));
    const monthEnd = monthStart.endOf('month');
    const calendarStart = monthStart.startOf('week');
    const calendarEnd = monthEnd.endOf('week');
    const days: Day[] = [];

    let timestamp = calendarStart;
    do {
      const date = timestamp.format('YYYY-MM-DD');
      const dayStatus = dayStatuses.find(md => md.date === date);
      days.push({
        index: timestamp.date(),
        date,
        active: !!dayStatus,
        status: dayStatus ? dayStatus.status : undefined,
        current: !(timestamp.isBefore(monthStart) || timestamp.isAfter(monthEnd)),
        isSunday: timestamp.day() === 0,
        isToday: date === today,
        week: timestamp.week(),
      });
      timestamp = timestamp.add(1, 'day');
    } while (timestamp.isBefore(calendarEnd));

    return {
      first: monthStart,
      index: month,
      days
    };
  });
}
