import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService, FavoriteMeal, MealCode, MealSize, MenuMealFood, SeasonNamePipe } from '@app/shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-menu-favorite',
  templateUrl: './menu-favorite.component.html',
  styleUrls: ['./menu-favorite.component.scss']
})
export class MenuFavoriteComponent implements OnInit {

  @Input()
  yearId: number;

  @Input()
  date: string;

  @Input()
  dietId: number;

  @Input()
  mealSize: MealSize;

  @Output()
  favourited = new EventEmitter<FavoriteMeal>();

  @ViewChild('titleEl', {static: false})
  titleEl: ElementRef<HTMLInputElement>;

  form: FormGroup;

  visible: boolean;

  seasonOptions: any[];

  mealCode: MealCode;

  foods: MenuMealFood[];

  allSeasons: any[];

  constructor(protected seasonNamePipe: SeasonNamePipe, protected fb: FormBuilder, protected api: ApiService) {
    this.seasonOptions = seasonNamePipe.options;
    this.allSeasons = seasonNamePipe.options.map(o => o.value);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: [null, [Validators.required]],
      seasonQuality: [],
    });
  }

  show(meal: MealCode, foods: MenuMealFood[]) {
    this.mealCode = meal;
    this.foods = foods;
    this.visible = true;
    this.form.reset({
      seasonQuality: this.allSeasons,
    });
    setTimeout(() => {
      this.titleEl?.nativeElement.focus();
    }, 0);
  }

  submit() {
    const values = this.form.value;

    this.api.menuFavoriteMeal({
      yearId: this.yearId,
      date: this.date,
      dietId: this.dietId,
      mealSize: this.mealSize,
      mealCode: this.mealCode,
      recSeasons: values.seasonQuality,
      title: values.title,
    }).subscribe(rv => {
      this.visible = false;
      this.favourited.next(rv);
    });
  }

}
