<ul class="list" #list>
  <li *ngFor="let food of foods" [class.selected]="selected === food.id" (click)="clicked.emit(food)">
    <i class="category" [ngClass]="'category-' + food.category"></i>
    <span class="name">
      {{ food.name }}
    </span>
    <span class="incomplete" *ngIf="food.isIncomplete || food.isDietUnconfirmed"></span>
    <app-component-pie [values]="food"></app-component-pie>
    <button type="button" *ngIf="canDelete" (click)="doDelete($event, food)"></button>
  </li>
</ul>
