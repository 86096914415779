import { NgModule, Provider } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { CalendarEditorComponent } from '@app/calendar';
import { GroupEditorComponent } from '@app/groups';
import { first, map, switchMap } from 'rxjs/operators';
import { ProductEditorComponent } from './foods/product-editor/product-editor.component';
import { RecipeEditorComponent } from './foods/recipe-editor/recipe-editor.component';
import { GroupExceptionsComponent } from './groups/group-exceptions/group-exceptions.component';
import { MenuDayComponent } from './menu/menu-day/menu-day.component';
import { MenuMonthComponent } from './menu/menu-month/menu-month.component';
import { ApiService, mapMealSize } from './shared';
import { AuthGuard } from './user/auth.guard';
import { LoginComponent } from './user/login/login.component';
import { MenuWeekComponent } from './menu/menu-week/menu-week.component';
import { MenuMonthGuard } from './menu/menu-month.guard';
import { LogoutGuard } from './user/logout.guard';
import { OrganisationListComponent } from './admin/organisation-list/organisation-list.component';
import { ActivateComponent } from './user/activate/activate.component';
import { AdminGuard } from './user/admin.guard';
import { LostPasswordComponent } from './user/lost-password/lost-password.component';
import { ProductGuard } from '@app/foods/product.guard';
import { RecipeGuard } from '@app/foods/recipe.guard';
import { GroupGuard } from '@app/groups/group.guard';
import { CalendarGuard } from '@app/calendar/calendar.guard';
import { ShoppingListDateGuard } from '@app/prep/shopping-list-date.guard';
import { ShoppingListComponent } from '@app/prep/shopping-list/shopping-list.component';
import { UserLayoutComponent } from '@app/layouts/user-layout/user-layout.component';
import { CheckDirtyGuard } from '@app/shared/check-dirty.guard';
import { AppLayoutComponent } from '@app/layouts/app-layout/app-layout.component';
import { WelcomeComponent } from '@app/home/welcome/welcome.component';
import { InfoDetailsComponent } from '@app/home/info-details/info-details.component';
import { ContactComponent } from '@app/home/contact/contact.component';
import { PrivacyComponent } from '@app/home/privacy/privacy.component';
import { CalendarYearsComponent } from '@app/calendar/calendar-years/calendar-years.component';
import { ProfileComponent } from '@app/user/profile/profile.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: WelcomeComponent,
        resolve: {
          welcome: 'welcome'
        },
        canActivate: [
          AuthGuard,
        ]
      },
      {
        path: 'info',
        pathMatch: 'full',
        component: InfoDetailsComponent,
      },
      {
        path: 'contact',
        pathMatch: 'full',
        component: ContactComponent,
      },
      {
        path: 'privacy',
        pathMatch: 'full',
        component: PrivacyComponent,
      },
      {
        path: 'logout',
        children: [],
        canActivate: [
          LogoutGuard,
        ]
      },
      {
        path: 'profile',
        pathMatch: 'full',
        component: ProfileComponent,
      },
      {
        path: 'years',
        component: CalendarYearsComponent,
        canActivate: [
          AuthGuard,
        ],
        resolve: {
          years: 'years'
        }
      },
      {
        path: 'calendar',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CalendarEditorComponent,
            resolve: {
              year: 'year'
            },
            canActivate: [
              AuthGuard,
              CalendarGuard
            ]
          },
          {
            path: ':month',
            component: CalendarEditorComponent,
            resolve: {
              year: 'year'
            },
            canActivate: [
              AuthGuard,
              CalendarGuard
            ]
          }
        ]
      }, {
        path: 'groups',
        children: [
          {
            path: '',
            pathMatch: 'full',
            resolve: {
              yearId: 'yearId',
              groups: 'groups',
            },
            component: GroupEditorComponent,
            canActivate: [
              AuthGuard,
              GroupGuard,
            ],
            canLoad: []
          },
          {
            path: 'add',
            component: GroupEditorComponent,
            resolve: {
              yearId: 'yearId',
              groups: 'groups',
            },
            canActivate: [
              AuthGuard,
              GroupGuard,
            ],
            canDeactivate: [
              CheckDirtyGuard,
            ],
            data: {
              isNew: true
            }
          },
          {
            path: 'edit/:id',
            component: GroupEditorComponent,
            resolve: {
              yearId: 'yearId',
              editGroup: 'editGroup',
            },
            canActivate: [
              AuthGuard,
              GroupGuard,
            ],
            canDeactivate: [
              CheckDirtyGuard,
            ],
          }
        ]
      }, {
        path: 'absence',
        children: [
          {
            path: '',
            component: GroupExceptionsComponent,
            resolve: {
              exceptions: 'exceptions',
            },
            canActivate: [
              AuthGuard
            ],
          }, {
            path: ':from',
            component: GroupExceptionsComponent,
            resolve: {
              exceptions: 'exceptions',
            },
            canActivate: [
              AuthGuard
            ],
          }, {
            path: ':from/:to',
            component: GroupExceptionsComponent,
            resolve: {
              exceptions: 'exceptions',
            },
            canActivate: [
              AuthGuard
            ]
          }
        ]
      }, {
        path: 'products',
        children: [
          {
            path: '',
            component: ProductEditorComponent,
            canActivate: [
              AuthGuard,
              ProductGuard,
            ],
            resolve: {
              yearId: 'yearId',
            },
          }, {
            path: 'new',
            component: ProductEditorComponent,
            canActivate: [
              AuthGuard,
              ProductGuard,
            ],
            data: {
              type: 'new'
            },
            resolve: {
              yearId: 'yearId'
            },
            canDeactivate: [
              CheckDirtyGuard,
            ],
          }, {
            path: ':id',
            component: ProductEditorComponent,
            resolve: {
              yearId: 'yearId',
              food: 'food',
            },
            canActivate: [
              AuthGuard,
              ProductGuard,
            ],
            canDeactivate: [
              CheckDirtyGuard,
            ],
          }
        ]
      }, {
        path: 'recipe',
        children: [
          {
            path: '',
            component: RecipeEditorComponent,
            resolve: {
              yearId: 'yearId',
              diets: 'diets',
            },
            canActivate: [
              AuthGuard,
              RecipeGuard
            ],
          }, {
            path: 'new',
            component: RecipeEditorComponent,
            resolve: {
              yearId: 'yearId',
              yearSizes: 'yearSizes',
              diets: 'diets',
            },
            data: {
              isNew: true,
            },
            canActivate: [
              AuthGuard,
              RecipeGuard
            ],
            canDeactivate: [
              CheckDirtyGuard,
            ],
          }, {
            path: ':id',
            component: RecipeEditorComponent,
            resolve: {
              yearId: 'yearId',
              food: 'food',
              yearSizes: 'yearSizes',
              diets: 'diets',
            },
            canActivate: [
              AuthGuard,
              RecipeGuard
            ],
            canDeactivate: [
              CheckDirtyGuard,
            ],
          }
        ]
      }, {
        path: 'menu',
        children: [
          {
            path: '',
            canActivate: [
              AuthGuard,
              MenuMonthGuard,
            ],
            children: [],
          }, {
            path: 'month/:month',
            component: MenuMonthComponent,
            resolve: {
              menuMonth: 'menuMonth',
            },
            canActivate: [
              AuthGuard,
            ],
          }, {
            path: 'month/:month/:mealSize',
            component: MenuMonthComponent,
            resolve: {
              menuMonth: 'menuMonth',
            },
            canActivate: [
              AuthGuard,
            ]
          }, {
            path: 'day/:date/:mealSize',
            component: MenuDayComponent,
            resolve: {
              menuDay: 'menuDay',
            },
            canActivate: [
              AuthGuard,
            ]
          }, {
            path: 'day/:date/:mealSize/:dietId',
            component: MenuDayComponent,
            resolve: {
              menuDay: 'menuDay',
            },
            canActivate: [
              AuthGuard,
            ]
          }, {
            path: 'week/:week',
            component: MenuWeekComponent,
            resolve: {
              menuWeek: 'menuWeek',
            },
            canActivate: [
              AuthGuard,
            ]
          }, {
            path: 'week/:week/:mealSize',
            component: MenuWeekComponent,
            resolve: {
              menuWeek: 'menuWeek',
            },
            canActivate: [
              AuthGuard,
            ]
          }, {
            path: 'week/:week/:mealSize/:dietId',
            component: MenuWeekComponent,
            resolve: {
              menuWeek: 'menuWeek',
            },
            canActivate: [
              AuthGuard,
            ]
          }
        ]
      },
      {
        path: 'preparation',
        canActivate: [
          AuthGuard,
        ],
        children: [
          {
            path: '',
            canActivate: [
              ShoppingListDateGuard,
            ],
            children: [],
          },
          {
            path: ':week',
            component: ShoppingListComponent,
            resolve: {
              shoppingList: 'shoppingList',
            },
          }
        ]
      },
      {
        path: 'admin',
        children: [
          {
            path: '',
            component: OrganisationListComponent,
            resolve: {
              organisationList: 'organisationList'
            },
            canActivate: [
              AdminGuard
            ]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: UserLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'activate/:jwt',
        component: ActivateComponent
      },
      {
        path: 'lost-password',
        component: LostPasswordComponent,
      },
    ]
  },

];

const providers: Provider[] = [
  {
    provide: 'yearId',
    useFactory: (api: ApiService) =>
      () => api.getYearId().pipe(first()),
    deps: [ApiService]
  },
  {
    provide: 'groups',
    useFactory: (api: ApiService) =>
      () => api.getYearId().pipe(switchMap(yearId => api.calendarGetGroups({ yearId })), first()),
    deps: [ApiService]
  },
  {
    provide: 'editGroup',
    useFactory: (api: ApiService) =>
      (route: ActivatedRouteSnapshot) => {
        const id = route.paramMap.get('id');

        return api.getYearId().pipe(switchMap(yearId => api.calendarGetGroups({ yearId })), map(groups => {
          const editGroup = groups.find(g => g.id === (+id));
          if (!editGroup) {
            throw new Error(`Group not found`);
          }

          return [groups, editGroup];
        }), first());
      },
    deps: [ApiService]
  },
  {
    provide: 'exceptions',
    useFactory: (api: ApiService) =>
      (route: ActivatedRouteSnapshot) => api.getYearId().pipe(switchMap(yearId => {
        const from = route.paramMap.get('from') || undefined;
        const to = route.paramMap.get('to') || undefined;
        return api.calendarGetGroupExceptions({
          yearId,
          from,
          to: to ?? from,
        });
      }), first()),
    deps: [ApiService]
  },
  {
    provide: 'year',
    useFactory: (api: ApiService) =>
      () => api.getYearId().pipe(switchMap(yearId => api.calendarGetYear({ yearId })), first()),
    deps: [ApiService]
  },
  {
    provide: 'food',
    useFactory: (api: ApiService) =>
      (route: ActivatedRouteSnapshot) => {
        const id = route.paramMap.get('id');
        return api.getYearId().pipe(
          switchMap(yearId => api.foodGetFood({ yearId, foodId: +id })),
          first()
        );
      },
    deps: [ApiService]
  },
  {
    provide: 'menuDay',
    useFactory: (api: ApiService) =>
      (route: ActivatedRouteSnapshot) => {
        const date = route.paramMap.get('date');
        const size = mapMealSize(route.paramMap.get('mealSize'));
        const dietId = route.paramMap.get('dietId');

        return api.getYearId()
          .pipe(
            switchMap(yearId => api.menuGetMenuDay({ yearId, date, size, dietId: dietId ? +dietId : undefined })),
            first()
          );
      },
    deps: [ApiService]
  },
  {
    provide: 'menuMonth',
    useFactory: (api: ApiService) =>
      (route: ActivatedRouteSnapshot) => {
        const month = +route.paramMap.get('month');
        const size = route.paramMap.get('mealSize');

        return api.getYearId()
          .pipe(
            switchMap(yearId => api.menuGetMenuYear({ yearId, month, mealSize: size ? +size : undefined })),
            first()
          );
      },
    deps: [ApiService]
  },
  {
    provide: 'menuWeek',
    useFactory: (api: ApiService) =>
      (route: ActivatedRouteSnapshot) => {
        const week = route.paramMap.get('week');
        const size = route.paramMap.get('mealSize');

        return api.getYearId()
          .pipe(
            switchMap(yearId => api.menuGetMenuWeek({
              yearId,
              week: +week,
              mealSize: size ? +size : undefined,
            })),
            first()
          );
      },
    deps: [ApiService]
  },
  {
    provide: 'shoppingList',
    useFactory: (api: ApiService) =>
      (route: ActivatedRouteSnapshot) =>
        api.getYearId().pipe(
          switchMap(yearId => {
            const week = parseInt(route.paramMap.get('week') ?? '1');
            return api.menuGetShoppingList({ yearId, week });
          }),
          first()
        ),
    deps: [ApiService]
  },
  {
    provide: 'yearSizes',
    useFactory: (api: ApiService) =>
      (_: ActivatedRouteSnapshot) =>
        api.getYearId().pipe(
          switchMap(yearId =>
            api.calendarGetAvailableMealSizesForYear({ yearId })
          ),
          first()
        ),
    deps: [ApiService]
  },
  {
    provide: 'organisationList',
    useFactory: (api: ApiService) =>
      () => api.organisationGetOrganisations().pipe(first()),
    deps: [ApiService]
  },
  {
    provide: 'diets',
    useFactory: (api: ApiService) =>
      () => api.getYearId().pipe(switchMap(yearId => api.calendarGetUsedDiets(yearId)), first()),
    deps: [ApiService],
  },
  {
    provide: 'welcome',
    useFactory: (api: ApiService) =>
      () => api.getYearId().pipe(switchMap(yearId => api.userWelcome(yearId)), first()),
    deps: [ApiService],
  },
  {
    provide: 'years',
    useFactory: (api: ApiService) => () => api.calendarGetYears().pipe(first()),
    deps: [ApiService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers
})
export class AppRoutingModule {
}
