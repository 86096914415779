<form class="container" [formGroup]="form" (submit)="save()">
  <h1>Ustvarite koledar</h1>
  <div class="form-group">
    Datum začetka
    <ng2-flatpickr formControlName="from" [config]="dateFrom"></ng2-flatpickr>
  </div>
  <div class="form-group">
    Datum konca
    <ng2-flatpickr formControlName="to" [config]="dateTo"></ng2-flatpickr>
  </div>
  <app-corner-buttons>
    <button class="btn-cancel" type="button" (click)="cancel()">Prekliči</button>
    <button class="btn-full-accept" type="submit">Ustvari</button>
  </app-corner-buttons>
</form>
