import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { COMMIT_SHORT_SHA } from '../version';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: environment.sentry,
      release: COMMIT_SHORT_SHA
    });
  }

  handleError(error) {
    console.error(error.originalError || error);
    Sentry.captureException(error.originalError || error);
  }
}
