import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import {
  AbstractApiService,
  ActivateRequest,
  CreateGroupRequest,
  CreateProductRequest,
  CreateRecipeRequest,
  DeleteFoodRequest, FindFoodRequest, FindFoodResponse,
  ForeignBasicFood,
  GroupResponse, ImportForeignRequest,
  LoginRequest, MealSize,
  UpdateGroupRequest,
  UpdateProductRequest,
  UpdateRecipeRequest, UpdateUserProfile,
  UserResponse
} from './api.abstract.service';
import { environment } from '../../environments/environment';

export class RpcError extends Error {
  constructor(public readonly code: number, message: string) {
    super(message);
  }
}

interface Response<T> {
  jsonrpc: string;
  id: number;
  error?: {
    code: number;
    message: string;
  };
  result: T;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService extends AbstractApiService {

  private requestTimeout = environment.rpcTimeout;

  protected id = 1;

  protected url = 'api';

  protected jwtRefreshTimeout: NodeJS.Timer;

  protected activeRequestCounter = 0;

  protected helpVisible = new Map<string, BehaviorSubject<boolean>>();

  protected helpDefault = new Map<string, boolean>();

  public readonly user$ = new ReplaySubject<UserResponse | null>(1);

  public readonly food$ = new Subject<void>();

  public readonly active$ = new Subject<boolean>();

  public readonly group$ = new Subject<GroupResponse | number>();

  public readonly incompleteFood$ = new Subject<{count: number, id: number}|null>();

  public readonly foodImport$ = new Subject<void>();

  constructor(protected client: HttpClient) {
    super();

    this.authMe().pipe(catchError(() => of(null))).subscribe(user =>
      this.user$.next(user)
    );
  }

  protected _rpc<T, V>(method: string, params?: any): Observable<V> {
    const id = this.id;
    this.id++;

    const request = {
      jsonrpc: '2.0',
      id,
      method,
      params
    };

    return new Observable(observer => {
      if (this.activeRequestCounter === 0) {
        this.active$.next(true);
      }
      this.activeRequestCounter++;
      const subscription = this.client.post<Response<V>>(this.url, request, { withCredentials: true })
        .pipe(
          timeout(this.requestTimeout),
          map(response => {
            if (response.error) {
              // if (response.error.code === 5) {
              //   this.user$.next(null);
              // }
              throw new RpcError(response.error.code, response.error.message);
            }
            return response.result;
          })
        )
        .subscribe(observer);

      return () => {
        subscription.unsubscribe();
        this.activeRequestCounter--;
        if (this.activeRequestCounter === 0) {
          this.active$.next(false);
        }
      };
    });
  }

  getYearId() {
    return this.user$
      .pipe(map(user => {
        // const year = localStorage.getItem(`year_${user.organisationId}`);
        // if (year) {
        //   return parseFloat(year);
        // }
        return user?.yearId;
      }));
  }

  setYearId(id: number) {
    return this.user$.pipe(tap(user =>
      localStorage.setItem(`year_${user.organisationId}`, String(id))
    ));
  }

  authMe() {
    return super.userMe().pipe(tap(user => {
      if (!user) {
        return;
      }

      if (this.jwtRefreshTimeout) {
        clearTimeout(this.jwtRefreshTimeout);
      }

      const refreshIn = user.jwtRefreshAt * 1e3 - Date.now();
      if (refreshIn > 0) {
        console.log(`Should refresh jwt in ${Math.floor(refreshIn / 1e3)}s`);
        this.jwtRefreshTimeout = setTimeout(this.refreshJwt.bind(this), refreshIn + 5e3);
      }

      Sentry.setUser({
        id: user.id.toString(),
      });
      Sentry.setExtra('organization_id', user.organisationId);
    }));
  }

  protected refreshJwt() {
    console.log('Refreshing jwt');
    this.authMe().subscribe(() => console.log('Jwt refreshed'));
  }

  authLogin(params: LoginRequest) {
    return super.userLogin(params).pipe(tap(user => {
      Sentry.setUser({
        id: user.id.toString(),
      });
      Sentry.setExtra('organization_id', user.organisationId);
      this.user$.next(user);
    }));
  }

  authActivate(params: ActivateRequest) {
    return super.userActivate(params).pipe(tap(user => {
      Sentry.setUser({
        id: user.id.toString(),
      });
      Sentry.setExtra('organization_id', user.organisationId);
      this.user$.next(user);
    }));
  }

  authLogout() {
    return super.userLogout().pipe(tap(() => this.user$.next(undefined)));
  }

  userUpdateProfile(params: UpdateUserProfile) {
    return super.userUpdateProfile(params).pipe(tap(rv => this.user$.next(rv)));
  }

  foodCreateProduct(params: CreateProductRequest) {
    return super.foodCreateProduct(params)
      .pipe(tap(() => this.food$.next()));
  }

  foodUpdateProduct(params: UpdateProductRequest) {
    return super.foodUpdateProduct(params)
      .pipe(tap(() => this.food$.next()));
  }

  foodDeleteFood(params: DeleteFoodRequest) {
    return super.foodDeleteFood(params)
      .pipe(tap(() => this.food$.next()));
  }

  foodCreateRecipe(params: CreateRecipeRequest) {
    return super.foodCreateRecipe(params)
      .pipe(tap(() => this.food$.next()));
  }

  foodUpdateRecipe(params: UpdateRecipeRequest) {
    return super.foodUpdateRecipe(params)
      .pipe(tap(() => this.food$.next()));
  }

  protected menuClipboardKey(yearId: number) {
    return `menu_clipboard_${yearId}`;
  }

  setMenuClipboard(yearId, sourceYearId: number, days: string[]) {
    const key = this.menuClipboardKey(yearId);
    if (days.length > 0) {
      localStorage.setItem(key, sourceYearId + ";" + days.join(','));
    } else {
      localStorage.removeItem(key);
    }
    return days;
  }

  clearMenuClipboard(yearId: number) {
    const key = this.menuClipboardKey(yearId);
    localStorage.removeItem(key);
    return [];
  }

  getMenuClipboard(yearId: number): {yearId: number, dates: string[]} | null {
    const key = this.menuClipboardKey(yearId);
    const value = localStorage.getItem(key);
    if (value) {
      const [yearId, dates] = value.split(';');

      return {
        yearId: ~~yearId,
        dates: dates.split(','),
      }
    }
    return null;
  }

  protected getHelpVisibleSubject(area: string) {
    if (!this.helpVisible.has(area)) {
      this.helpVisible.set(area, new BehaviorSubject(false));
    }
    return this.helpVisible.get(area);
  }

  getHelpVisible(area: string): Observable<boolean> {
    return this.getHelpVisibleSubject(area);
  }

  useDefaultHelpVisible(area: string): Observable<boolean> {
    this.setHelpVisible(area, this.getHelpDefault(area));
    return this.getHelpVisible(area);

  }

  setHelpVisible(area: string, visible: boolean) {
    this.getHelpVisibleSubject(area).next(visible);
  }

  toggleHelpVisible(area: string) {
    const subject = this.getHelpVisibleSubject(area);
    subject.next(!subject.value);
  }

  setHelpDefault(area: string, visible: boolean) {
    localStorage.setItem(`help_${area}`, visible ? '1' : '0');
    this.helpDefault.set(area, visible);
  }

  toggleHelpDefault(area: string): boolean {
    const visible = !this.getHelpDefault(area);
    this.setHelpDefault(area, visible);
    return visible;
  }


  getHelpDefault(area: string) {
    if (!this.helpDefault.has(area)) {
      this.helpDefault.set(area, localStorage.getItem(`help_${area}`) !== '0');
    }
    return this.helpDefault.get(area);
  }

  foodFindAnyForeign(query: string, limit = 100, source = 'nutribase'): Observable<ForeignBasicFood[]> {
    if (query !== sessionStorage.getItem('last_foreign_query')) {
      sessionStorage.setItem('last_foreign_query', query);
    }

    if (query === undefined || query === null || query.length < 2) {
      return of([]);
    }

    return super.foodFindForeign({source: source, query, limit})
      .pipe(map(rv => rv.foods));
  }

  foodFindLastForeign(): Observable<{ foods: ForeignBasicFood[], query: string}> {
    const query = sessionStorage.getItem('last_foreign_query');
    return this.foodFindAnyForeign(query)
      .pipe(
        map(foods => ({
          foods,
          query,
        })),
        catchError(() => of({
          foods: [],
          query: '',
        }))
      );
  }

  foodImportForeign(params: ImportForeignRequest): Observable<number> {
    return super.foodImportForeign(params).pipe(tap(() => this.foodImport$.next()));
  }

  calendarCreateGroup(params: CreateGroupRequest) {
    return super.calendarCreateGroup(params)
      .pipe(
        tap(group => this.group$.next(group))
      );
  }

  calendarUpdateGroup(params: UpdateGroupRequest) {
    return super.calendarUpdateGroup(params)
      .pipe(
        tap(group => this.group$.next(group))
      );
  }

  calendarDeleteGroup(params: number) {
    return super.calendarDeleteGroup(params)
      .pipe(
        tap(() => this.group$.next(params))
      );
  }

  foodFindFood(params: FindFoodRequest): Observable<FindFoodResponse> {
    return super.foodFindFood(params)
      .pipe(
        tap(rv => {
          if (params.includeIncomplete && rv.incompleteCount > 0) {
            this.incompleteFood$.next({
              count: rv.incompleteCount,
              id: rv.incompleteId
            });
          } else {
            this.incompleteFood$.next(null);
          }
        })
      );
  }

  foodImportBarCode(yearId: number, file: File) {
    return this.client.post<Response<number>>(`api/import-barcode?year_id=${yearId}`, file).pipe(
      map(response => {
        if (response.error) {
          throw new RpcError(response.error.code, response.error.message);
        }
        return response.result;
      }),
      tap(() => this.foodImport$.next())
    );
  }

  menuReportRecipes(yearId: number, date: string) {
    return `api/report/recipe/${yearId}/${date}`;
  }

  reportDailyMenu(yearId: number, date: string, size: MealSize, dietId: number) {
    return `api/report/daily-menu/${yearId}/${date}/${size}/${dietId}`;
  }

  menuReportBasic(yearId: number, month: number, size: MealSize, dietId: number) {
    return `api/report/basic-menu/${yearId}/${month}/${size}/${dietId}`;
  }

  menuReportExtended(yearId: number, month: number, size: MealSize, dietId: number) {
    return `api/report/extended-menu/${yearId}/${month}/${size}/${dietId}`;
  }

  reportShoppingList(yearId: number, week: number) {
    return `api/report/shopping-list/${yearId}/${week}`;
  }

  reportShoppingYear(yearId: number) {
    return `api/report/shopping-year/${yearId}`;
  }
}
