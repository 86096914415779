<app-title icon="user" text="Administrator">
  <button type="button" (click)="setOrgYear()">Nastavi leto</button>
  <button class="btn-add" (click)="doAddOrganisation()">Dodaj<br>organizacijo</button>
</app-title>

<table class="generic">
  <thead>
    <tr>
      <th><button type="button" (click)="toggleAllOrgs()">Vse</button></th>
      <th>ID</th>
      <th colspan="2" style="width: 99%">Naziv</th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let org of organisations">
      <td><input type="checkbox" [checked]="orgChecked.has(org.id)" (change)="toggleOrg(org.id)"></td>
      <td>{{ org.id }}</td>
      <td style="border-right: 0">
        <img alt="Logotip" *ngIf="org.logoUrl" [src]="org.logoUrl" width="100" />
      </td>
      <td style="width: 99%; border-left: 0">{{ org.name }}</td>
      <td>
        <ng-container *ngIf="org.currentYear">{{ org.currentYear }}/{{org.currentYear + 1}}</ng-container>
      </td>
      <td style="white-space: nowrap">
        <button (click)="doEditOrganisation(org)">Uredi</button>
        &nbsp;
        <button (click)="doEditUsers(org)">Uporabniki</button>
      </td>
    </tr>
  </tbody>
</table>

<div class="org-dialog" [class.hidden]="!addOrganisation && !editOrganisation">
  <div class="dialog">
    <form [formGroup]="organisationForm" (submit)="saveOrganisation()">
      <label class="form-group">
        <span class="form-label">Naziv</span>
        <input type="text" formControlName="name">
      </label>
      <div class="form-group">
        <span class="form-label">Davčna številka</span>
        <input type="text" formControlName="vatNumber">
      </div>
      <div class="form-group">
        <span class="form-label">
          Logotip
        </span>
        <div class="photo" [class.has-photo]="logoUrl">
          <img [src]="logoUrl" alt="Logotip"/>
          <input #file type="file">
          <button type="button" class="btn-remove-photo" (click)="removeLogo()"></button>
        </div>
      </div>
      <app-corner-buttons>
        <app-submit-button></app-submit-button>
      </app-corner-buttons>
    </form>
    <button class="btn-close" (click)="cancel()"></button>
  </div>
</div>

<div class="usr-dialog" *ngIf="editUsers">
  <div class="dialog">
    <h1>
      <ng-container *ngIf="userForm.value.id">{{ editUsers.name }}</ng-container>
      <ng-container *ngIf="!userForm.value.id">Nov uporabnik</ng-container>
    </h1>
    <div class="user-list">
      <div *ngFor="let usr of editUsers.users" [class.selected]="usr.id == userForm.value.id" (click)="doEditUser(usr)">
        <span class="role">{{ usr.role }}</span>
        {{usr.name}}<i *ngIf="!usr.activated" tooltip="Uporabnik se ni aktiviran" class="icon-invalid"></i>
        <span class="email">{{ usr.email }}</span>
      </div>
    </div>
    <div class="user-form">
      <form [formGroup]="userForm" (submit)="saveUser()">
        <app-error></app-error>
        <label class="form-group">
          <span class="form-label">Ime</span>
          <input type="text" formControlName="name">
        </label>
        <label class="form-group">
          <span class="form-label">E-mail</span>
          <input type="email" formControlName="email">
        </label>
        <label class="form-group">
          <span class="form-label">Vloga</span>
          <app-select-array formControlName="role" [options]="roles"></app-select-array>
        </label>
        <app-corner-buttons>
          <button *ngIf="userForm.value.id" type="button" class="btn-add" (click)="addUser()">Dodaj<br>uporabnika</button>
          <button *ngIf="userForm.value.id" type="button" (click)="impersonateUser()">Prijavi se<br>kot</button>
          <button *ngIf="userForm.value.id && !userForm.dirty" type="button" (click)="sendActivationLink()">
            <ng-container *ngIf="isActive">Poslji aktivacijsko<br>povezavo</ng-container>
            <ng-container *ngIf="!isActive">Poslji povezavo za<br>ponastavitev gesla</ng-container>
          </button>
          <app-submit-button #userFormSave *ngIf="userForm.value.id" saveText="Shrani" [dirty]="userForm.dirty"></app-submit-button>
          <app-submit-button *ngIf="!userForm.value.id" saveText="Dodaj" [dirty]="userForm.dirty"></app-submit-button>
        </app-corner-buttons>
      </form>
    </div>
    <button class="btn-close" (click)="cancel()"></button>
  </div>
</div>


