import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, ForeignBasicFood, SubscriptionSink } from '@app/shared';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { merge, of, Subject } from 'rxjs';

@Component({
  selector: 'app-foreign-search',
  templateUrl: './foreign-search.component.html',
  styleUrls: ['./foreign-search.component.scss']
})
export class ForeignSearchComponent implements OnInit, OnDestroy {

  @Output()
  selected = new EventEmitter<ForeignBasicFood>();

  current: any;

  form: FormGroup;

  results: ForeignBasicFood[];

  triggerFetch = new Subject();

  fetchFailed = false;

  protected subscription = new SubscriptionSink();

  constructor(
    protected fb: FormBuilder,
    protected api: ApiService,
    protected router: Router,
    protected route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      query: []
    });

    this.subscription.sink = merge(this.form.valueChanges.pipe(
      debounceTime(500),
      map(({query}) => query)
    ), this.triggerFetch.asObservable().pipe(
      map(() => this.form.value.query),
    ))
      .pipe(
        tap(() => this.fetchFailed = false),
        switchMap(query =>
          this.api.foodFindAnyForeign(query).pipe(
            catchError(() => {
              this.fetchFailed = true;
              return of([]);
            })
          )
        ),
        tap(foods => this.results = foods),
      )
      .subscribe();

    this.subscription.sink = this.route
      .data
      .pipe(
        tap(({ foreignSearchResults }) => {
          if (!foreignSearchResults) {
            return;
          }
          const { foods, query } = foreignSearchResults;
          this.results = foods;
          this.form.setValue({ query }, { emitEvent: false });
        })
      )
      .subscribe(() => {}, () => {
        debugger;
      }, () => {
        debugger;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClick(food: ForeignBasicFood) {
      this.selected.emit(food);
  }

}
