import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  combineStatuses,
  DietDetails,
  GroupType,
  MealSize,
  MenuDietStatus,
  MenuSizeStatus,
  SIZE_TO_GROUP_TYPE
} from '@app/shared';
import { get } from 'lodash-es';

@Component({
  selector: 'app-meal-size-picker',
  templateUrl: './meal-size-picker.component.html',
  styleUrls: ['./meal-size-picker.component.scss']
})
export class MealSizePickerComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {

  @Input()
  sizes: MealSize[];

  @Input()
  sizeStatuses: MenuSizeStatus[];

  @Input()
  dietStatuses: MenuDietStatus[];

  @Input()
  selectedSize: MealSize;

  @Output()
  selected = new EventEmitter<[MealSize, number | null]>();

  @Output()
  confirmAll = new EventEmitter<[boolean, boolean]>();

  @Input()
  diets: DietDetails[];

  @Input()
  dietId: number;

  @Input()
  canConfirmAllSizes: boolean;

  @Input()
  canUnconfirmAllSizes: boolean;

  @Input()
  canConfirmAllDiets: boolean;

  @Input()
  canUnconfirmAllDiets: boolean;

  dietStatusClasses: string[];

  sizeStatusClasses: string[];

  groupTypes: GroupType[];

  groupSizes: Map<GroupType, MealSize[]>;

  dietIndex: number;

  // protected resize$: Subscription;

  @ViewChild('dietList', {static: false})
  protected dietListEl: ElementRef<HTMLDivElement>;

  protected dietScroll = 0;

  constructor() { }

  protected onResize() {
    if (!this.dietListEl) {
      return;
    }

    const index = this.diets.findIndex(d => d.id === this.dietId);
    if (parseFloat(this.dietListEl.nativeElement.getAttribute('data-size')) != this.selectedSize || index === -1) {
      // view not rendered yet, bail out
      return;
    }

    const firstChild = this.dietListEl.nativeElement.querySelector('button.diet-button:first-child');
    if (!firstChild || firstChild.hasAttribute('data-scrolled')) {
      // only fix scroll once after render
      return;
    }
    firstChild.setAttribute('data-scrolled', 'yes');

    const allButtons: NodeListOf<HTMLButtonElement> = this.dietListEl.nativeElement.querySelectorAll('button.diet-button');
    const cntRect = this.dietListEl.nativeElement.getBoundingClientRect();
    const lastRect = allButtons.item(allButtons.length - 1).getBoundingClientRect();
    const currentRect = allButtons[index].getBoundingClientRect();
    const scrollButtons = 60;

    let current = this.dietScroll;

    let diff: number = 0;
    if (currentRect.right + current > cntRect.right - scrollButtons) {
      diff = (cntRect.right - scrollButtons) - (currentRect.right + current);
    } else if (currentRect.left + current < cntRect.left) {
      diff = cntRect.left - (currentRect.left + current);
    }
    diff = Math.round(diff);

    current += diff;
    allButtons[0].style.marginLeft = `${Math.ceil(current)}px`;
    this.dietListEl.nativeElement.classList.add('scroll-btns-visible');

    this.dietScroll = current;

    if (current < 0 || (lastRect.right - current) >= cntRect.right) {
      this.dietListEl.nativeElement.classList.add('scroll-btns-visible');
    } else {
      this.dietListEl.nativeElement.classList.remove('scroll-btns-visible');
    }
  }

  ngOnInit() {
     // this.resize$ = fromEvent(window, 'resize').pipe(debounceTime(250)).subscribe(() => this.onResize());
  }

  ngAfterViewChecked() {
    this.onResize();
  }

  ngOnChanges() {
    this.groupTypes = [];
    this.groupSizes = new Map();
    this.sizes.forEach(size => {
      const type = SIZE_TO_GROUP_TYPE.get(size);
      if (!this.groupTypes.includes(type)) {
        this.groupTypes.push(type);
        this.groupSizes.set(type, []);
      }
      this.groupSizes.get(type).push(size);
    });
    this.groupTypes.sort();

    this.groupSizes.forEach(sizes => sizes.sort());

    this.dietStatusClasses = (this.diets || []).map(diet =>
      get(this.dietStatuses.find(
        status => status.size === this.selectedSize && status.dietId === diet.id
      ), 'status')
    );
    this.sizeStatusClasses = this.sizes.map(size => {
      if (this.sizeStatuses !== undefined) {
        return get(this.sizeStatuses.find(ss => ss.size === size), 'status');
      } else if (this.dietStatuses !== undefined) {
        return combineStatuses(this.dietStatuses.filter(fd => fd.size === size).map(fd => fd.status));
      }
    });
    this.dietIndex = this.diets?.findIndex(d => d.id === this.dietId);
  }

  ngOnDestroy() {
    // this.resize$?.unsubscribe();
  }

  sizeClick(size: MealSize) {
    this.selected.emit([size, null]);
  }

  dietClick(diet: DietDetails) {
    this.selected.emit([this.selectedSize, diet.id]);
  }

  scroll(direction: number) {
    if (!this.dietListEl) {
      return;
    }

    const cntRect = this.dietListEl.nativeElement.parentElement.getBoundingClientRect();
    const allButtons: NodeListOf<HTMLButtonElement> = this.dietListEl.nativeElement.querySelectorAll('button.diet-button');
    const firstRect = allButtons.item(0).getBoundingClientRect();
    const lastRect = allButtons.item(allButtons.length - 1).getBoundingClientRect();
    const current = this.dietScroll;

    const max = lastRect.right - firstRect.left - cntRect.width + 60;
    const step = Math.floor(cntRect.width / 2);
    this.dietScroll = Math.max(-max, Math.min(0,  current - direction * step));

    allButtons[0].style.marginLeft = `${this.dietScroll}px`;
  }
}
