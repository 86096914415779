<app-title icon="product_thin" text="Živila" [sidebar]="true">
  <div class="swap-with" *ngIf="!type">
    <app-search label="Zamenjaj za:" variant="search" [searchProvider]="swapSearch" *ngIf="swapVisible" (selected)="swapSelected($event)"></app-search>
    <button class="swap-toggle" [class.active]="swapVisible" (click)="toggleSwap()">Zamenjaj</button>
  </div>
  <app-toggle-help>
    Baza živil predstavlja osnovo, ki omogoča sestavljanje receptov in jedilnikov. Baza, ki jo boste vključili v orodje, temelji na javnem naročilu vašega vzgojno-izobraževalnega zavoda. Orodje omogoča dve možnosti vnosa živil v bazo:  1. Uvoz spiska živil na podlagi črtnih kod (priporočena metoda). Po vnosu črtnih kod v bazo živil je potrebno bazo pregledati in potrditi. S potrditvijo živil prevzamete odgovornost za pravilnost podatkov o živilih!; 2. Individualno dodajanje živil (metoda je primerna za živila, ki jih zavod nabavlja izven javnega naročila ali pri lokalnih dobaviteljih). Dodajanje živil si lahko ogledate v video predstavitvi.
  </app-toggle-help>
</app-title>

<app-main-with-aside>
  <ng-container aside>
    <div class="add-buttons">
      <button class="btn-add-horizontal-large" (click)="importOpened = true">
        <span>Uvozi živila<br>iz baze</span>
      </button>
      <button class="btn-add-horizontal-large2" [routerLink]="['/products', 'new']">
        <span>Ročno vnesi<br>živilo</span>
      </button>
    </div>

    <app-product-list *ngIf="!type || type === 'new'" [selected]="productId" (pick)="showProduct($event)" (delete)="deleteProduct($event)" [includeIncomplete]="true"></app-product-list>
  </ng-container>
  <ng-container main>
    <app-error></app-error>
    <app-product-form *ngIf="type === 'new' || values" (save)="save($event)" [values]="values" (dirty)="setDirty($event)">
      <button *ngIf="values && !type" class="btn-copy" (click)="copy()" type="button">Kopiraj</button>
      <button *ngIf="productId && type!=='new'" class="btn-cancel" type="button" (click)="remove()">Izbriši</button>
      <app-submit-button #submit></app-submit-button>
    </app-product-form>
    <app-import-product *ngIf="importOpened" [yearId]="yearId" (done)="importOpened = false"></app-import-product>
    <div id="aside-marker"></div>
  </ng-container>
</app-main-with-aside>

