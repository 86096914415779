<form [formGroup]="searchForm">
  <div class="search-box">
    <input formControlName="query">
  </div>
  <button class="search-filter--all" type="button" (click)="resetSearchForm()" [class.search-filter--all-inactive]="anyFilter">Vse</button>
  <div class="form-group">
    <app-select-array [options]="allECatalogCategories" formControlName="eCatalogCategory" emptyTitle="Skupina živil" [selectedDark]="true">
    </app-select-array>
  </div>
</form>
<app-food-list [foods]="productList" [canDelete]="canDelete" (bottom)="loadMore()" (delete)="deleteProduct($event)" (clicked)="showProduct($event)" [selected]="selected"></app-food-list>
