import { Component, Input, OnChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';

interface PieSegment {
  name: string;
  value: number;
  color: string;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnChanges {

  @Input()
  segments: PieSegment[];

  styles: any[];

  title: string;

  constructor(protected numberPipe: DecimalPipe) {
  }

  ngOnChanges() {
    this.styles = [];
    let offset = 0;
    const totalValue = this.segments.reduce((c, {value}) => c + value, 0);

    const title: string[] = [];

    for (const segment of this.segments) {
      const ratio = segment.value / totalValue;
      const over50 = ratio > 0.5;

      const a = over50 ? -100 : 0;
      const b = over50 ? 200 : 100;
      const degrees = offset * 360;
      const beforeDegrees = ratio * 360;

      this.styles.push({
        s: {
          '-webkit-clip-path': `polygon(${a}% ${a}%, ${b}% ${a}%, ${b}% ${b}%, ${a}% ${b}%)`,
          'clip-path': `polygon(${a}% ${a}%, ${b}% ${a}%, ${b}% ${b}%, ${a}% ${b}%)`,
          transform: `translate(0, -50%) rotate(90deg) rotate(${degrees}deg)`,
          'z-index': over50 ? 2 : 1
        },
        b: {
          background: segment.color,
          transform: `translate(0, 100%) rotate(${beforeDegrees}deg)`
        },
        a: {
          background: segment.color,
          opacity: over50 ? 1 : 0
        }
      });
      offset += ratio;

      title.push(`${segment.name}: ${this.numberPipe.transform(ratio * 100, '1.0-0')}%`);
    }

    this.title = title.join('\n');
  }

}
