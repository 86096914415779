import { Pipe, PipeTransform } from '@angular/core';
import { Component } from './api.abstract.service';

const NAMES = new Map([
  [Component.Protein, 'Skupne beljakovine'],
  [Component.AnimalProtein, 'živalskega izvora'],
  [Component.VegetableProtein, 'rastlinskega izvora'],
  [Component.BranchedChainedAminoAcids, 'Razvejane esencialne aminokisline'],
  [Component.Isoleucine, 'izolevcin'],
  [Component.Leucine, 'levcin'],
  [Component.Lysine, 'lizin'],
  [Component.TotalAminoAcids, 'Skupne aminokisline'],
  [Component.Phenylalanine, 'fenilalanin'],
  [Component.Aspartame, 'aspartam'],
  [Component.Gluten, 'gluten'],
  [Component.Fat, 'Skupne maščobe'],
  [Component.SaturatedFattyAcids, 'nasičene kisline'],
  [Component.MonounsaturatedFattyAcids, 'enkrat nenasičene kisline'],
  [Component.LinoleicFattyAcid, 'linolna kislina'],
  [Component.LinolenicFattyAcid, 'linolenska kislina'],
  [Component.ArachidonicAcid, 'arahidonska kislina'],
  [Component.EicopentaenoicAcid, 'EPK - eikozapentaenojska kislina'],
  [Component.DocosahexaenoicAcid, 'DHK – dokozaheksaenojska kislina'],
  [Component.MyristoleicFattyAcid, 'miristinsko maščobna kislina'],
  [Component.PalmitoleicFattyAcid, 'palmitinska maščobna kislina'],
  [Component.StearicFattyAcid, 'stearinska maščobna kislina'],
  [Component.OleicFattyAcid, 'oleinska maščobna kislina'],
  [Component.TotalOmega3, 'skupne omega - 3'],
  [Component.TotalOmega6, 'skupne omega - 6'],
  [Component.TotalPolyunsaturatedFattyAcids, 'Skupne večkrat nenasičene'],
  [Component.Cholesterol, 'Holesterol'],
  [Component.TotalCarbohydrates, 'Skupni ogljikovi hidrati'],
  [Component.TotalSugars, 'Skupni sladkorji'],
  [Component.SimpleSugars, 'Skupni enostavni sladkorji'],
  [Component.FreeSugars, 'Prosti sladkorji'],
  [Component.Fructose, 'fruktoza'],
  [Component.Galactose, 'galaktoza'],
  [Component.Glucose, 'glukoza'],
  [Component.Lactose, 'laktoza'],
  [Component.Sucrose, 'saharoza'],
  [Component.Starch, 'škrob'],
  [Component.TotalDietaryFibre, 'Skupna prehranska vlaknina'],
  [Component.CrudeDietaryFibre, 'Surova prehranska vlaknina'],
  [Component.InsolubleDietaryFibre, 'Netopna prehranska vlaknina'],
  [Component.SolubleDietaryFibre, 'Topna prehranska vlaknina'],
  [Component.VitaminA, 'Vitamin A'],
  [Component.AlphaCarotene, 'Alfa - karoten'],
  [Component.BetaCarotene, 'Beta - karoten'],
  [Component.GammaCarotene, 'Gama - karoten'],
  [Component.VitaminD, 'Vitamin D'],
  [Component.VitaminE, 'Vitamin E'],
  [Component.VitaminK, 'Vitamin K'],
  [Component.VitaminC, 'Vitamin C'],
  [Component.B1Thiamin, 'B1 - tiamin'],
  [Component.B2Riboflavin, 'B2 - riboflavin'],
  [Component.B3Niacin, 'B3 - niacin'],
  [Component.B6Piridoksin, 'B6 - piridoksin, piridoksal in piridoksamin'],
  [Component.B5PantothenicAcid, 'B5 - pantotenska kislina'],
  [Component.B7Biotin, 'B7 - biotin(vitamin H)'],
  [Component.B9FolicAcid, 'B9 - folna kislina'],
  [Component.B12Cobalamin, 'B12 - kobalamin'],
  [Component.Sodium, 'Natrij'],
  [Component.Chloride, 'Klor'],
  [Component.Potassium, 'Kalij'],
  [Component.Calcium, 'Kalcij'],
  [Component.Magnesium, 'Magnezij'],
  [Component.Phosphorus, 'Fosfor'],
  [Component.Iron, 'Železo'],
  [Component.Iodine, 'Jod'],
  [Component.Fluoride, 'Fluorid'],
  [Component.Zink, 'Cink'],
  [Component.Copper, 'Baker'],
  [Component.Manganese, 'Mangan'],
  [Component.Chronium, 'Krom'],
  [Component.Molybdenum, 'Molibden'],
  [Component.Proline, 'Prolin'],
  [Component.Selenium, 'Selen'],
  [Component.Water, 'Voda'],
  [Component.Alcohol, 'Alkohol'],
  [Component.TotalOrac, 'total ORAC'],
  [Component.GlycemicIndex, 'GI'],
  [Component.GlycemicLoad, 'GL'],
  [Component.Color, 'Barva'],
  [Component.Probio, 'Probio'],
  [Component.Co2e, 'CO2e'],
  [Component.Energy, 'Energija'],
]);


@Pipe({
  name: 'componentName'
})
export class ComponentNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return NAMES.get(value) || '';
  }

}
