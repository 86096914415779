import { Pipe, PipeTransform } from '@angular/core';
import { QualityScheme } from './api.abstract.service';

const names = new Map([
  [QualityScheme.EuZOP, 'Zaščitena označba porekla'],
  [QualityScheme.EuZGO, 'Zaščitena geografska označba'],
  [QualityScheme.EuZTP, 'Zajamčena tradicionalna posebnost'],
  [QualityScheme.EuEKO, 'Ekološka proizvodnja'],
  [QualityScheme.NtVK, 'Označba višje kakovosti'],
  [QualityScheme.NtIP, 'Integrirana pridelava'],
  [QualityScheme.NtIK, 'Izbrana kakovost'],
  [QualityScheme.NtEKO, 'Ekološki kmetijski pridelki ali živila'],
  [QualityScheme.NtNMV, 'Naravna mineralna voda'],
  [QualityScheme.Biodar, 'Biodar'],
  [QualityScheme.Demeter, 'Demeter'],
]);

@Pipe({
  name: 'qualityScheme'
})
export class QualitySchemePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return names.get(value) || '';
  }

}
