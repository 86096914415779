<div>
  <div class="logos1">
    <a href="https://www.nijz.si/" target="_blank">
      <img src="assets/logotypes/nijz.png" width="320">
    </a>
    <a href="https://ijs.si/ijsw" target="_blank">
      <img src="assets/logotypes/ijs.png" width="265">
    </a>
    <a href="https://www.gov.si/drzavni-organi/ministrstva/ministrstvo-za-zdravje/o-ministrstvu/" target="_blank">
      <img src="assets/logotypes/mz.png" width="370">
    </a>
  </div>
  <div class="logos2">
    <a href="https://www.dobertekslovenija.si/" target="_blank">
      <img src="assets/logotypes/dober_tek.png" width="213">
    </a>
    <a href="http://solskilonec.si/" target="_blank">
      <img src="assets/logotypes/lonec.png" width="246">
    </a>
  </div>
  <div class="links">
    <a [routerLink]="['/info']">Informacije</a>
    <a [routerLink]="['/contact']">Kontakt</a>
    <a [routerLink]="['/privacy']">Zasebnost</a>
  </div>
</div>
