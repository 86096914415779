<a class="logo" routerLink="/">
  <img alt="Logo" src="assets/logo_header.svg">
</a>

<div class="separator-1"></div>

<nav class="main">
  <a class="menu" routerLink="/menu" [routerLinkActive]="['is-active']">
    <i class="icon-menu_normal"></i>
    <i class="icon-menu_over"></i>
    <span data-title="Jedilniki"></span>
  </a>
  <a class="preparation" routerLink="/preparation" [routerLinkActive]="['is-active']">
    <i class="icon-preparation_normal"></i>
    <i class="icon-preparation_over"></i>
    <span data-title="Dobava"></span>
  </a>
  <a class="absence" routerLink="/absence" [routerLinkActive]="['is-active']">
    <i class="icon-absence_normal"></i>
    <i class="icon-absence_over"></i>
    <span data-title="Odsotnost"></span>
  </a>
</nav>

<div class="separator-2"></div>

<nav class="secondary">
  <a class="groups" routerLink="/groups" [routerLinkActive]="['is-active']">
    <i class="icon-groups_normal"></i>
    <i class="icon-groups_over"></i>
    <span data-title="Naročniki"></span>
  </a>
  <a class="product" routerLink="/products" [routerLinkActive]="['is-active']">
    <i class="icon-product_normal"></i>
    <i class="icon-product_over"></i>
    <span data-title="Živila"></span>
  </a>
  <a class="recipe" routerLink="/recipe" [routerLinkActive]="['is-active']">
    <i class="icon-recipe_normal"></i>
    <i class="icon-recipe_over"></i>
    <span data-title="Recepti"></span>
  </a>
</nav>

<div class="separator-3"></div>

<div class="dropdown-menu">
  <a>
    <i class="icon-menu"></i>
  </a>
  <div class="submenu">
    <span>{{organisationName$ | async}}</span>
    <a [routerLink]="['/profile']">Profil</a>
    <a href="https://skupnost.sio.si/course/view.php?id=10782" target="_blank">Interne strani</a>
    <a href="https://skupnost.sio.si/course/view.php?id=10782&section=2" target="_blank">Obrazci</a>
    <a href="https://skupnost.sio.si/course/view.php?id=10782&section=6" target="_blank">Porcijski normativi</a>
    <a [routerLink]="['/admin']" *ngIf="(role$ | async) === 'admin'">Admin</a>
    <a [routerLink]="['/years']">Šolska leta</a>
    <a routerLink="/logout">Odjava</a>
  </div>
</div>
