<form [formGroup]="form" (submit)="submit()" class="user-layout-form">
  <app-error></app-error>
  <div class="success" *ngIf="emailSent">Poslan vam je bil e-mail za ponastavitev gesla</div>
  <input placeholder="E-mail" type="email" formControlName="email">
  <button type="submit" [disabled]="form.invalid">
    <i class="icon-accept_small"></i>
  </button>
  <div class="links">
    <a href="mailto:solskilonec@nijz.si">Kontakt</a>
    <a [routerLink]="['/login']">Nazaj na prijavo</a>
  </div>
</form>
