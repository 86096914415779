import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GroupResponse } from '@app/shared';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent {

  @Input()
  groups: GroupResponse[];

  @Input()
  selected: GroupResponse;

  @Output()
  delete = new EventEmitter<GroupResponse>();

}
