import { Component, Input, OnChanges } from '@angular/core';
import { MenuSizeGuidelines, Units, Values, Component as Cp } from '@app/shared';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnChanges {

  @Input()
  values: Values;

  @Input()
  units: Units;

  @Input()
  guidelines: MenuSizeGuidelines;

  energy: number;

  chot: number;

  prot: number;

  fat: number;

  constructor() { }

  ngOnChanges() {
    this.energy = (this.values.find(([cp, value]) => cp === Cp.Energy) ?? [])[1] ?? 0;
    this.chot = (this.values.find(([cp, value]) => cp === Cp.TotalCarbohydrates) ?? [])[1] ?? 0;
    this.prot = (this.values.find(([cp, value]) => cp === Cp.Protein) ?? [])[1] ?? 0;
    this.fat = (this.values.find(([cp, value]) => cp === Cp.Fat) ?? [])[1] ?? 0;
  }

}
