import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService, CheckboxArrayOption, SubscriptionSink } from '@app/shared';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  form: FormGroup;

  sub$ = new SubscriptionSink;

  energyUnitOptions: CheckboxArrayOption[] = [
    {
      value: 'kcal',
      title: 'kcal',
    },
    {
      value: 'kj',
      title: 'kJ',
    }
  ];

  constructor(private fb: FormBuilder, private api: ApiService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      email: [null, Validators.email],
      energyUnit: [null]
    });

    this.sub$.sink = this.api.user$.subscribe(user => {
      this.form.setValue({
        name: user.name,
        email: user.email,
        energyUnit: user.energyUnit,
      });
    });
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  submit() {
    const value = this.form.value;
    this.sub$.sink = this.api.userUpdateProfile({
      name: value.name,
      email: value.email,
      energyUnit: value.energyUnit,
    }).subscribe(() => this.form.markAsPristine());
  }
}
