import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiService,
  Category,
  DietDetails,
  downloadUrl,
  GetMenuMonthResponse,
  getWeekdayNames,
  mapMapToArray,
  MealSize,
  MenuSizeGuidelines,
  MenuSizeStatus,
  MenuYearSizes,
  SubscriptionSink,
  Unit,
  Units
} from '@app/shared';
import * as dayjs from 'dayjs';
import { Day, dayClass, mapDayStatusToMonths, Month } from '../types';

@Component({
  selector: 'app-menu-month',
  templateUrl: './menu-month.component.html',
  styleUrls: ['./menu-month.component.scss']
})
export class MenuMonthComponent implements OnInit, OnDestroy {

  dayClass = dayClass;

  yearId: number;

  months: Month[];

  currentMonth: Month;

  selectedMonth: number;

  selectedSize: MealSize;

  availableSizes: MealSize[];

  weekDays: string[];

  sizeStatuses: MenuSizeStatus[];

  monthUnits: Units;

  weekCategories: Map<number, Units>;

  menuGuidelines: MenuSizeGuidelines;

  prevMonth?: number;

  nextMonth?: number;

  copyMode: 'none' | 'copy' | 'pick' | 'paste';

  selectedDays: Set<string> = new Set();

  sourceCopyYearId: number;

  copiedDays: string[];

  avgCost: number | null;

  diets: DietDetails[];

  availableYears: MenuYearSizes[];

  protected subscription = new SubscriptionSink();

  constructor(protected route: ActivatedRoute, protected router: Router, protected api: ApiService) {
  }

  ngOnInit() {
    this.weekDays = getWeekdayNames();

    this.subscription.sink = this.route
      .data
      .subscribe(({ menuMonth }) => {
        this.setup(menuMonth);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changeMonth(month: number) {
    return this.router.navigate(['/menu/month', month]);
  }

  sizeSelected([size, dietId]: [MealSize, number | null]) {
    const segments = ['/menu/month', this.currentMonth.index, size];
    this.router.navigate(segments);
  }

  dayClick(day: Day) {
    if (!day.active || !day.current) {
      return;
    }

    if (this.copyMode === 'none') {
      return this.router.navigate(['/menu/day', day.date, this.selectedSize]);
    }

    if (this.copyMode === 'copy') {
      if (day.status === 'bare') {
        return;
      }
      if (this.selectedDays.has(day.date)) {
        this.selectedDays.delete(day.date);
      } else {
        this.selectedDays.add(day.date);
      }
    } else if (this.copyMode === 'pick') {
      this.copyMode = 'paste';
    }
  }

  dayOver(day: Day) {
    if (this.copyMode !== 'pick') {
      return;
    }
    this.selectedDays.clear();
    let after = false;
    let index = 0;
    for (const d of this.currentMonth.days) {
      if (d.date === day.date) {
        if (!d.active) {
          break;
        }
        after = true;
      }
      if (!after || !d.active || index >= this.copiedDays.length) {
        continue;
      }
      this.selectedDays.add(d.date);
      index++;
    }
  }

  dayOut() {
    if (this.copyMode === 'pick') {
      this.selectedDays.clear();
    }
  }

  copyClick() {
    if (this.copyMode === 'none') {
      this.copyMode = 'copy';
      this.sourceCopyYearId = this.yearId;
    } else if (this.copyMode === 'copy') {
      this.copiedDays = Array.from(this.selectedDays).sort();
      this.selectedDays.clear();
      this.copyMode = 'pick';
      this.api.setMenuClipboard(this.yearId, this.sourceCopyYearId, this.copiedDays);
      this.resetAfterCopy();
    } else if (this.copyMode === 'paste') {
      const sourceDates = this.copiedDays;
      const startDate = Array.from(this.selectedDays).sort()[0];

      this.copyMode = 'none';
      this.copiedDays = this.api.clearMenuClipboard(this.yearId);
      this.selectedDays.clear();

      this.subscription.sink = this.api.menuCopyMenus({
        sourceYearId: this.sourceCopyYearId,
        targetYearId: this.yearId,
        startDate,
        sourceDates,
        mealSize: this.selectedSize,
      }).subscribe(response => {
        this.setup(response);
        this.copiedDays = this.api.clearMenuClipboard(this.yearId);
      });
    }
  }

  copyCancel() {
    if (this.copyMode === 'copy') {
      this.copyMode = 'none';
      this.selectedDays.clear();
    } else if (this.copyMode === 'pick') {
      this.selectedDays.clear();
      this.copiedDays = this.api.clearMenuClipboard(this.yearId);
      this.copyMode = 'none';
    } else if (this.copyMode === 'paste') {
      this.selectedDays.clear();
      this.copyMode = 'pick';
    }
    this.resetAfterCopy();
  }

  protected setup(response: GetMenuMonthResponse) {
    const {
      dayStatuses, sizeStatuses, selectedYear, selectedMonth, diets,
      availableSizes, selectedSize, prevMonth, nextMonth, yearId,
    } = response;

    this.yearId = yearId;

    this.months = mapDayStatusToMonths(dayStatuses, selectedYear);

    this.selectedMonth = selectedMonth;
    this.selectedSize = selectedSize;
    this.availableSizes = availableSizes;
    this.diets = diets;

    this.sizeStatuses = sizeStatuses;

    this.setupCommon(response, this.months);

    this.prevMonth = prevMonth;
    this.nextMonth = nextMonth;

    this.availableYears = response.availableYears;

    this.selectedDays.clear();
    const clipBoard = this.api.getMenuClipboard(this.yearId);
    if (clipBoard) {
      this.sourceCopyYearId = clipBoard.yearId;
      this.copiedDays = clipBoard.dates;
    } else {
      this.sourceCopyYearId = null;
      this.copiedDays = [];
    }
    this.copyMode = this.copiedDays.length > 0 ? 'pick' : 'none';
  }

  setupCommon(response: GetMenuMonthResponse, months: Month[]) {
    const {
      selectedMonth, menuGuidelines, dayNutrients
    } = response;

    this.currentMonth = months.find(m => m.index === selectedMonth);

    const monthUnitSum = dayNutrients.reduce((c, { units }) => {
      units.forEach(([gp, v]) => c.set(gp, (c.get(gp) || 0) + v));
      return c;
    }, new Map<Category, number>());
    this.monthUnits = mapMapToArray(monthUnitSum, (v, k) => [k, v / dayNutrients.length] as Unit);

    const weekCategorySum = new Map<number, Map<Category, number>>();
    dayNutrients.forEach(({ date, values, units, categories }) => {
      const w = dayjs(date).week();
      if (!weekCategorySum.has(w)) {
        weekCategorySum.set(w, new Map());
      }
      categories.forEach(([gp, value]) =>
        weekCategorySum.get(w).set(gp, (weekCategorySum.get(w).get(gp) || 0) + value)
      );
    });

    this.weekCategories = new Map();
    weekCategorySum.forEach((units, week) =>
      this.weekCategories.set(week, mapMapToArray(units, (v, k) => [k, v]))
    );

    this.menuGuidelines = menuGuidelines;
  }

  reportBasicMenu($event: Event) {
    const selectEl = $event.target as HTMLSelectElement;
    const dietId = parseFloat(selectEl.value);
    selectEl.selectedIndex = 0;
    downloadUrl(this.api.menuReportBasic(this.yearId, this.selectedMonth, this.selectedSize, dietId));
  }

  reportExtendedMenu($event: Event) {
    const selectEl = $event.target as HTMLSelectElement;
    const dietId = parseFloat(selectEl.value);
    selectEl.selectedIndex = 0;
    downloadUrl(this.api.menuReportExtended(this.yearId, this.selectedMonth, this.selectedSize, dietId));
  }

  selectCopyYear($event: Event) {
    const yearId = ~~($event.target as any).value;
    const sizeAvailable = this.availableYears.find(y => y.yearId === yearId).availableSizes.includes(this.selectedSize)

    this.api.menuGetMenuYear({
      yearId,
      month: this.selectedMonth,
      mealSize: sizeAvailable ? this.selectedSize : undefined,
    }).subscribe(rv => {
      const months = mapDayStatusToMonths(rv.dayStatuses, rv.selectedYear);
      this.setupCommon(rv, months);
      this.sourceCopyYearId = rv.yearId;
    });
  }

  resetAfterCopy() {
    if (this.sourceCopyYearId === this.yearId) {
      return;
    }
    this.api.menuGetMenuYear({
      yearId: this.yearId,
      month: this.selectedMonth,
      mealSize: this.selectedSize,
    }).subscribe(rv => this.setupCommon(rv, mapDayStatusToMonths(rv.dayStatuses, rv.selectedYear)));
  }
}
