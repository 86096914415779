<form [formGroup]="form" (submit)="submit($event)">
  <label class="form-title-field">
    Naziv recepta
    <span class="help-tooltip" tooltip="Vpišite ime recepta."></span>
    <input type="text" formControlName="name">
  </label>

  <div class="columns">
    <div class="columns--1">
      <app-search label="Sestavine" [searchProvider]="search" (selected)="addIngredient($event)" appFormClass="ingredients" help="V prazno polje vpisujte imena živila in jih dodajate v recepturo." variant="add"></app-search>
      <div formArrayName="ingredients" class="ingredients">
        <ng-container *ngFor="let ingredient of ingredients.controls; let valueIndex = index">
          <ng-container [formGroupName]="valueIndex">
            <label class="ingredients--value">
              <span>
                {{ foods[ingredient.value.id]?.name }}
                <button type="button" (click)="removeIngredient(ingredient.value.id)" tabindex="-1"></button>
              </span>
              <div>
                <app-count formControlName="weight" align="right"></app-count>
                g
              </div>
            </label>
            <div class="ingredients--ingredients" *ngIf="foods[ingredient.value.id]?.ingredients">{{ foods[ingredient.value.id]?.ingredients.join(', ') }}</div>
          </ng-container>
        </ng-container>
      </div>

      <div class="form-group">
        <span class="form-label">
          Alergeni
          <span class="help-tooltip" tooltip="Prikaz alergenov in alergenov v sledovih v receptu."></span>
        </span>
        <ng-container *ngFor="let allergen of allergens; let i = index"><ng-container *ngIf="i > 0">, </ng-container>{{ allergen | allergenName }}</ng-container>
      </div>

      <label class="form-group">
        <span class="form-label">
          Način priprave
          <span class="help-tooltip" tooltip="Izberite ustrezen način obdelave jedi."></span>
        </span>
        <app-select-array [options]="cookingOptions" formControlName="cookingMethod"></app-select-array>
      </label>

      <label class="form-group">
        <span class="form-label">
          Kulinarična skupina
          <span class="help-tooltip" tooltip="Opredelite kulinarično skupino jedi."></span>
        </span>
        <app-select-array [options]="gastroOptions" formControlName="gastro"></app-select-array>
      </label>

      <div class="form-group">
        <span class="form-label">
          Sezona
          <span class="help-tooltip" tooltip="Izberite za katero sezono v letu je jed najprimernejša."></span>
        </span>
        <app-checkbox-array [options]="seasonOptions" all="Vse" formControlName="seasonQuality" altStyle="true"></app-checkbox-array>
      </div>

      <label class="form-group edible">
        <span class="form-label">
          Transportni odpadek
          <span class="help-tooltip" tooltip="Določite transportni odpadek."></span>
        </span>
        <div>
          <span class="ratio" [class.ratio-selected]="form.value.edible === 0"
                (click)="setEdible(0)">0%</span>
          <span class="ratio" [class.ratio-selected]="form.value.edible === 10"
                (click)="setEdible(10)">10%</span>
          <span class="ratio" [class.ratio-selected]="form.value.edible === 20"
                (click)="setEdible(20)">20%</span>
          <span class="ratio" [class.ratio-selected]="form.value.edible === 30"
                (click)="setEdible(30)">30%</span>
          <span class="ratio" [class.ratio-selected]="form.value.edible === 40"
                (click)="setEdible(40)">40%</span>
          <app-number formControlName="edible" max="100"></app-number>
        </div>
      </label>

      <label class="form-group">
        <span class="form-label">
          Številka
          <span class="help-tooltip" tooltip="Receptu lahko dodelite zaporedno številko."></span>
        </span>
        <input type="number" formControlName="seq"/>
      </label>

      <label class="form-group">
        <span class="form-label">
          Označba
          <span class="help-tooltip" tooltip="Receptu lahko določite poljubno označbo."></span>
        </span>
        <input type="text" formControlName="designation"/>
      </label>

      <label class="form-group">
        <span class="form-label">
          Vir
          <span class="help-tooltip" tooltip="Vpišite vir recepta (npr. kuharska knjiga moje babice)"></span>
        </span>
        <input type="text" formControlName="source"/>
      </label>

      <label class="form-group">
        <span class="form-label">
          Avtor
          <span class="help-tooltip" tooltip="Vpišite avtorja, ki je vnesel recept."></span>
        </span>
        <input type="text" formControlName="author"/>
      </label>
    </div>

    <div class="columns--2">
      <div class="diets" *ngIf="customDiets.length > 0">
        <div class="form-label">
          Diete
          <span class="help-tooltip" tooltip="Označite ali je recept glede na vsebnost alergenov in sestavin primeren za posamezne naročnike z dietami."></span>
        </div>
        <ng-container formArrayName="customDiets">
          <ng-container *ngFor="let diet of customDiets; let i = index">
            <div class="diets--item">
              <span>{{ diet.name }}</span>
              <label>
                <input type="radio" [formControlName]="i" [value]="true" />
                <span>Primerno</span>
              </label>
              <label>
                <input type="radio" [formControlName]="i" [value]="false" />
                <span>Neprimerno</span>
              </label>
              <label>
                <input type="radio" [formControlName]="i" [value]="null" />
                <span>Neopred.</span>
              </label>
            </div>
            <div class="diets--desc" [class.expanded]="dietExpanded[i]" (click)="expandDiet(i)">
              <div class="diets--type">
                {{ diet.dietCodes | dietName }}
                <button type="button" ></button>
              </div>
              <div class="diets--instr">
                {{ diet.description }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="form-group portions">
        <span class="form-label">
          Priporočena količina (in št. porcij)
          <span class="help-tooltip" tooltip="Priporočena količina, ki jo določajo smernice."></span>
        </span>
        <table *ngIf="portionsEstimations?.length>0">
          <tbody>
            <tr *ngFor="let portion of portionsEstimations">
              <th class="name">{{ portion.groupType | groupType }}</th>
              <td *ngFor="let size of portion.sizes">
                <ng-container *ngIf="size">
                  <div>{{ size.size | mealSize : 'ageGroup' }}</div>
                  <div>{{ size.weight | number : '1.0-2' }} g x {{ size.count | number : '1.0-0' }}</div>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="columns">
        <div class="columns--3">
          <label class="form-group">
            <span class="form-label">
              Opis priprave
              <span class="help-tooltip" tooltip="Dodajte opis priprave jedi."></span>
            </span>
            <div class="preparation-container">
              <textarea #preparationElement rows="1" formControlName="preparation"></textarea>
            </div>
          </label>
        </div>
        <div class="columns--4">
          <div class="form-group">
            <span class="form-label">
              Slika
              <span class="help-tooltip" tooltip="Dodajte fotografijo jedi."></span>
            </span>
            <div class="photo" [class.has-photo]="photoUrl">
              <img [src]="photoUrl" alt="Slika recepta"/>
              <input #file type="file">
              <button type="button" class="btn-remove-photo" (click)="removePhoto()"></button>
            </div>
          </div>

        </div>
      </div>

      <app-corner-buttons>
        <ng-content></ng-content>
      </app-corner-buttons>
    </div>
  </div>
</form>

