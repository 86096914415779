import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BasicFood, Category, ForeignBasicFood } from '../api.abstract.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

interface Food {
  id: any;

  name: string;

  category: Category;

  componentTotalCarbohydrates: number;

  componentProtein: number;

  componentFat: number;
}

type BasicOrForeign = BasicFood | ForeignBasicFood;

@Component({
  selector: 'app-food-list',
  templateUrl: './food-list.component.html',
  styleUrls: ['./food-list.component.scss']
})
export class FoodListComponent implements OnInit, OnDestroy {

  @Input()
  selected: string | number;

  @Input()
  foods: BasicOrForeign[];

  @Input()
  canDelete = false;

  @Output()
  delete = new EventEmitter<BasicOrForeign>();

  @Output()
  clicked = new EventEmitter<BasicOrForeign>();

  @Output()
  bottom = new EventEmitter<void>();

  protected subscription: Subscription;

  @ViewChild('list', { static: true })
  private listEl: ElementRef<HTMLUListElement>;

  doDelete($event, food: BasicOrForeign) {
    $event.preventDefault();
    $event.stopPropagation();
    this.delete.emit(food);
  }

  ngOnInit() {
    this.subscription = fromEvent(this.listEl.nativeElement, 'scroll')
      .pipe(
        debounceTime(250),
        filter(() => {
          const listEl = this.listEl.nativeElement;
          const scrollTop = listEl.scrollTop;
          const scrollHeight = listEl.scrollHeight;
          const offsetHeight = listEl.offsetHeight;

          return scrollHeight - (scrollTop + offsetHeight) < 100;
        }),
        map(() => {
        }),
      )
      .subscribe(this.bottom);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isForeignBasicFood(food: BasicOrForeign): food is ForeignBasicFood {
    return typeof ((food as ForeignBasicFood).id) === 'string';
  }

}
