<div class="header">
  <div class="header-date-picker">
    <a class="date--prev" *ngIf="datePages.prev" [routerLink]="['/menu/day', datePages.prev, size]"></a>
    <app-date-picker [dates]="workdays" (selected)="navigateDate($event)">
      <app-title icon="menu_over">
        Jedilnik: {{ dateJs.format('dddd, D.M.') }}
      </app-title>
    </app-date-picker>
    <a class="date--next" *ngIf="datePages.next" [routerLink]="['/menu/day', datePages.next, size]"></a>
  </div>
  <button type="button" class="date--remove" (click)="removeDay()"></button>
  <button type="button" class="date--copy" (click)="showCopy()">
    Kopiraj<br>od
  </button>
  <a class="btn-report" [href]="reportDailyMenu()" download>Natisni<br>dnevne menije</a>
  <a class="btn-shopping-list" [href]="shoppingList()">Dobava</a>
  <app-toggle-help>
    <p>
      V pogledu dnevnega jedilnika vam orodje omogoči sestavo dnevnega jedilnika po skupinah naročnikov vašega zavoda (vključno tistih z medicinsko predpisanimi dietami). S klikom na funkcijo »Dodaj živilo/jed« pri posameznem obroku izbirate med živili in jedmi iz vaše baze podatkov. Orodje med sestavljanjem sprotno vrednoti energijsko in hranilno sestavo obroka in celotnega dnevnega jedilnika, skladno s smernicami. Orodje vam dovoli potrditev ustreznosti jedilnika šele po ureditvi jedilnika vseh naročnikov. Pomembno! Ustreznost sestave dietnih jedilnikov je odgovornost organizatorja prehrane, ki mora vedno sproti preverjati možnost prisotnosti alergenov oziroma živil, ki so pri posamezniku s posebnimi prehranskimi potrebami medicinsko odsvetovani oz. izključeni. V kolikor želite posamezni obrok ali dnevni jedilnik uporabiti naslednjič, ga označite z zvezdico. Orodje vam omogoča, da jedilnik prekopirate iz drugega dneva (npr. iz preteklega meseca). V posameznem obroku je omogočeno, da jedilnik prekopirate iz druge skupine naročnikov istega dne (npr. iz male v veliko porcijo). Pri kopiranju bodite pozorni na količine živil/jedi v obroku.
    </p>
  </app-toggle-help>
  <button type="button" class="btn--back" (click)="back()">
    Nazaj na<br>
    koledar
  </button>
</div>

<app-meal-size-picker [sizes]="mealSizes" [diets]="diets" [selectedSize]="size" [dietId]="dietId" (selected)="sizeSelected($event)" [dietStatuses]="mealDietStatuses" [canConfirmAllSizes]="canConfirmAllSizes" [canUnconfirmAllSizes]="canUnconfirmAllSizes" [canConfirmAllDiets]="canConfirmAllDiets" [canUnconfirmAllDiets]="canUnconfirmAllDiets" (confirmAll)="confirmAll($event)"></app-meal-size-picker>

<div *ngIf="!diet.noDiet" class="diet-desc" [class.diet-shown]="showDiet">
  <span class="diet-name">{{diet.dietCodes | dietName}}</span>
  <span class="diet-text">{{diet.description}}</span>
  <button (click)="showDiet = !showDiet"><i
    [class.icon-down-small]="!showDiet"
    [class.icon-up-small]="showDiet"></i></button>
</div>

<div class="container">
  <div class="meals">
      <ng-container *ngFor="let mealCode of mealCodes">
        <div class="meal-name">
          <div class="meal-name--name">
          <span class="meal-name--main">
            <ng-container *ngIf="mealCode !== 2 && mealCode !== 4">{{ mealCode | mealName }}</ng-container>
            <ng-container *ngIf="mealCode === 2 || mealCode === 4">Malica</ng-container>
          </span>
            <span class="meal-name--sub" *ngIf="mealCode === 2">dop.</span>
            <span class="meal-name--sub" *ngIf="mealCode === 4">pop.</span>
            <div>
              <button type="button" (click)="removeMeal(mealCode)" class="remove-meal" [disabled]="!mealFoods.get(mealCode)?.length" ></button>
              <button type="button" class="toggle-fav" [class.active]="favorites.has(mealCode)" [disabled]="!mealFoods.get(mealCode)?.length" (click)="favToggle(mealCode)"></button>
            </div>
          </div>
          <div class="meal-buttons">
            <button type="button" (click)="showSearch(mealCode)" class="add-food">
              Dodaj<br>živilo<br>/jed
            </button>
            <app-menu-size-picker *ngIf="otherSizes.length > 0" [sizes]="otherSizes" (selected)="copyFrom($event, mealCode)"></app-menu-size-picker>
            <span class="help-tooltip" tooltip="Funkcija omogoča kopiranje dnevnega jedilnika, ki ste ga v preteklem obdobju že načrtovali."></span>
          </div>
        </div>
        <div class="ingredients">
          <ul>
            <li *ngFor="let food of mealFoods.get(mealCode)">
              <span [tooltip]="getIngredientTooltip(food)" class="ingredients--name" (click)="showSearch(mealCode, food)">{{ food.name }}</span>
              <app-count [value]="food.weight" [delta]="10" (changed)="addFood([mealCode, food.foodId, $event, false])" unit="g" align="right"></app-count>
              <button (click)="removeFood(mealCode, food.foodId)"></button>
            </li>
          </ul>
        </div>
        <ng-container>
          <div class="meal--values">
            <app-component-pie [values]="mealNutritions.get(mealCode)?.values"></app-component-pie>
            <div class="meal--values-table">
              <ng-container *ngFor="let value of getMealValues(mealCode)">
                <span [ngClass]="value[1]">{{ value[0] }}</span>
                <span [ngClass]="value[1]">{{ value[2] | number: '1.0-1' }} g</span>
              </ng-container>
            </div>
          </div>
          <div class="meal--energy">
            <app-value-bar [value]="getMealEnergy(mealCode)" [min]="mealEnergyGuidelines.get(mealCode)?.min" [max]="mealEnergyGuidelines.get(mealCode)?.max" [showGuide]="true" color="energy"></app-value-bar>
            <div class="meal--energy--guide">
              {{ mealEnergyGuidelines.get(mealCode)?.min | energy }}-{{ mealEnergyGuidelines.get(mealCode)?.max | energy }} {{'unit'|energy}}
            </div>
            <div class="meal--energy--value">
              {{getMealEnergy(mealCode) | energy}} {{'unit'|energy}}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

  <div class="sidebar">
    <div class="day-confirmation">
      Sestava<br>obrokov
      <div *ngIf="status != 'bare'" class="day-confirmation--buttons">
        <button type="button" (click)="confirm()" class="btn-day-confirm" [disabled]="status === 'completed'">
          <ng-container *ngIf="status==='completed'">
            Potrjeno
          </ng-container>
          <ng-container *ngIf="status==='invalid'">
            Potrdi<br>jedilnik
          </ng-container>
        </button>
        <button *ngIf="canUnconfirm" type="button" (click)="confirm()" class="btn-day-unconfirm">
          Odstrani<br>potrditev
        </button>
      </div>
    </div>
    <app-menu-sidebar *ngIf="!isEmpty" [values]="dayValues" [units]="dayUnits" [guidelines]="guidelines"></app-menu-sidebar>
  </div>
</div>


<app-menu-food-search [yearId]="yearId" (selectedFood)="addFood($event)" (selectedMeal)="setMeal($event)" [all]="diet.noDiet" (closed)="searchClosed()"></app-menu-food-search>
<app-menu-copy [yearId]="yearId" (selected)="copyDay($event)"></app-menu-copy>
<app-menu-favorite [yearId]="yearId" [mealSize]="size" [dietId]="dietId" [date]="date" (favourited)="onFavorite($event)" ></app-menu-favorite>


