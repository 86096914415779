import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '@app/shared';
import { map, switchMap } from 'rxjs/operators';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class ShoppingListDateGuard implements CanActivate {

  constructor(protected router: Router, protected api: ApiService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.api.getYearId().pipe(
      switchMap(yearId => this.api.calendarGetNextWorkday(yearId)),
      map(rv => {
        const week = dayjs(rv).week();
        return this.router.parseUrl(`/preparation/${week}`)
      }),
    );
  }

}
