import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiService,
  avgNutrients,
  DietDetails,
  GetMenuWeekResponse,
  MealCode,
  MealSize,
  MenuDayNutrition,
  MenuSizeGuidelines,
  MenuSizeStatus,
  SubscriptionSink,
  Units,
  Values,
  Component as Cp, guideToWidth, ComponentGuideline,
} from '@app/shared';
import * as dayjs from 'dayjs';
import { dayClass, mapDayStatusToMonths, Month } from '../types';

interface Day {
  date: dayjs.Dayjs;
  mealFoods: Map<MealCode, string>;
  mealEnergy: Map<MealCode, [number, number]>;
  statusClass?: string;
  energy: number;
  nutrients?: MenuDayNutrition;
  active: boolean;
  componentWidths: string[];
  componentValid: boolean[];
  allComponentValid: boolean;
  week: number;
}

@Component({
  selector: 'app-menu-week',
  templateUrl: './menu-week.component.html',
  styleUrls: ['./menu-week.component.scss']
})
export class MenuWeekComponent implements OnInit, OnDestroy {

  year: number;

  yearId: number;

  months: Month[];

  currentMonth: Month;

  selectedSize: MealSize;

  selectedDiet: number;

  availableSizes: MealSize[];

  availableDiets: DietDetails[];

  availableMeals: MealCode[];

  sizeStatuses: MenuSizeStatus[];

  dateJs: dayjs.Dayjs;

  days: Day[];

  daysBefore: {statusClass?: string, week: number, date: string, active: boolean, index: number }[];

  daysAfter: { statusClass?: string, week: number, date: string, active: boolean, index: number }[];

  guidelines: MenuSizeGuidelines;

  weekValues: Values;

  weekUnits: Units;

  prevWeek: number;

  nextWeek: number;

  week: number;

  avgCost: number;

  ecoRatio: number;

  protected subscription = new SubscriptionSink();

  constructor(protected route: ActivatedRoute, protected router: Router, protected api: ApiService) { }

  ngOnInit() {
    this.subscription.sink = this.route
      .data
      .subscribe(({ menuWeek }) => {
        this.setup(menuWeek);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setup(response: GetMenuWeekResponse) {
    const { dayStatuses, sizeStatuses, year, yearId, startDate,
      availableSizes, selectedSize, availableDiets, foods, selectedDietId,
      availableMeals, menuNutrients, menuGuidelines, week, prevWeek, nextWeek } = response;
    const currentMonth = dayjs(startDate).month() + 1;
    this.week = week;

    this.dateJs = dayjs(startDate);
    this.availableSizes = availableSizes;
    this.availableDiets = availableDiets;
    this.availableMeals = availableMeals;
    this.selectedSize = selectedSize;
    this.selectedDiet = selectedDietId;
    this.year = year;
    this.yearId = yearId;
    this.guidelines = menuGuidelines;

    this.months = mapDayStatusToMonths(dayStatuses, year);

    this.currentMonth = this.months.find(m => m.index === currentMonth);

    this.daysBefore = this.currentMonth.days.filter(d => d.date < startDate).map(d => ({
      index: d.index,
      week: d.week,
      date: d.date,
      statusClass: d.status,
      active: d.active,
    }));

    this.sizeStatuses = sizeStatuses;

    const components = [Cp.TotalSugars, Cp.Sodium, Cp.SaturatedFattyAcids];
    const cpGuides = new Map<Cp, ComponentGuideline>(components.map(cp =>
      [cp, menuGuidelines.components.find(g => g.component === cp)],
    ));

    this.days = [];
    let ts = this.dateJs;
    for (let i = 0; i < 7; i++) {
      const date = ts.format('YYYY-MM-DD');

      const mealFoods = new Map<MealCode, string>();
      const mealEnergy = new Map<MealCode, [number, number]>();

      foods.filter(f => f.date === date).forEach(f => {
        if (!mealFoods.has(f.mealCode)) {
          mealFoods.set(f.mealCode, f.name);
        } else {
          mealFoods.set(f.mealCode, mealFoods.get(f.mealCode) + ', ' + f.name);
        }
        if (!mealEnergy.has(f.mealCode)) {
          mealEnergy.set(f.mealCode, [0, menuGuidelines.mealEnergy.find(m => m.mealCode === f.mealCode).max]);
        }
        mealEnergy.get(f.mealCode)[0] += f.energy;
      });

      const dayStatus = dayStatuses.find(s => s.date === date);
      let statusClass: string;
      if (dayStatus) {
        statusClass = dayStatus.status;
      }

      const nutrients = menuNutrients.find(n => n.date === date);

      const values: [Cp, number][] = components.map(cp => [cp, (nutrients?.values?.find(([c]) => c === cp) ?? [])[1] ?? 0]);

      this.days.push({
        date: ts,
        mealFoods,
        mealEnergy,
        statusClass,
        energy: nutrients?.values?.find(([cp]) => cp === Cp.Energy)[1],
        nutrients,
        active: !!dayStatus,
        componentWidths: values.map(([cp, value]) => guideToWidth(value, cpGuides.get(cp).max)),
        componentValid: values.map(([cp, value]) => value >= cpGuides.get(cp).min && value <= cpGuides.get(cp).max),
        allComponentValid: !values.map(([cp, value]) => value >= cpGuides.get(cp).min && value <= cpGuides.get(cp).max).includes(false),
        week: ts.week(),
      });

      ts = ts.add(1, 'day');
    }

    const lastDate =  ts.format('YYYY-MM-DD');
    this.daysAfter = this.currentMonth.days.filter(d => d.date >= lastDate).map(d => ({
      index: d.index,
      week: d.week,
      date: d.date,
      statusClass: dayClass(d),
      active: d.active,
    }));

    [this.weekValues, this.weekUnits] = avgNutrients(menuNutrients);

    this.prevWeek = prevWeek;

    this.nextWeek = nextWeek;

  }

  navigateWeek(week: number) {
    this.router.navigate(['/menu/week', week]);
  }

  navigateMonth(month: number) {
    this.router.navigate(['/menu/month', month]);
  }

  navigateCurrentMonth() {
    this.navigateMonth(this.currentMonth.first.month() + 1);
  }

  navigateDay(date: dayjs.Dayjs | string) {
    const params = ['/menu/day', typeof(date)==='string' ? date : date.format('YYYY-MM-DD'), this.selectedSize, this.selectedDiet];
    this.router.navigate(params, {queryParams: {
        week: this.week,
    }});
  }

  sizeSelected([size]: [MealSize, number | null]) {
    const segments = ['/menu/week', this.week, size];
    this.router.navigate(segments);
  }

  report() {
    this.api.menuGetMenuWeekReport({
      yearId: this.yearId,
      week: this.week,
      mealSize: this.selectedSize
    }).subscribe(response => {
      const link = document.createElement('a');
      link.download = response.fileName;
      link.href = response.fileContent;
      document.body.appendChild(link);
      link.click();
    });
  }

  shoppingList() {
    return `/preparation/${this.week}`;
  }

}
