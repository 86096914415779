import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dayClass, Month } from '../types';

@Component({
  selector: 'app-menu-year',
  templateUrl: './menu-year.component.html',
  styleUrls: ['./menu-year.component.scss']
})
export class MenuYearComponent {

  dayClass = dayClass;

  @Input()
  months: Month[];

  @Input()
  current: number;

  @Output()
  change = new EventEmitter<number>();

  constructor() { }

}
