import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  organisationName$: Observable<string>;

  role$: Observable<string>;

  constructor(protected api: ApiService, protected route: ActivatedRoute) {
    this.organisationName$ = api.user$.pipe(map(u => u?.organisationName));
    this.role$ = api.user$.pipe(map(u => u?.role));
  }

  ngOnInit() {
  }

}
