import { Pipe, PipeTransform } from '@angular/core';
import { fromBitset } from './util';
import { DietNamePipe } from './diet-name.pipe';

@Pipe({
  name: 'dietCombination'
})
export class DietCombinationPipe implements PipeTransform {

  constructor(protected dietName: DietNamePipe) {}

  transform(value: any, long = false): any {
    if (!value) {
      return '';
    }

    const ids = fromBitset(value).sort((a, b) => a - b);

    if (ids.length === 0) {
      return '';
    }

    return ids.map(id => {
      if (long) {
        return `D${id} - ${this.dietName.transform(id)}`;
      }
      return `D${id}`;
    }).join('+');
  }

}
