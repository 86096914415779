import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService, SubscriptionSink } from '@app/shared';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss']
})
export class LostPasswordComponent implements OnInit, OnDestroy {

  form: FormGroup;

  emailSent: boolean;

  protected subscription = new SubscriptionSink();

  constructor(protected fb: FormBuilder, protected api: ApiService) { }

  ngOnInit() {
    this.emailSent = false;
    this.form = this.fb.group({
      email: this.fb.control(null, [Validators.required, Validators.email]),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    this.emailSent = false;

    this.subscription.sink = this.api.userLostPassword({email: this.form.value.email})
      .subscribe(() => this.emailSent = true);
  }

}
