import { Component, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-main-with-aside',
  templateUrl: './main-with-aside.component.html',
  styleUrls: ['./main-with-aside.component.scss']
})
export class MainWithAsideComponent implements AfterViewInit, OnDestroy {

  // @ViewChild('aside', {static: true})
  // asideEl: ElementRef<HTMLDivElement>;
  //
  // private timer: NodeJS.Timer;

  constructor() { }

  // resize = () => {
  //   const contentBottom = window.scrollY + document.getElementById('aside-marker').getBoundingClientRect().y;
  //   const asideTop = window.scrollY + this.asideEl.nativeElement.getBoundingClientRect().y;
  //   const viewportHeight = window.innerHeight;
  //   const height = Math.max(viewportHeight - asideTop, contentBottom - asideTop, 300) - 30;
  //   this.asideEl.nativeElement.style.maxHeight = `${height}px`;
  // }

  ngAfterViewInit() {
    // this.resize();
    // this.timer = setInterval(() => this.resize(), 250);
    // window.addEventListener('scroll', this.resize);
  }

  ngOnDestroy() {
    // window.removeEventListener('scroll', this.resize);
    // clearInterval(this.timer);
  }
}
