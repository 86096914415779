<div class="groups">
  <div *ngFor="let groupType of groupTypes" class="group">
    <div class="group-name">{{ groupType | groupType }}</div>
    <div class="sizes">
      <button *ngFor="let size of groupSizes.get(groupType); let i = index"
              type="button"
              [class.selected]="size === selectedSize"
              [ngClass]="sizeStatusClasses[i]"
              (click)="sizeClick(size)">{{ size | mealSize : 'ageGroup' }}</button>
    </div>
  </div>
  <div *ngIf="canConfirmAllSizes || canUnconfirmAllSizes" class="confirm-actions">
    <button class="confirm" *ngIf="canConfirmAllSizes" (click)="confirmAll.emit([true, true])">Potrdi jedilnike vseh<br>starostnih skupin</button>
    <button class="unconfirm" *ngIf="canUnconfirmAllSizes" (click)="confirmAll.emit([true, false])">Odstrani vse<br> potrditve</button>
  </div>
</div>

<div class="diets" *ngIf="diets">
  <div class="diet-list" #dietList [attr.data-size]="selectedSize">
    <button *ngFor="let diet of diets; let j = index"
            type="button"
            class="diet-button"
            [class.selected]="dietId == diet.id"
            [ngClass]="dietStatusClasses[j]"
            [tooltip]="diet.description"
            [display]="!diet.noDiet"
            (click)="dietClick(diet)">
      {{ diet | dietDetails }}
    </button>
    <span></span>
    <div class="scroll-buttons">
      <button [disabled]="dietIndex == 0" ><i class="icon-left-small" (mousedown)="scroll(-1)"></i></button>
      <button [disabled]="dietIndex === diets.length - 1"><i class="icon-right-small" (mousedown)="scroll(1)"></i></button>
    </div>
  </div>
  <div *ngIf="canConfirmAllDiets" class="confirm-actions">
    <button class="confirm" (click)="confirmAll.emit([false, true])">Potrdi jedilnik vseh dietnih skupin<br>(znotraj trenutne starostne skupine)</button>
  </div>
</div>

