import { Component, Input, OnChanges } from '@angular/core';
import { Unit, Category, AllCategories } from '../api.abstract.service';
import { get } from 'lodash-es';

const categoryNames = new Map([
  [5, 'zelenj.'],
  [6, 'sadje'],
  [1, 'škrob'],
  [3, 'meso'],
  [11, 'ribe'],
  [10, 'jajca'],
  [2, 'mleko'],
  [9, 'voda'],
]);

@Component({
  selector: 'app-pyramide-compact',
  templateUrl: './pyramide-compact.component.html',
  styleUrls: ['./pyramide-compact.component.scss']
})
export class PyramideCompactComponent implements OnChanges {

  @Input()
  units: Unit[];

  @Input()
  showLabels = true;

  @Input()
  counts = false;

  unit: string;

  segments: {
    category: Category,
    value: number,
    name: string,
  }[];

  constructor() { }

  ngOnChanges() {
    this.segments = [];
    for (const category of AllCategories) {
      this.segments.push({
        category,
        name: categoryNames.get(category),
        value: get((this.units || []).find(u => u[0] === category), 1, 0),
      });
    }

    this.unit = this.counts ? 'x' : 'g';
  }

}
