import { Pipe, PipeTransform } from '@angular/core';
import { DietToName } from './api.abstract.service';
import { checkboxTitleComparator } from './checkbox-array/checkbox-array.component';

@Pipe({
  name: 'dietName'
})
export class DietNamePipe implements PipeTransform {

  public readonly options: {value: number, title: string}[];

  constructor() {
    this.options = [];
    DietToName.forEach((title, value) =>
      this.options.push(({title, value}))
    );
    this.options = this.options.sort(checkboxTitleComparator);
  }

  transform(value: any, args?: any): any {
    if (Array.isArray(value)) {
      return value.map(v => DietToName.get(v)).join(', ');
    }

    return DietToName.get(value);
  }

}
