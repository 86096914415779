import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ApiService,
  AVAILABLE_MONTHS,
  CopyAvailableMeal, GetCalendarYearsResponse,
  GetCopyAvailableMealsResponse,
  getWeekdayNames,
  MealSize
} from '@app/shared';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';

interface AvailableDate {
  day: number;
  current: boolean;
  available: boolean;
}

@Component({
  selector: 'app-menu-copy',
  templateUrl: './menu-copy.component.html',
  styleUrls: ['./menu-copy.component.scss']
})
export class MenuCopyComponent implements OnInit {

  @Input()
  yearId;

  @Output()
  selected = new EventEmitter<{
    date: string;
    mealSize: MealSize,
    sourceYearId: number,
  }>();

  sourceYearId: number;

  year: number;

  visible: boolean;

  weekDays: string[];

  availableDates: AvailableDate[];

  month: number;

  monthStr: string;

  selectedDay: number;

  date: string;

  prevMonth: number;

  nextMonth: number;

  mealSize: MealSize;

  availableSizes: MealSize[];

  availableMeals: CopyAvailableMeal[];

  allMeals: CopyAvailableMeal[];

  years$: Observable<GetCalendarYearsResponse[]>;

  constructor(protected apiService: ApiService) {
  }

  ngOnInit() {
    this.weekDays = getWeekdayNames();
  }

  show() {
    this.sourceYearId = this.yearId;
    this.setMonth(new Date().getMonth() + 1);
    this.years$ = this.apiService.calendarGetYears();
  }

  setMonth(month: number) {
    this.apiService.menuGetCopyAvailableMeals({
      month,
      yearId: this.sourceYearId,
    })
      .subscribe(rv => this.setValue(rv));
  }

  setSourceYear(event: Event) {
    this.sourceYearId = ~~(event.target as HTMLSelectElement).value;
    this.setMonth(new Date().getMonth() + 1);
  }

  protected setValue(rv: GetCopyAvailableMealsResponse) {
    this.visible = true;
    this.selectedDay = null;
    this.availableSizes = [];
    this.availableMeals = [];
    this.allMeals = [];

    const date = dayjs(new Date(rv.year, rv.month - 1, 1));

    let startDate = dayjs(date).startOf('week');
    const endDate = dayjs(date).endOf('month').endOf('week');

    this.monthStr = date.format('MMMM');
    this.month = date.month();
    this.year = date.year();
    const monthIndex = AVAILABLE_MONTHS.indexOf(date.month() + 1);
    if (monthIndex > 0) {
      this.prevMonth = AVAILABLE_MONTHS[monthIndex - 1];
    } else {
      this.prevMonth = 0
    }
    if (monthIndex < AVAILABLE_MONTHS.length - 1) {
      this.nextMonth = AVAILABLE_MONTHS[monthIndex + 1];
    } else {
      this.nextMonth = 0;
    }

    this.availableDates = [];
    while (startDate.isBefore(endDate)) {
      const dbDate = startDate.format('YYYY-MM-DD');
      const available = rv.availableDates.includes(dbDate);
      this.availableDates.push({
        day: startDate.date(),
        current: startDate.month() === rv.month - 1,
        available,
      })
      startDate = startDate.add(1, 'day');
    }
  }

  protected setMeals(allMeals: CopyAvailableMeal[]) {
    this.availableSizes = [];
    allMeals.forEach(m => {
      if (this.availableSizes.indexOf(m.mealSize) == -1) {
        this.availableSizes.push(m.mealSize);
      }
    });
    this.availableSizes.sort((a, b) => a - b);
    this.allMeals = allMeals;
    this.setMealSize(this.availableSizes[0]);
  }

  setMealSize(mealSize: MealSize | 'prev' | 'next') {
    if (mealSize == 'prev' || mealSize === 'next') {
      const index = this.availableSizes.indexOf(this.mealSize);
      if (mealSize === 'next') {
        mealSize = this.availableSizes[index + 1];
      } else if (mealSize === 'prev') {
        mealSize = this.availableSizes[index -1];
      }
    }

    this.mealSize = mealSize;
    this.availableMeals = this.allMeals.filter(m => m.mealSize === this.mealSize).sort((a, b) => a.mealCode - b.mealCode);
  }

  setDate(day: number) {
    if (!this.availableDates.find(d => d.day === day).available) {
      return;
    }

    const date = dayjs(new Date(this.year, this.month, day)).format('YYYY-MM-DD');
    this.selectedDay = day;

    this.apiService.menuGetCopyAvailableMealsForDate({
        yearId: this.sourceYearId,
        date,
      })
      .subscribe(rv => {
        this.date = date;
        this.setMeals(rv.meals);
      });
  }

  submit() {
    this.visible = false;
    this.selected.emit({
      date: this.date,
      mealSize: this.mealSize,
      sourceYearId: this.sourceYearId,
    });
  }
}
