import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AllDiets,
  AllGastroProduct,
  AllGastroRecipe,
  AllQualitySchemes,
  AllSeasons,
  ApiService,
  BasicFood,
  CheckboxArrayOptionSimple,
  DietNamePipe,
  FavoriteMeal,
  FoodKind,
  MealCode,
  MealSize,
  MenuMealFood,
  nonZeroValidator,
  SeasonNamePipe,
  SubscriptionSink
} from '@app/shared';
import { checkboxTitleComparator } from '@app/shared/checkbox-array/checkbox-array.component';
import { GastroNamePipe } from '@app/shared/gastro-name.pipe';
import { QualitySchemePipe } from '@app/shared/quality-scheme.pipe';
import { of, ReplaySubject, zip } from 'rxjs';
import { CountComponent } from '@app/shared/count/count.component';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-menu-food-search',
  templateUrl: './menu-food-search.component.html',
  styleUrls: ['./menu-food-search.component.scss']
})
export class MenuFoodSearchComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('queryInput', { static: true })
  queryInput: ElementRef<HTMLInputElement>;

  @ViewChild('weightInput', { static: false })
  weightInput: CountComponent;

  displayed = false;

  products: BasicFood[];

  recipes: BasicFood[];

  @Input()
  yearId: number;

  @Input()
  all: boolean;

  @Output()
  selectedFood = new EventEmitter<[MealCode, number, number, boolean, number?]>();

  @Output()
  selectedMeal = new EventEmitter<[MealCode, FavoriteMeal]>();

  @Output()
  closed = new EventEmitter<void>();

  currentFood: BasicFood | null = null;

  searchForm: FormGroup;

  anyFilter = false;

  weightForm: FormGroup;

  mealCode: MealCode;

  mealSize: MealSize;

  allGastro: CheckboxArrayOptionSimple[];

  allQualitySchemes: CheckboxArrayOptionSimple[];

  allDiets: CheckboxArrayOptionSimple[];

  allSeasons: CheckboxArrayOptionSimple[];

  favorites: FavoriteMeal[];

  shownFavorites: FavoriteMeal[];

  setWeightFocus = false;

  editId?: number;

  protected recentProducts: BasicFood[];

  protected recentRecipes: BasicFood[];

  protected dietId: number;

  protected subscription = new SubscriptionSink();

  public readonly initialized$ = new ReplaySubject(1);

  constructor(
    protected apiService: ApiService,
    protected fb: FormBuilder,
    protected router: Router,
    gastroName: GastroNamePipe,
    qualitySchemeName: QualitySchemePipe,
    dietName: DietNamePipe,
    seasonName: SeasonNamePipe,
  ) {

    this.allGastro = AllGastroProduct.concat(AllGastroRecipe).map(gastro => ({
      title: gastroName.transform(gastro),
      value: gastro
    })).sort(checkboxTitleComparator);
    this.allQualitySchemes = AllQualitySchemes.map(scheme => ({
      title: qualitySchemeName.transform(scheme),
      value: scheme,
    })).sort(checkboxTitleComparator);
    this.allDiets = AllDiets.map(diet => ({
      title: dietName.transform(diet),
      value: diet,
    })).sort(checkboxTitleComparator);
    this.allSeasons = AllSeasons.map(season => ({
      title: seasonName.transform(season),
      value: season,
    }));
  }

  ngOnInit() {
    this.weightForm = this.fb.group({
      weight: [null, [Validators.required, nonZeroValidator]],
    });
    this.searchForm = this.fb.group({
      query: [],
      gastro: [],
      qualityScheme: [],
      diet: [],
      season: [],
    });
    this.subscription.sink = this.searchForm.valueChanges.subscribe((v) => {
      this.anyFilter = v.gastro !== null || v.qualityScheme !== null || v.diet !== null || v.season !== null;
      return this.executeSearch();
    });
    this.initialized$.next(true);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  search(mealCode: MealCode, mealSize: MealSize, dietId: number, menuFood?: MenuMealFood, foodId?: number) {
    this.searchForm.reset();
    this.weightForm.reset();
    this.dietId = dietId;
    this.subscription.sink = zip(
      this.apiService.foodFindFood({ yearId: this.yearId, dietId: this.dietId, mealSize, mealCode, kind: FoodKind.Product }),
      this.apiService.foodFindFood({ yearId: this.yearId, dietId: this.dietId, mealSize, mealCode, kind: FoodKind.Recipe }),
      this.apiService.menuGetFavoriteMeals({ yearId: this.yearId, mealSize, dietId: this.dietId }),
      menuFood ? this.apiService.foodFindFood({yearId: this.yearId, mealSize, mealCode, ids: [menuFood.foodId]}) : of(null),
      foodId ? this.apiService.foodFindFood({yearId: this.yearId, mealSize, mealCode, ids: [foodId]}) : of(null)
    ).subscribe(([productResponse, recipeResponse, favorites, selectedFood, updatedFood]) => {
      this.recentProducts = productResponse.foods;
      this.recentRecipes = recipeResponse.foods;
      this.favorites = favorites;
      this.currentFood = null;
      this.mealCode = mealCode;
      this.mealSize = mealSize;
      this.displayed = true;
      this.editId = null;
      this.showRecent();
      if (menuFood && selectedFood) {
        this.setCurrent(selectedFood.foods[0]);
        this.setWeight(menuFood.weight);
        this.editId = menuFood.foodId;
      } else if (foodId && updatedFood) {
        this.setCurrent(updatedFood.foods[0]);
      }
      setTimeout(() => {
        this.queryInput.nativeElement.focus();
      }, 250);
    });
  }

  resetSearchForm() {
    this.searchForm.patchValue({
      gastro: null,
      qualityScheme: null,
      diet: null,
      season: null,
    });
  }

  executeSearch() {
    const { query, qualityScheme, gastro, diet, season } = this.searchForm.value;

    if (query || gastro || qualityScheme || diet || season) {
    } else {
      return this.showRecent();
    }
    this.currentFood = null;
    this.subscription.sink = this.apiService.foodFindFood({
      yearId: this.yearId,
      mealSize: this.mealSize,
      mealCode: this.mealCode,
      dietId: this.dietId,
      query,
      gastro,
      qualityScheme,
      diet,
      season,
    })
      .subscribe(response => {
        this.products = response.foods.filter(food => food.kind === FoodKind.Product);
        this.recipes = response.foods.filter(food => food.kind === FoodKind.Recipe);
      });

    this.shownFavorites = this.favorites.filter(f => f.title.includes(query));
  }

  protected getReturnUrl(): string {
    const returnUrl = this.router.parseUrl(this.router.url);
    returnUrl.queryParams['meal'] = this.mealCode;
    return returnUrl.toString();
  }

  setCurrent(food: BasicFood) {
    if (food.kind == FoodKind.Recipe && food.isDietUnconfirmed) {
      this.router.navigate(['/recipe', food.id], {queryParams: {return: this.getReturnUrl()}});
      return;
    }

    this.currentFood = food;
    let weight: number | null = null;

    if (food.guide[0] && food.guide[1]) {
      weight = (food.guide[0] + food.guide[1])/2
    } else if (food.packages?.length > 0) {
      weight = food.packages[0]
    }

    this.setWeight(weight ?? null);

    this.setWeightFocus = true;
  }

  ngAfterViewChecked() {
    if (this.setWeightFocus) {
      this.weightInput?.focus();
      this.setWeightFocus = false;
    }
  }

  backgroundClick($event) {
    if ($event.target.classList.contains('container')) {
      this.close();
    }
  }

  close() {
    this.displayed = false;
    this.closed.emit();
  }

  submit($event) {
    $event.preventDefault();

    const weight = parseFloat(this.weightForm.value.weight);
    if (this.currentFood && weight) {
      this.selectedFood.emit([this.mealCode, this.currentFood.id, weight, this.all, this.editId]);
      this.close();
    }
  }

  setWeight(weight: number) {
    // TODO: all, should be set to true only if noDiet
    this.weightForm.patchValue({weight});
  }

  clickMeal(meal: FavoriteMeal) {
    this.selectedMeal.emit([this.mealCode, meal]);
    this.close();
  }

  clearFilter(name: string) {
    this.searchForm.get(name).setValue(null);
  }

  protected showRecent() {
    this.products = this.recentProducts;
    this.recipes = this.recentRecipes;
    this.shownFavorites = this.favorites ?? [];
  }

  removeFav($event: MouseEvent, meal: FavoriteMeal) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!confirm('Ste prepričani?')) {
      return;
    }

    this.apiService.menuUnfavoriteMeal({
      yearId: this.yearId,
      mealSize: meal.mealSize,
      mealCode: meal.mealCode,
      dietId: meal.dietId,
      date: meal.date,
    }).pipe(switchMap(() =>
      this.apiService.menuGetFavoriteMeals({ yearId: this.yearId, mealSize: this.mealSize, dietId: this.dietId }),
    )).subscribe((rv) => {
      this.favorites = rv;
      this.executeSearch()
    });
  }

}
