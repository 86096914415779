<div *ngIf="visible" class="container">
  <div class="dialog">
    <button class="btn-close" (click)="visible = false"></button>
    <h1>{{ mealCode | mealName }}</h1>
    <form [formGroup]="form" (submit)="submit()">
      <label class="form-group">
        <span class="form-label">Naziv</span>
        <input type="text" formControlName="title" #titleEl>
      </label>
      <label class="form-group">
        <span class="form-label">Sezone</span>
        <app-checkbox-array [options]="seasonOptions" all="Vse" formControlName="seasonQuality" altStyle="true"></app-checkbox-array>
      </label>
      <div class="food-table">
        <ng-container *ngFor="let food of foods">
          <div>{{ food.name }}</div>
          <div>{{ food.weight | number:'1.0-2' }}g</div>
        </ng-container>
      </div>
      <app-corner-buttons>
        <button class="btn-full-accept-large" [disabled]="form.invalid" type="submit">Potrdi</button>
      </app-corner-buttons>
    </form>
  </div>
</div>
