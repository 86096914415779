<div class="home-container">
  <h1 class="home-title">Zasebnost</h1>

  <div class="home-content">
    <h2 class="home-subtitle">PRAVICE INTELEKTUALNE LASTNINE </h2>
    S pojmom spletnega mesta je v tem pravnem obvestilu mišljena vsa vsebina na spletnem naslovu <a href="http://www.solskilonec.si">http://www.solskilonec.si</a>. (v nadaljevanju: spletno mesto).
    Vse informacije in gradiva, tako pisna kot slikovna so v zakonsko dovoljenem okviru predmet avtorske zaščite ali druge oblike zaščite intelektualne lastnine Nacionalnega inštituta za javno zdravje (v nadaljevanju: NIJZ), ter z njim povezanih partnerjev in oseb, katerih vsebine so vključene v spletno mesto.<br>
    <br>
    Uporabnik lahko uporablja vsebine na spletnem mestu izključno za svojo osebno in nekomercialno uporabo, pri čemer ne sme spreminjati označb avtorskih pravic, drugih obvestil o pravicah intelektualne lastnine ali obvestil o drugih pravicah. Avtorji vsebin niso odgovorni za morebitne škodljive posledice uporabe. Vsaka druga oblika uporabe vsebin spletnega mesta (kot je na primer kopiranje, reproduciranje, distribuiranje in podobno) v komercialne namene je prepovedana.

    <h2 class="home-subtitle">OMEJITEV ODGOVORNOSTI</h2>
    Vse informacije in gradiva na spletnem mestu so informativne narave. NIJZ se trudi za pravilnost, ažurnost in popolnost objavljenih podatkov, vendar za njihovo točnost in celovitost ne prevzema nobene pravne odgovornosti, ki se nanaša na natančnost, popolnost ali uporabnost katerekoli informacije. NIJZ ni odgovoren za občasno nedelovanje spletnega mesta in ne odgovarja za kakršnokoli škodo, ki bi morebiti nastala uporabniku v zvezi z dostopom, uporabo in/ali nezmožnostjo uporabe informacij na tem spletnem mestu, in/ali zaradi kakršnekoli napake ali pomanjkljivosti v njihovi vsebini.<br>
    <br>
    <strong>Povezave do spletnih strani tretjih oseb</strong><br>
    <br>
    To spletno mesto vsebuje tudi informacije in povezave na spletna mesta tretjih oseb, vendar na NIJZ ne prevzemamo nikakršne odgovornosti za njihovo vsebino in obliko. Pri obisku in uporabi povezanih spletnih mest je odgovornost za vse primere izključena.

    <h2 class="home-subtitle">PRAVICA DO SPREMINJANJA VSEBINE SPLETNEGA MESTA</h2>
    Na NIJZ si pridržujemo pravico, da spremenimo vsebino tega spletnega mesta kadarkoli, na kakršenkoli način in ne glede na razlog brez predhodnega opozorila. Prav tako ne prevzemamo odgovornosti za kakršnekoli posledice takih sprememb.

    <h2 class="home-subtitle">INFORMACIJE O OBDELAVI OSEBNIH PODATKOV ZA POSAMEZNIKE</h2>
    UPRAVLJAVEC IN POOBLAŠČENA OSEBA ZA VARSTVO OSEBNIH PODATKOV<br>
    <br>
    Nacionalni inštitut za javno zdravje kot upravljalec posveča posebno pozornost varovanju zasebnosti informacij, ki jih pridobi od uporabnikov tega spletnega mesta, in njihovih osebnih podatkov ter ravna skrbno in v skladu z veljavnimi predpisi o varstvu osebnih podatkov.<br>
    <br>
    <strong>Nacionalni inštitut za javno zdravje</strong>, Trubarjeva 2, 1000 LJUBLJANA, Telefon: +386 1 2441 400<br>
    <br>
    E- naslov: <a href="mailto:info@nijz.si">info@nijz.si</a><br>
    <br>
    Za vse informacije glede obdelave vaših osebnih podatkov ima NIJZ pooblaščeno osebo za varstvo podatkov, s katero lahko stopite v stik na elektronskem naslovu: <a href="mailto:vop@nijz.si">vop@nijz.si</a>.

    <h2 class="home-subtitle">OSEBNI PODATKI IN NAMEN NJIHOVE OBDELAVE</h2>
    Osebne podatke, ki nam jih posredujete ob prijavi na e-novice in izpolnitvijo kontaktnega obrazca, obdelujemo z vašim jasnim in nedvoumnim soglasjem, ki temelji na členu 6 (1)a Splošne uredbe EU o varstvu osebnih podatkov in jih obdelujemo izključno za namen obveščanja o novostih in aktualnih temah preko e-novic in za priprave odgovora na vaše morebitno vprašanje ter ostale komunikacije s strokovnjakom. Svoje soglasje lahko kadarkoli umaknete s klikom na povezavo »odjava« ali preko elektronske pošte na naslov <a href="mailto:solskilonec@nijz.si">solskilonec@nijz.si</a>. Morebiten umik soglasja ne vpliva na zakonitost obdelave vaših osebnih podatkov v času, preden je bil umik podan.

    <h2 class="home-subtitle">OBDOBJE HRAMBE</h2>
    NIJZ hrani vaše osebne podatke do preklica vašega soglasja, za katero je bilo dano. Po preklicu soglasja bomo vaše osebne podatke nemudoma učinkovito in trajno zbrisali.<br>
    <br>
    <strong>Prenos osebnih podatkov</strong><br>
    <br>
    Osebnih podatkov in kontaktnih informacij, ki nam jih boste posredovali preko kontaktnih obrazcev ne bomo uporabljali v druge namene in jih ne bomo posredovani tretjim nepooblaščenim osebam brez vašega soglasja. Zavezujemo se, da vaših osebnih podatkov ne bodo posredovali ali prenesli v tretjo državo izven Evropske unije in/ali Evropskega gospodarskega prostora ali v mednarodno organizacijo.<br>
    <br>
    <strong>Vaše pravice</strong><br>
    <br>
    V skladu z določili Splošne uredbe EU o varstvu osebnih podatkov (GDPR) imate pravico do dostopa do vaših osebnih podatkov, pravico do popravka, pravico do prenosljivosti, pravico zahtevati omejitev obdelave osebnih podatkov in pravico do ugovora.<br>
    <br>
    Pri uveljavljanju vseh vaših pravic ali pri pridobivanju dodatnih informacij, se obrnite na našo pooblaščeno osebo za varstvo podatkov, ki vam je na voljo na e-naslovu: <a href="mailto:vop@nijz.si">vop@nijz.si</a> s pripisom za solskilonec@nijz.si.<br>
    <br>
    Če menite, da so kršene vaše pravice ali predpisi o varstvu osebnih podatkov se lahko pritožite pristojnemu državnemu organu: Informacijski pooblaščenec Republike Slovenije (Zaloška 59, 1000 Ljubljana, telefon: 01 230 97 30, faks: 01 230 97 78, e-pošta: <a href="mailto:gp.ip@ip-rs.si">gp.ip@ip-rs.si</a>).<br>
    <br>
    <strong>Spremembe</strong><br>
    <br>
    Pridržujemo si pravico, da predmetno Informacijo po potrebi občasno prilagodimo dejanskim razmeram in zakonodaji s področja varstva osebnih podatkov. Iz tega razloga vas prosimo, da pred vsakim posredovanjem osebnih podatkov preverite aktualno različico, da boste seznanjeni z morebitnimi spremembami in dopolnitvami.<br>
    <br>
    V Ljubljani, dne 27. 5. 2021
  </div>
</div>
