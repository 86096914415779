import { Component, Input, OnChanges } from '@angular/core';
import { Color, ColorToRgb, guideToWidth } from '@app/shared';

@Component({
  selector: 'app-value-bar',
  templateUrl: './value-bar.component.html',
  styleUrls: ['./value-bar.component.scss']
})
export class ValueBarComponent implements OnChanges {

  @Input()
  color: Color;

  @Input()
  min: number = 0;

  @Input()
  max: number;

  @Input()
  value: number | [number, number] | [number, number, number];

  @Input()
  showGuide: boolean;

  width: string;

  guideLeft: any;

  guideWidth: any;

  valid: boolean;

  invalid: boolean;

  background: string;

  constructor() { }

  ngOnChanges(): void {
    let value: number;
    let min: number;
    let max: number;

    if (Array.isArray(this.value)) {
      if (this.value.length === 3) {
        [value, min, max] = this.value;
      } else if (this.value.length === 2) {
        min = 0;
        [value, max] = this.value;
      } else {
        throw new Error(`Invalid value: ${this.value}`);
      }
    } else {
      value = this.value;
      min = this.min;
      max = this.max;
    }

    this.width = guideToWidth(value, max);
    this.guideLeft = guideToWidth(min, max);
    this.guideWidth = guideToWidth(max - min, max);
    this.valid = value >= min && value <= max;
    this.invalid = !this.valid;
    this.background = ColorToRgb.get(this.color);

  }

}
