import { Component, OnInit } from '@angular/core';
import { ApiService, UserResponse } from '@app/shared';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  title = 'sauce-pan';

  load = false;

  constructor(protected router: Router, protected api: ApiService) {
  }

  ngOnInit() {
    combineLatest([
      this.router.events
        .pipe(
          map(event => {
            if (event instanceof NavigationStart) {
              return true;
            } else if (
              event instanceof NavigationEnd ||
              event instanceof NavigationCancel ||
              event instanceof NavigationError
            ) {
              return false;
            }
            return null;
          }),
          filter(load => load !== null)
        ),

      this.api.active$
    ])
      .pipe(
        debounceTime(250)
      )
      .subscribe(([navigate, api]) => {
        this.load = navigate || api;
      });
  }

}
