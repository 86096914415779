import { Pipe, PipeTransform } from '@angular/core';
import { Dunford } from './api.abstract.service';

const dunfordToName = new Map([
  [1000, 'Pijače'],
  [1001, 'Sadni in zelenjavni sokovi'],
  [100101, 'Sokovi'],
  [100102, 'Nektarji'],
  [1002, 'Brezalkoholne pijače'],
  [100201, 'Brezalkoholne pijače'],
  [100205, 'Energijske pijače'],
  [1003, 'Sirupi'],
  [100300, 'Sirupi'],
  [1004, 'Kava in čaj'],
  [100401, 'Čaji'],
  [100403, 'Kava'],
  [100404, 'Kava instant'],
  [100405, 'Kavni pripravki'],
  [1005, 'Izotonične pijače'],
  [100500, 'Izotonične pijače'],
  [1006, 'Alkoholne pijače'],
  [100600, 'Alkoholne pijače'],
  [1007, 'Vode'],
  [100700, 'Vode'],
  [1100, 'Kruh in pekovski izdelki'],
  [1101, 'Kruh'],
  [110101, 'Kruh'],
  [110102, 'Hrustljavčki'],
  [110103, 'Tortilje'],
  [110104, 'Testo'],
  [1102, 'Piškoti'],
  [110200, 'Piškoti'],
  [1103, 'Torte, mafini in pecivo'],
  [110300, 'Torte, mafini in pecivo'],
  [1200, 'Žita in žitni proizvodi'],
  [1201, 'Žitne ploščice'],
  [120100, 'Žitne ploščice'],
  [1202, 'Rezanci'],
  [1203, 'Žita za zajtrk'],
  [120301, 'Žita za zajtrk'],
  [120302, 'Kosmiči in otrobi'],
  [1204, 'Testenine'],
  [120401, 'Jušne zakuhe'],
  [120402, 'Sušene testenine'],
  [120403, 'Sveže testenine'],
  [120405, 'Sveže polnjene testenine'],
  [1205, 'Koruza'],
  [1206, 'Riž'],
  [120600, 'Riž'],
  [1207, 'Kuskus'],
  [1208, 'Nepredelana žita'],
  [120800, 'Nepredelana žita'],
  [1300, 'Slaščice'],
  [1301, 'Čokolada in sladkarije'],
  [130100, 'Čokolada in sladkarije'],
  [1302, 'Žele izdelki'],
  [130200, 'Žele izdelki'],
  [1303, 'Žvečilni gumi'],
  [130300, 'Žvečilni gumi'],
  [1400, 'Predpripravljena živila'],
  [1401, 'Pice'],
  [140100, 'Pice'],
  [1402, 'Juhe'],
  [140200, 'Juhe'],
  [1403, 'Pripravljeni obroki'],
  [140300, 'Pripravljeni obroki'],
  [1404, 'Predpripravljene solate in sendviči'],
  [140400, 'Predpripravljene solate in sendviči'],
  [1405, 'Drugo'],
  [140500, 'Priloge'],
  [1500, 'Mlečni izdelki'],
  [1501, 'Siri '],
  [150101, 'Skuta'],
  [150102, 'Namazi in topljeni siri'],
  [150103, 'Siri'],
  [150104, 'Nadomestki sira'],
  [1502, 'Jogurti'],
  [150201, 'Navadni jogurti'],
  [150202, 'Jogurti z okusom'],
  [150203, 'Jogurtovi napitki z okusom'],
  [150204, 'Nadomestki jogurtov'],
  [1503, 'Mleko'],
  [150301, 'Mleko'],
  [150302, 'Nadomestki mleka'],
  [150303, 'Mlečni napitki'],
  [150304, 'Koncentrirano mleko'],
  [150305, 'Instant kakav'],
  [1504, 'Smetana'],
  [150401, 'Smetana'],
  [150402, 'Nadomestki smetane'],
  [1505, 'Deserti'],
  [150500, 'Deserti'],
  [1506, 'Sladoledi'],
  [150600, 'Sladoledi'],
  [1600, 'Jedilna olja in emulzije'],
  [1601, 'Maslo in margarina'],
  [160101, 'Maslo'],
  [160102, 'Margarine'],
  [1602, 'Jedilna olja'],
  [160200, 'Jedilna olja'],
  [1700, 'Jajca'],
  [1701, 'Jajca'],
  [170100, 'Jajca'],
  [1800, 'Ribe in ribji izdelki'],
  [1801, 'Konzervirane ribe in morski sadeži'],
  [180101, 'Ribe v nalivu'],
  [180102, 'Ribe z zelenjavo'],
  [180103, 'Ribji namazi'],
  [1802, 'Sveže ribe'],
  [180201, 'Nepredelane ribe'],
  [180202, 'Predelani ribji izdelki'],
  [1803, 'Zamrznjene ribe'],
  [1900, 'Živila za posebne prehranske namene'],
  [1901, 'Otroška hrana'],
  [190101, 'Otroški napitki'],
  [190102, 'Mlečne formule'],
  [190103, 'Uvajalna hrana'],
  [1902, 'Nadomestki obrokov'],
  [190200, 'Nadomestki obrokov'],
  [2000, 'Sadje in zelenjava'],
  [2001, 'Zelenjava'],
  [200101, 'Zamrznjena zelenjava'],
  [200102, 'Vložena zelenjava'],
  [200103, 'Sušena zelenjava'],
  [2002, 'Sadje'],
  [200201, 'Zamrznjeno sadje'],
  [200202, 'Vloženo sadje'],
  [200203, 'Sveže sadje'],
  [200204, 'Sušeno sadje'],
  [200205, 'Mešanice oreškov in sadja'],
  [2003, 'Marmelade in namazi'],
  [200300, 'Marmelade in namazi'],
  [2004, 'Oreški in semena'],
  [200400, 'Oreški in semena'],
  [2100, 'Meso in mesni izdelki'],
  [2101, 'Predelani mesni izdelki'],
  [210101, 'Nepredelano meso'],
  [210102, 'Predelani mesni izdelki'],
  [210103, 'Mastni izdelki'],
  [210104, 'Paštete'],
  [2102, 'Nadomestki mesnih izdelkov'],
  [210200, 'Nadomestki mesnih izdelkov'],
  [2200, 'Prigrizki'],
  [2201, 'Čips in razni prigrizki'],
  [220100, 'Čips in razni prigrizki'],
  [2300, 'Omake in namazi'],
  [2301, 'Omake'],
  [230101, 'Sladke omake'],
  [230102, 'Omake za testenine'],
  [230103, 'Druge omake'],
  [2302, 'Majoneze / prelivi'],
  [230200, 'Majoneze / prelivi'],
  [2303, 'Namazi'],
  [230301, 'Sladki namazi'],
  [230302, 'Namazi iz oreškov'],
  [230303, 'Rastlinski namazi'],
  [230304, 'Ostali namazi'],
  [2400, 'Sladkor, med in podobni izdelki'],
  [2401, 'Med in sirupi'],
  [240101, 'Sladkor'],
  [240102, 'Med'],
  [240103, 'Sirupi'],
  [2500, 'Drugo'],
  [2501, 'Drugo'],
  [250101, 'Kis'],
  [250102, 'Prehranska dopolnila'],
  [250103, 'Začimbe'],
  [250104, 'Aditivi'],
  [250105, 'Ostalo'],
  [250106, 'Mešani raznoliki paketi '],
  [250107, 'Paketi več okusov'],
  [250108, 'Sol'],
  [2502, 'Drugo - sol'],
  [2600, 'Nerazvrščeno'],
  [2601, 'Nerazvrščeno'],
  [260101, 'Nerazvrščeno'],
]);

const levels = new Map([
  [0, ''],
  [1, '\u00A0'],
  [2, '\u00A0\u00A0'],
]);

@Pipe({
  name: 'dunford'
})
export class DunfordPipe implements PipeTransform {

  transform(value: Dunford, level = 0): any {
    return levels.get(level) + dunfordToName.get(value);
  }

}
