<div class="title">
  <a *ngIf="prev" class="prev" [routerLink]="['/calendar', prev]"></a>
  {{ monthName }}
  <a *ngIf="next" class="next" [routerLink]="['/calendar', next]"></a>
</div>
<div class="month">
  <div class="head" *ngFor="let day of weekDays">
    {{ day }}
  </div>

  <div class="day" *ngFor="let day of days" [class.current]="day.current" [class.active]="day.current && day.active">
    <ng-container *ngIf="day.current">
      <span (click)="setActive(day.date, !day.active)">{{ day.index }}</span>
      <div class="sub">{{ day.active ? 'Delovni' : 'Nedelovni'}}</div>
    </ng-container>
    <span *ngIf="!day.current">{{ day.index }}</span>
  </div>
</div>
<div style="margin-top: 15px;">
  <button type="button" class="btn-cancel" (click)="onRemove()">
    Izbriši mesec
  </button>
</div>
