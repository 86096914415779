import { Pipe, PipeTransform } from '@angular/core';
import { GastroToName, Gastro } from './api.abstract.service';

@Pipe({
  name: 'gastroName'
})
export class GastroNamePipe implements PipeTransform {

  transform(value: Gastro): string {
    return GastroToName.get(value);
  }

}
