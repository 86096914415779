<ul class="group-list">
  <li *ngFor="let group of groups" [class.selected]="selected?.id === group.id">
    <a [routerLink]="['/groups/edit', group.id]">
      <div class="group-name">
        <i class="icon-dot"></i>
        <span>{{ group.name }}</span>
      </div>
      <div class="group-type">{{ group.type | groupType }}</div>
      <button (click)="delete.emit(group)"><i class="icon-close"></i></button>
    </a>

  </li>
</ul>
