import { Directive, ElementRef, Host, Input, OnChanges, OnDestroy, Optional, Renderer2, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

const stateFunctions = [
  'markAsTouched', 'markAsUntouched', 'markAsDirty', 'markAsPristine', 'markAsPending', 'markAllAsTouched',
];

@Directive({
  selector: '[appFormClass]'
})
export class FormClassDirective implements OnDestroy, OnChanges {

  private control: AbstractControl;

  @Input()
  appFormClass: AbstractControl | string | number;

  subscription: Subscription;

  private originalStateFunctions: Map<string, any>;

  constructor(
    private renderer: Renderer2,
    private hostElement: ElementRef,
    @Optional() @Host() @SkipSelf()
    private parent: ControlContainer
  ) {}

  renderClasses() {
    const classes: [string, boolean][] = [
      ['ng-valid', this.control.valid],
      ['ng-invalid', this.control.invalid],
      ['ng-touched', this.control.touched],
      ['ng-untouched', this.control.untouched],
      ['ng-dirty', this.control.dirty],
      ['ng-pristine', this.control.pristine],
    ];

    classes.forEach(([clazz, enabled]) => {
      if (enabled) {
        this.renderer.addClass(this.hostElement.nativeElement, clazz);
      } else {
        this.renderer.removeClass(this.hostElement.nativeElement, clazz);
      }
    });
  }

  ngOnChanges() {
    this.ngOnDestroy();

    this.control = null;

    if (typeof(this.appFormClass) === 'string' || typeof(this.appFormClass) === 'number') {
      this.control = (this.parent.control as FormArray).get(this.appFormClass.toString());
    } else if (this.appFormClass instanceof AbstractControl) {
      this.control = this.appFormClass;
    }

    if (!this.control) {
      return;
    }

    this.originalStateFunctions = new Map();
    stateFunctions.forEach(name => {
      const origFunc = this.control[name];
      this.originalStateFunctions.set(name, origFunc);
      this.control[name] = (...args) => {
        origFunc.apply(this.control, ...args);
        this.renderClasses();
      };
    });

    this.subscription = this.control
      .statusChanges
      .subscribe(this.renderClasses.bind(this));

    this.renderClasses();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    if (this.originalStateFunctions) {
      this.originalStateFunctions.forEach((origFunc, name) =>
        this.control[name] = origFunc
      );
      this.originalStateFunctions = undefined;
    }
  }

}
