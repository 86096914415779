import { Component, OnDestroy, OnInit } from '@angular/core';
import { RpcError } from '@app/shared';
import { Observable} from 'rxjs';
import {SubmitButtonComponent} from "@app/shared/submit-button/submit-button.component";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  success: string;

  error: string;

  private hideTimeout;

  constructor() { }

  ngOnInit() {
    this.error = undefined;
  }

  reset = () => {
    this.success = undefined;
    this.error = undefined;
  }

  handle = (err: Error) => {
    if (err instanceof RpcError) {
      this.error = err.message;
    }
  }

  operator = (params?: {successMessage?: string, button?: SubmitButtonComponent}) => <T>(source: Observable<T>) => new Observable<T>(observer => {
    const self = this;
    this.reset();
    params?.button?.setStatus('submitted');
    return source.subscribe({
      next(x: T) {
        self.reset();
        if (params?.successMessage) {
          self.success = params.successMessage;
          clearTimeout(self.hideTimeout);
          self.hideTimeout = setTimeout(self.reset, 3e3);
        }
        observer.next(x);
        params?.button?.setStatus('saved');
      },
      error(err) {
        if (err instanceof RpcError) {
          self.error = err.message;
        } else {
          observer.error(err);
        }
        params?.button?.setStatus('force_dirty');
      },
      complete() { observer.complete(); }
    });
  })

  ngOnDestroy() {
    clearTimeout(this.hideTimeout);
  }

}
