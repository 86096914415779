import { Component, Input } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent {

  @Input()
  saveText = 'Shrani';

  @Input()
  savedText = 'Shranjeno';


  disabled = false;
  saved = false;

  @Input()
  dirty = false;

  setStatus(status: 'submitted' | 'saved' | 'initial' | 'dirty' | 'force_dirty' = 'initial') {
    if ((this.disabled || this.saved) && status === 'dirty') {
      return;
    }
    this.disabled = false;
    this.saved = false;
    this.dirty = false;

    if (status === 'submitted') {
      this.disabled = true;
    } else if (status === 'saved') {
      this.saved = true;
      of(null).pipe(delay(2e3)).subscribe(() => this.setStatus('initial'));
    } else if (status === 'dirty') {
      this.dirty = true;
    } else if (status === 'force_dirty') {
      this.dirty = true;
      this.saved = false;
      this.disabled = false;
    }
  }


}
