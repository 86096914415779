import { Component, OnInit, Input, OnChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isArray } from 'lodash-es';


export interface CheckboxArrayOptionSimple {
  value: any;
  title: string;
  disabled?: boolean;
  indent?: number;
}

interface CheckboxArrayOptionGroup {
  title: string;
  children: CheckboxArrayOptionSimple[];
}

export type CheckboxArrayOption = CheckboxArrayOptionSimple | CheckboxArrayOptionGroup;

export function checkboxTitleComparator(a: CheckboxArrayOption, b: CheckboxArrayOption) {
  return a.title.localeCompare(b.title);
}

export function checkboxIsGroup(option: CheckboxArrayOption): option is CheckboxArrayOptionGroup {
  return 'children' in option;
}

@Component({
  selector: 'app-checkbox-array',
  templateUrl: './checkbox-array.component.html',
  styleUrls: ['./checkbox-array.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxArrayComponent),
      multi: true
    }
  ]
})
export class CheckboxArrayComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input()
  options: CheckboxArrayOption[] = [];

  @Input()
  compact = false;

  @Input()
  all: string;

  @Input()
  add = 'Add';

  @Input()
  columns = 1;

  @Input()
  altStyle = false;

  disabled = false;

  selected: Set<any>;

  valueToTitle: Map<any, string>;

  protected changeListeners = [];

  constructor() { }

  isGroup = checkboxIsGroup;

  allValues: Set<any>;

  ngOnInit() {
  }

  ngOnChanges() {
    this.valueToTitle = new Map();
    this.options.forEach(option => {
      if (this.isGroup(option)) {
        option.children.forEach(child =>
          this.valueToTitle.set(child.value, child.title)
        );
      } else {
        this.valueToTitle.set(option.value, option.title);
      }
    });
    this.allValues = new Set();
    const addOption = (o: CheckboxArrayOption) => {
      if (checkboxIsGroup(o)) {
        o.children.forEach(s => addOption(s));
      } else {
        this.allValues.add(o.value);
      }
    };
    this.options.forEach(addOption);
  }

  protected notifyChangeListeners() {
    this.changeListeners.forEach(listener => listener(Array.from(this.selected.values())));
  }

  toggleOption($event, value) {
    if (this.selected.has(value)) {
      this.selected.delete(value);
    } else if ($event.target.checked) {
      this.selected.add(value);
    }

    this.notifyChangeListeners();
  }

  addOption($event) {
    const target = $event.target;
    const selectedIndex = target.selectedIndex;
    target.selectedIndex = 0;

    let index = 0;
    this.options.some(option => {
      if (this.isGroup(option)) {
        return option.children.some(({value}) => {
          if (this.selected.has(value)) {
            return false;
          }
          index++;
          if (selectedIndex === index) {
            this.selected.add(value);
            return true;
          }
        });
      }

      if (this.selected.has(option.value)) {
        return false;
      }
      index++;
      if (selectedIndex === index) {
        this.selected.add(option.value);
        return true;
      }

      return false;
    });

    this.notifyChangeListeners();
  }

  removeOption(value) {
    this.selected.delete(value);
    this.notifyChangeListeners();
  }

  writeValue(obj: any): void {
    if (isArray(obj)) {
      this.selected = new Set(obj);
    } else {
      this.selected = new Set();
    }
  }

  registerOnChange(fn: any): void {
    this.changeListeners = [fn];
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  indent(n: number | undefined) {
    if (!n) {
      return '';
    }
    return [...Array(n)].map(() => '\u00A0').join('');
  }

  toggleAll($event) {
    if ($event.target.checked) {
      this.selected = new Set(this.allValues.values());
    } else {
      this.selected = new Set();
    }
    this.notifyChangeListeners();
  }

  get allSelected() {
    return this.all && this.selected.size === this.allValues.size;
  }
}
