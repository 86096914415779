import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Day, getMonthName, getWeekdayNames } from '@app/shared';

@Component({
  selector: 'app-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss']
})
export class CalendarMonthComponent implements OnInit, OnChanges {

  @Input()
  month: number;

  @Input()
  days: Day[];

  @Input()
  prev: number;

  @Input()
  next: number;

  @Output()
  remove = new EventEmitter<void>();

  @Output()
  active = new EventEmitter<[string, boolean]>();

  weekDays: string[];

  monthName: string;

  constructor() { }

  ngOnInit() {
    this.weekDays = getWeekdayNames();
  }

  ngOnChanges() {
    this.monthName = getMonthName(this.month);
  }

  setActive(date: string, active: boolean) {
    this.active.emit([date, active]);
  }

  onRemove() {
    if (!confirm(`Ste prepričani, da je cel ${this.monthName} nedelaven?`)) {
      return;
    }

    this.remove.emit();
  }
}
