import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SubscriptionSink } from '../util';
import { BasicFood } from '@app/shared';
import { debounceTime, switchMap } from 'rxjs/operators';

type SearchProvider = (query: string) => Observable<BasicFood[]>;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  @Input()
  label: string;

  @Input()
  searchProvider: SearchProvider;

  @Input()
  variant: 'add' | 'search';

  @Input()
  help: string;

  @Output()
  selected = new EventEmitter<BasicFood>();

  @ViewChild('search', { static: true })
  searchEl: ElementRef<HTMLInputElement>;

  @ViewChild('dropdown', { static: true })
  dropdownEl: ElementRef<HTMLDivElement>;

  @ViewChild('host', {static: true})
  hostEl: ElementRef<HTMLLabelElement>;

  current: number = null;

  results: BasicFood[] = null;

  prevQuery: string;

  nextQuery = new Subject<string>();

  protected subscription = new SubscriptionSink();

  constructor() { }

  ngOnInit() {
    this.subscription.sink = this.nextQuery.pipe(
      debounceTime(500),
      switchMap(query => this.searchProvider(query)),
    ).subscribe(results => {
      if (results.length > 0) {
        this.current = 0;
      } else {
        this.current = null;
      }
      this.results = results;
      const width = this.hostEl.nativeElement.clientWidth;
      this.dropdownEl.nativeElement.style.setProperty('min-width', `${width}px`);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  keyDown($event) {
    if (this.current === null) {
      return;
    }
    if ($event.keyCode === 40) {
      this.current = Math.min(this.current + 1, this.results.length - 1);
      $event.preventDefault();
    } else if ($event.keyCode === 38) {
      this.current = Math.max(this.current - 1, 0);
      $event.preventDefault();
    } else if ($event.keyCode === 13) {
      this.selected.emit(this.results[this.current]);
      this.reset();
      $event.preventDefault();
    } else if ($event.keyCode === 27) {
      this.reset();
    }
  }

  keyUp($event) {
    const query = $event.target.value;

    if (query !== this.prevQuery) {
      this.prevQuery = query;
    } else {
      return;
    }

    this.nextQuery.next($event.target.value);
  }

  reset() {
    this.results = null;
    this.current = null;
    this.prevQuery = null;
    this.searchEl.nativeElement.value = '';
  }

  click(result: BasicFood) {
    this.selected.emit(result);
    return this.reset();
  }

  blur() {
    setTimeout(this.reset.bind(this), 250);
  }

}
