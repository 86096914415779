<app-title icon="absence_thin" text="Odsotnost">
  <app-toggle-help>
    <p>
      Večina vzgojno-izobraževalnih zavodov ima omogočeno dnevno odjavo od šolske prehrane. S tem omogočajo ekonomično ravnanje s hrano. Orodje omogoča dnevno prilagajanje števila naročnikov na način, da v skupinah naročnikov, ki ste jih v začetku šolska opredelili kot upravičence do šolske prehrane, dodajate število odsotnih (vključno oseb z medicinsko indiciranimi dietami).
    </p>
  </app-toggle-help>
</app-title>

<div class="container">
  <div>
    <div class="title">Časovno obdobje</div>
    <div class="date-range" [formGroup]="rangeForm">
      <label>Od</label>
      <ng-container formGroupName="from">
        <span class="date-range--day">
          <button type="button" (click)="incDateRange('from', 'day', 1)"></button>
          <input type="number" formControlName="day">
          <button type="button" (click)="incDateRange('from', 'day', -1)"></button>
        </span>
          <span class="date-range--month">
          <button type="button" (click)="incDateRange('from', 'month', 1)"></button>
          <input type="number" formControlName="month" min="1" max="12">
          <button type="button" (click)="incDateRange('from', 'month', -1)"></button>
        </span>
          <span class="date-range--year">
          <button type="button" (click)="incDateRange('from', 'year', 1)"></button>
          <input type="number" formControlName="year">
          <button type="button" (click)="incDateRange('from', 'year', -1)"></button>
        </span>
      </ng-container>
      <label>Do</label>
      <ng-container formGroupName="to">
        <span class="date-range--day">
          <button type="button" (click)="incDateRange('to', 'day', 1)"></button>
          <input type="number" formControlName="day">
          <button type="button" (click)="incDateRange('to', 'day', -1)"></button>
        </span>
        <span class="date-range--month">
          <button type="button" (click)="incDateRange('to', 'month', 1)"></button>
          <input type="number" formControlName="month" min="1" max="12">
          <button type="button" (click)="incDateRange('to', 'month', -1)"></button>
        </span>
        <span class="date-range--year">
          <button type="button" (click)="incDateRange('to', 'year', 1)"></button>
          <input type="number" formControlName="year">
          <button type="button" (click)="incDateRange('to', 'year', -1)"></button>
        </span>
      </ng-container>
    </div>
    <div class="toggle-workday">
      <button (click)="toggleWorkday()">
        <i class="icon-toggle-workday"></i>Spremeni delavnik/nedelavnik
      </button>
    </div>
    <div class="month-picker">
      <button *ngIf="prevMonth" type="button" (click)="navigateTo(prevMonth)"></button>
      <span>
        {{ monthName }}
      </span>
      <button *ngIf="nextMonth" type="button" (click)="navigateTo(nextMonth)"></button>
    </div>
    <div class="calendar">
      <div *ngFor="let name of weekDays" class="name">{{ name }}</div>
      <div *ngFor="let day of days; let i = index; let last = last" class="day" [class.active]="day.current && day.active" [class.current]="day.current && !day.active" [class.weekday]="i % 7 < 5" [class.selected]="day.date >= fromDate && day.date <= toDate" [class.holiday]="holidays.has(day.date)" [class.today]="day.date == today">
        <span (click)="navigateTo(day.date)" *ngIf="day.current">{{ day.index }}</span>
      </div>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="white-container" *ngIf="!anyWorkday">
      <div class="title">
        Ni delovni dan
      </div>
    </div>
    <div class="white-container" *ngIf="anyWorkday">
      <div class="title">
        Dodano število odsotnih
        <button class="btn-save-all-exceptions" *ngIf="isMultiDay && form.dirty" (click)="saveGroupIndex = true">Dodaj<br>vse</button>
      </div>
      <div class="warning" *ngIf="isMultiDay">
        Pozor: Vnešeno število odsotnih se bodo dodalo oz. odvzelo trenutnemu stanju vsakega dneva časovnega obdobja, ki ste ga izbrali. Potrebna je potrditev.
      </div>
      <div class="groups" formArrayName="counts">
        <ng-container *ngFor="let group of groups; let groupIndex = index" [formArrayName]="groupIndex">
          <div class="group--name" [class.expanded]="!groupHidden(groupIndex)">
            {{ group.name }}
            <app-checkbox (toggled)="toggleGroupAbsent(groupIndex, $event)" [checked]="isGroupAbsent(groupIndex)"></app-checkbox>
            <span (click)="toggleGroup(groupIndex)"></span>
          </div>
          <ng-container *ngFor="let size of groupSizes.get(groupIndex); let sizeIndex = index" [formArrayName]="sizeIndex" class="group--diets" [class.expanded]="expandedSizes.get(groupIndex).get(sizeIndex)">
            <div class="group--size" [class.expanded]="!sizeHidden(groupIndex, sizeIndex)" [class.hidden]="groupHidden(groupIndex)">
              {{ size | mealSize : 'name' }}, {{ size | mealSize : 'ageGroup' | lowercase }}
              <app-checkbox (toggled)="toggleSizeAbsent(groupIndex, sizeIndex, $event)" [checked]="isSizeAbsent(groupIndex, sizeIndex)"></app-checkbox>
              <span (click)="toggleSize(groupIndex, sizeIndex)"></span>
            </div>
            <ng-container *ngFor="let dietNameAndDietId of groupDiets.get(groupIndex).get(sizeIndex); let dietIndex = index, let lastDiet = last" [formArrayName]="dietIndex">
              <div class="diet-name" [class.diet-last]="lastDiet" [class.hidden]="sizeHidden(groupIndex, sizeIndex)">
                <span class="clickable" *ngIf="dietNameAndDietId[1] === noDietId" (click)="incrementCount(groupIndex, sizeIndex, dietIndex, 1)">{{ dietNameAndDietId[0] }}</span>
                <span class="clickable" *ngIf="dietNameAndDietId[1] !== noDietId" (click)="toggleDiet(groupIndex, sizeIndex, dietIndex, true)">{{ dietNameAndDietId[0] }}</span>
              </div>
              <ng-container *ngIf="dietNameAndDietId[1] === noDietId">
                <div class="absence-down" [class.hidden]="sizeHidden(groupIndex, sizeIndex)" [class.diet-last]="lastDiet">
                  <button type="button" (click)="incrementCount(groupIndex, sizeIndex, dietIndex, -1)" ></button>
                </div>
                <div class="absence-up" [class.hidden]="sizeHidden(groupIndex, sizeIndex)" [class.diet-last]="lastDiet">
                  <button type="button" (click)="incrementCount(groupIndex, sizeIndex, dietIndex, 1)"></button>
                </div>
              </ng-container>
              <div class="diet-toggle" [class.hidden]="sizeHidden(groupIndex, sizeIndex)" [class.diet-last]="lastDiet" *ngIf="dietNameAndDietId[1] !== noDietId">
                <app-checkbox (toggled)="toggleDiet(groupIndex, sizeIndex, dietIndex, $event)" [checked]="isDietChecked(groupIndex, sizeIndex, dietIndex)" [indeterminate]="isDietChecked(groupIndex, sizeIndex, dietIndex) === null"></app-checkbox>
              </div>
              <ng-container *ngFor="let mealCode of AllMealCodes; let mealIndex = index; let mealFirst = first; let mealLast = last">
                <label class="meal-count" [class.diet-last]="lastDiet" [class.hidden]="sizeHidden(groupIndex, sizeIndex)" [class.meal-first]="mealFirst" [class.meal-last]="mealLast">
                  <ng-container *ngIf="groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).has(mealCode)">
                    {{ mealCode | mealName:'code' }}:
                    <app-count *ngIf="dietNameAndDietId[1] === noDietId" [hasBorder]="true" [formControlName]="mealIndex" [min]="groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode)[0]" [max]="groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode)[1]"></app-count>
                    <ng-container *ngIf="dietNameAndDietId[1] !== noDietId">
                      <app-checkbox [formControlName]="mealIndex" [indeterminate]="isMultiDay"></app-checkbox>
                    </ng-container>
                  </ng-container>
                </label>
              </ng-container>
            </ng-container>
          </ng-container>
          <app-corner-buttons *ngIf="canSaveGroup(groupIndex)">
            <button class="btn-save-exceptions" (click)="saveGroupIndex = groupIndex">Dodaj</button>
          </app-corner-buttons>
        </ng-container>
      </div>
    </div>
  </form>
  <div class="confirm-dialog" *ngIf="saveGroupIndex !== null">
    <div class="confirm-dialog-inner">
      <p>Pozor: Vnešeno število odsotnih se bodo dodalo oz. odvzelo trenutnemu stanju<br>vsakega dneva v časovnem obdobju, ki ste ga izbrali.</p>
      <div class="text-center">
        <button class="cancel" (click)="saveGroupIndex = null">Prekliči</button>
        <button class="confirm" (click)="saveConfirmed()">Potrdi</button>
      </div>
    </div>
  </div>
</div>
