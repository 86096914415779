<div class="container" [class.displayed]="displayed" (click)="backgroundClick($event)">
  <div class="dialog">
    <div class="search">
      <h1>Izberi živilo</h1>
      <form [formGroup]="searchForm">
        <div class="search-box">
          <input type="text" formControlName="query" #queryInput>
        </div>
        <div class="search-filter">
          <button class="search-filter--all" type="button" (click)="resetSearchForm()" [class.search-filter--all-inactive]="anyFilter">Vse</button>
          <div class="form-group">
            <app-select-array [options]="allGastro" formControlName="gastro" emptyTitle="Vse skup. jedilnikov" [selectedDark]="true"></app-select-array>
          </div>
          <button class="clear-filter" [class.visible]="searchForm.value.gastro" (click)="clearFilter('gastro')"></button>
          <div class="form-group">
            <app-select-array [options]="allQualitySchemes" formControlName="qualityScheme" emptyTitle="Vse shema kakovosti" [selectedDark]="true"></app-select-array>
          </div>
          <button class="clear-filter" [class.visible]="searchForm.value.qualityScheme" (click)="clearFilter('qualityScheme')"></button>
          <div class="form-group">
            <app-select-array [options]="allDiets" formControlName="diet" emptyTitle="Vse diete" [selectedDark]="true"></app-select-array>
          </div>
          <button class="clear-filter" [class.visible]="searchForm.value.diet" (click)="clearFilter('diet')"></button>
          <div class="form-group">
            <app-select-array [options]="allSeasons" formControlName="season" emptyTitle="Vse sezone" [selectedDark]="true"></app-select-array>
          </div>
          <button class="clear-filter" [class.visible]="searchForm.value.season" (click)="clearFilter('season')"></button>
        </div>
      </form>
      <div class="results">
        <h1>
          Živila
          <a [routerLink]="['/products/new']">Vnesi novo živilo</a>
        </h1>
        <app-food-list [foods]="products" [selected]="currentFood?.id" (clicked)="setCurrent($event)"></app-food-list>
        <h1>
          Recepti
          <a [routerLink]="['/recipe/new']">Vnesi nov recept</a>
        </h1>
        <app-food-list [foods]="recipes" [selected]="currentFood?.id" (clicked)="setCurrent($event)"></app-food-list>
        <h1>Obrok</h1>
        <ul class="fav-meals">
          <li *ngFor="let meal of shownFavorites" (click)="clickMeal(meal)">
            <div class="fav-meals--title">
              <span>{{ meal.title }}</span>
              <span>{{ meal.mealCode | mealName: 'code' }}</span>
            </div>
            <div class="fav-meals--foods">{{ meal?.foods?.join(', ') }}</div>
            <button type="button" (click)="removeFav($event, meal)" class="remove-fav"><i class="icon-close"></i></button>
          </li>
        </ul>
      </div>
    </div>
    <div class="weight">
      <h1>
        Določi količino
      </h1>
      <form class="weight--form" *ngIf="currentFood" [formGroup]="weightForm" (submit)="submit($event)">
        <div class="weight--card">
          <div class="weight--food">{{ currentFood.name }}</div>
          <ng-container *ngIf="currentFood?.guide[0] && currentFood?.guide[1]">
            <div class="weight--section">Priporočena količina</div>
            <div class="weight--guide">
              {{ currentFood.guide[0] | number:'1.0-1' }}
              -
              {{ currentFood.guide[1] | number:'1.0-1' }}
              g
            </div>
          </ng-container>
          <ng-container *ngIf="currentFood?.packages?.length > 0">
            <div class="weight--section">Pakiranja</div>
            <div class="weight--packages">
              <span *ngFor="let pkg of currentFood.packages" (click)="setWeight(pkg)">
                {{ pkg | number : '1.0-1' }} g
              </span>
            </div>
          </ng-container>
          <div class="weight--value">
            <div>Izbrana količina</div>
            <app-count #weightInput unit="g" [delta]="10" formControlName="weight" btnStyle="full" [hasBorder]="true"></app-count>
          </div>
        </div>
        <button class="weight--accept" type="submit">
          {{ editId ? 'Zamenjaj' : 'Dodaj' }}
        </button>
      </form>
    </div>
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>
</div>
