<app-title icon="user" text="Profil"></app-title>
<form [formGroup]="form" (ngSubmit)="submit()">
  <label class="form-group">
    <label class="form-label">Ime</label>
    <input type="text" formControlName="name"/>
  </label>
  <label class="form-group">
    <label class="form-label">E-mail</label>
    <input type="email" formControlName="email"/>
  </label>
  <div class="type--label form-label">
    Enote
  </div>
  <app-radio-array [options]="energyUnitOptions" [horizontal]="true" formControlName="energyUnit"></app-radio-array>
  <app-submit-button [dirty]="form.dirty"></app-submit-button>
</form>
