import { Pipe, PipeTransform } from '@angular/core';
import { Category } from './api.abstract.service';

const names = new Map([
  [5,  'zelenjava'],
  [6,  'sadje'],
  [1,  'škrob'],
  [3,  'meso'],
  [11, 'ribe'],
  [10, 'jajca'],
  [2,  'mleko'],
  [9,  'voda'],
]);

@Pipe({
  name: 'categoryName'
})
export class CategoryNamePipe implements PipeTransform {

  transform(value: Category): string {
    return names.get(value);
  }

}
