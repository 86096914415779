<app-title icon="groups_thin" text="Naročniki">
  <app-toggle-help>
    V zavihku Naročniki natančno uredimo strukturo uporabnikov za katere vaša kuhinja načrtuje in pripravlja obroke. Kot skupine uporabnikov so mišljeni: predšolski otroci, učenci, dijaki in odrasli (vzgojitelji, učitelji in ostali zaposleni, zunanji odjemalci). V tem delu lahko za tekoče šolsko leto opredelite tudi posameznike z medicinsko indiciranimi dietami.
    Osnovne nastavitve o uporabnikih uredimo enkrat v tekočem šolskem letu in sicer takrat, ko smo natančneje seznanjeni s situacijo povpraševanja po obrokih v prihajajočem šolskem letu (navadno konec junija oziroma avgusta).
    V kolikor obroke pripravljate za zunanje odjemalce oziroma druge zavode, vam orodje omogoča dodajanje novih zavodov. Npr. v kolikor ste osnovna šola z vrtcem, boste najprej dodali podatke za obroke za šolske otroke (Naziv zavoda: Osnovna šola Brest – šola), nato pa kot nov zavod dodate še podatke za predšolske otroke (Naziv zavoda: Osnovna šola Brest – vrtec).
    Svetujemo vam, da ste pri vpisovanju podatkov čim bolj natančni, saj vnesene vrednosti vplivajo na končno količino pripravljenih obrokov in odpadkov v vaši kuhinji.
    Normalno je, da se število uporabnikov obrokov nepredvideno spreminja od dneva do dneva, kar pa lahko sprotno dnevno urejamo v zavihku Odsotnost.
    Način urejanja zavihka Naročniki si lahko ogledate tudi v video predstavitvi.
  </app-toggle-help>
</app-title>

<app-main-with-aside>
  <ng-container aside>
    <button class="btn-add-horizontal-large" [class.active]="isNew" [routerLink]="['/groups/add']">
      <span>
        Dodaj nov<br>
        zavod
      </span>
    </button>
    <app-group-list [groups]="groups" [selected]="editGroup" (delete)="delete($event)"></app-group-list>
  </ng-container>

  <ng-container main>
    <app-error></app-error>
    <div class="group-forms">
      <app-group-form *ngIf="isNew" [yearId]="yearId" (changed)="insert($event)" (cancel)="cancelCreate()" (dirty)="setDirty($event)">
        <app-submit-button #submit></app-submit-button>
      </app-group-form>
      <app-group-form *ngIf="editGroup" [yearId]="yearId" [value]="editGroup" (changed)="update(editGroup, $event)" (remove)="delete(editGroup)" (dirty)="setDirty($event)">
        <app-submit-button #submit></app-submit-button>
      </app-group-form>
    </div>
    <div id="aside-marker"></div>
  </ng-container>

</app-main-with-aside>
