import { Pipe, PipeTransform } from '@angular/core';
import { MealCode } from './api.abstract.service';

const names = new Map([
  [MealCode.Breakfast, ['Zajtrk', 'Zajtrk', 'Z']],
  [MealCode.MorningSnack, ['Dopoldanska malica', 'Dop. malica', 'DM']],
  [MealCode.Lunch, ['Kosilo', 'Kosilo', 'K']],
  [MealCode.AfternoonSnack, ['Popoldanska malica', 'Pop. malica', 'PM']],
  [MealCode.Supper, ['Večerja', 'Večerja', 'V']],
]);

@Pipe({
  name: 'mealName'
})
export class MealNamePipe implements PipeTransform {

  public readonly longOptions: { value: MealCode, title: string }[] = [];

  public readonly shortOptions: { value: MealCode, title: string }[] = [];

  constructor() {
    names.forEach((titles, value) => {
      this.longOptions.push({ title: titles[0], value });
      this.shortOptions.push({ title: titles[1], value });
    });
  }

  transform(value: MealCode, short: boolean | 'code' = false): any {
    if (names.has(value)) {
      const index = short === 'code' ? 2 : (short ? 1 : 0);
      return names.get(value)[index];
    }
    return '';
  }

}
