import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SubscriptionSink, ErrorComponent } from '@app/shared';
import { zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  form: FormGroup;

  @ViewChild(ErrorComponent, {static: true})
  error: ErrorComponent;

  protected subscription = new SubscriptionSink();

  constructor(
    protected fb: FormBuilder,
    protected api: ApiService,
    protected route: ActivatedRoute,
    protected router: Router
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submit() {
    const { email, password } = this.form.value;

    this.subscription.sink = zip(
        this.api.authLogin({ email, password }),
        this.route.queryParams
      )
      .pipe(
        this.error.operator(),
        switchMap(([_user, query]) =>
          this.router.navigateByUrl(query.ret || '/')
        )
      )
      .subscribe();
  }

}
