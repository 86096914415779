import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '@app/shared';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupGuard implements CanActivate {

  private area = 'groups';

  constructor(protected api: ApiService, protected router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = route.routeConfig.path;

    if (path === '') {
      return this.api.getYearId()
        .pipe(
          switchMap(yearId => this.api.calendarGetGroups({ yearId })),
          map(rv => {
            if (rv.length === 0) {
              return this.router.createUrlTree([this.area, 'add']);
            }

            return this.router.createUrlTree([this.area, 'edit', rv[0].id]);
          })
        );
    } else if (path === '') {
      this.api.setHelpVisible(this.area, true);
    } else if (path === 'add') {
      this.api.setHelpVisible(this.area, false);
    } else if (path === 'edit/:id') {
      this.api.setHelpVisible(this.area, false);
    }

    return true;
  }

}
