<app-title icon="calendar" text="Šolska leta" [sidebar]="false"></app-title>
<div class="home-container" *ngIf="years$ | async as years">
  <table class="generic">
    <thead>
      <tr>
        <th>Leto</th>
        <th>Trenutno</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let year of years">
        <td>{{ year.year }}/{{ year.year + 1 }}</td>
        <td>{{ year.current ? 'da' : 'ne' }}</td>
      </tr>
      <tr *ngIf="canStartYear(years)">
        <td colspan="2">
          <button (click)="startNewYear()">Novo šolsko leto</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

