<div class="container" [class.disabled]="disabled" [class.has-border]="hasBorder" [ngClass]="btnStyle">
  <button [disabled]="disabled" type="button" class="down" (click)="incr($event, -1)">
    <i [class.icon-minus-inline]="btnStyle === 'clear'" [class.icon-full-minus]="btnStyle === 'full'"></i>
  </button>
  <input [disabled]="disabled" type="number" (change)="change($event)" (keyup)="keyUp($event)" #input [ngClass]="inputClasses">
  <span *ngIf="unit">{{ unit }}</span>
  <button [disabled]="disabled" type="button" class="up" (click)="incr($event, 1)">
    <i [class.icon-plus-inline]="btnStyle === 'clear'" [class.icon-plus-full]="btnStyle === 'full'"></i>
  </button>
</div>
