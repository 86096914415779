<div class="grid">
  <ng-container *ngFor="let segment of segments">
    <div class="name" [ngClass]="segment.cls">{{ segment.name }}</div>
    <app-segmented-bar [value]="segment.value" [color]="segment.cls" [min]="segment.guideline.min" [max]="segment.guideline.max"></app-segmented-bar>
    <div class="value" [ngClass]="segment.cls">{{ segment.value | number:'1.0-1' }} g</div>
    <div class="guideline" [class.outside]="segment.isOutside">
      {{ segment.guideline.min | number:'1.0-1' }}
      -
      {{ segment.guideline.max | number:'1.0-1' }} g
    </div>
  </ng-container>
</div>
