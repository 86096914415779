<div class="container">
  <div class="dialog">
    <h1>Uvozi živila iz baze</h1>
    <div class="panes">
      <div class="search-pane">
        <div class="search-source">
          <button type="button" class="source-search" [class.selected]="source === 'search'" (click)="source = 'search'">Išči po bazi</button>
          <span class="help-tooltip" tooltip="Vpišite ime živila"></span>
          <button type="button" class="source-barcode" [class.selected]="source === 'barcode'" (click)="source = 'barcode'">Uvozi spisek črtnih kod</button>
          <span class="help-tooltip" tooltip="Kopirajte seznam črtnih kod in jih prilepite v spodnji okvir."></span>
        </div>
        <app-foreign-search *ngIf="source === 'search'" (selected)="addFood($event)"></app-foreign-search>
        <div class="direct-codes" *ngIf="source === 'barcode'">
          <textarea (keyup)="onBarcodeChange($event)"></textarea>

          <label class="form-group">
            <span class="form-label">
              Datoteka
              <span class="help-tooltip" tooltip="Izberite Excel tabelo v kateri ste uredili seznam črtnih kod in kliknite možnost Uvozi."></span>
            </span>
            <input type="file" (change)="onFileSelected($event)" accept=".xlsx">
          </label>
        </div>
      </div>
      <div class="list-pane">
        <div class="list-pane-title">Spisek za uvoz</div>
        <app-food-list [foods]="foods" [canDelete]="true" (delete)="removeFood($event)"></app-food-list>
        <div class="failed" *ngIf="failed">
          Uvoz spodletel
        </div>
        <button type="button" class="import-button" (click)="startImport()" [disabled]="importing || !anyFood">
          <ng-container *ngIf="!importing">Uvozi</ng-container>
          <ng-container *ngIf="importing">Uvoz v teku</ng-container>
        </button>
      </div>
    </div>
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>
</div>
