import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  Allergen,
  AllGastroRecipe,
  AnalyzeRecipeRequest,
  ApiService,
  BasicFood,
  CheckboxArrayOption,
  CreateRecipeRequest,
  DietDetails,
  DietNamePipe,
  FoodDietStatus,
  fromBitset,
  GROUP_TYPE_TO_SIZES,
  GroupType,
  MealNamePipe,
  MealSize,
  Recipe,
  SeasonNamePipe,
  SIZE_TO_GROUP_TYPE,
  SubscriptionSink, toBase64,
  toBitset,
  UpdateRecipeRequest,
  validateFormArrayMinLength
} from '@app/shared';
import { AllergenNamePipe } from '@app/shared/allergen-name.pipe';
import { checkboxTitleComparator } from '@app/shared/checkbox-array/checkbox-array.component';
import { CookingMethodNamePipe } from '@app/shared/cooking-method-name.pipe';
import { GastroNamePipe } from '@app/shared/gastro-name.pipe';
import { merge, NEVER, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { range } from 'lodash-es';

@Component({
  selector: 'app-recipe-form',
  templateUrl: './recipe-form.component.html',
  styleUrls: ['./recipe-form.component.scss']
})
export class RecipeFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {

  allergenOptions: CheckboxArrayOption[];

  dietOptions: CheckboxArrayOption[];

  gastroOptions: CheckboxArrayOption[];

  cookingOptions: CheckboxArrayOption[];

  @ViewChild('file', {static: true})
  fileRef: ElementRef<HTMLInputElement>;

  @ViewChild('preparationElement', {static: true})
  preparationEl: ElementRef<HTMLTextAreaElement>;

  @Input()
  yearId: number;

  @Input()
  recipe: Omit<Recipe, 'id' | 'yearId'> & {id?: number};

  @Input()
  sizes: MealSize[];

  @Input()
  customDiets: DietDetails[];

  dietExpanded: boolean[];

  @Output()
  save = new EventEmitter<Omit<CreateRecipeRequest, 'yearId'>>();

  @Output()
  dirty = new EventEmitter<boolean>();

  form: FormGroup;

  ingredients: FormArray;

  foods: {[k: number]: BasicFood} = {};

  seasonOptions: any[];

  mealOptions: any[];

  photoUrl: SafeUrl | null;

  photoBytes: string;

  portionsEstimations: {
    groupType: GroupType,
    sizes: ({
      size: MealSize;
      weight: number;
      count: number;
    } | null)[];
  }[];

  allergens: Allergen[];

  readonly search: (query: string) => Observable<any[]>;

  protected subscription = new SubscriptionSink();

  constructor(
      protected fb: FormBuilder,
      protected api: ApiService,
      dietNamePipe: DietNamePipe,
      seasonNamePipe: SeasonNamePipe,
      mealNamePipe: MealNamePipe,
      allergenPipe: AllergenNamePipe,
      gastroName: GastroNamePipe,
      cookingName: CookingMethodNamePipe,
      protected domSanitizer: DomSanitizer
  ) {
    this.allergenOptions = allergenPipe.options();
    this.seasonOptions = seasonNamePipe.options;
    this.mealOptions = mealNamePipe.longOptions;
    this.cookingOptions = cookingName.options;

    this.gastroOptions = AllGastroRecipe.map(code => ({
      title: gastroName.transform(code),
      value: code
    })).sort(checkboxTitleComparator);

    this.search = (query: string) => {
      if (!query) {
        return of([]);
      }
      return this.api
        .foodFindFood({ query, yearId: this.yearId, excludeFoodId: this.recipe?.id, includeIngredients: true })
        .pipe(map(({ foods }) =>
          foods.filter(food =>
            !this.form.value.ingredients.find(ingredient => ingredient.id === food.id)
          ))
        );
    };

    this.dietOptions = dietNamePipe.options;
  }

  protected createForm() {
    if (this.form) {
      return;
    }

    this.ingredients = this.fb.array([], validateFormArrayMinLength(1));

    this.form = this.fb.group({
      name: [{value: null}, Validators.required],
      ingredients: this.ingredients,
      cookingMethod: [{value: null}, Validators.required],
      seasonQuality: [],
      gastro: [{value: null}, Validators.required],
      edible: [null, [Validators.min(0), Validators.max(100)]],
      preparation: [],
      seq: [],
      designation: [],
      source: [],
      author: [],
      customDiets: this.fb.array(range(this.customDiets.length).map(() => [])),
    });

    this.subscription.sink = merge(
        this.form.get('cookingMethod').valueChanges,
        this.ingredients.valueChanges,
        this.form.get('gastro').valueChanges,
      )
      .pipe(
        debounceTime(250),
        switchMap(() => {
          const value = this.form.value;
          if (value.ingredients.length === 0) {
            this.allergens = [];
            this.portionsEstimations = [];
            return NEVER;
          }
          const request: AnalyzeRecipeRequest = {
            yearId: this.yearId,
            cookingMethod: value.cookingMethod,
            ingredients: value.ingredients.map(({id, weight}) => ({
              food: id,
              weight,
              onlyEdible: false,
              wholeWheat: false,
              soaked: false
            })),
            gastro: value.gastro
          };
          return this.api.foodAnalyzeRecipe(request);
        }),
      )
      .subscribe(rv => {
        const groupTypes: GroupType[] = [];
        rv.portions.forEach(p => {
          const groupType = SIZE_TO_GROUP_TYPE.get(p.size);
          if (!groupTypes.includes(groupType)) {
            groupTypes.push(groupType);
          }
        });
        groupTypes.sort();

        this.portionsEstimations = groupTypes.map(groupType => {
          const portion = {
            groupType: groupType,
              sizes: GROUP_TYPE_TO_SIZES.get(groupType).map(size => {
                const weight = rv.portions.find(e => e.size === size)?.weight ?? 0;
                if (!weight) {
                  return null;
                }

                const count = rv.finalWeight / weight;
                return {
                  size,
                  weight,
                  count,
                };
              })
          }
          portion.sizes = portion.sizes.concat(Array.from(Array(3 - portion.sizes.length)).map(() => null));
          return portion;
        });

        this.allergens = rv.allergens;
      });

    this.subscription.sink = this.form.statusChanges.subscribe(() => {
      this.dirty.emit(this.form.dirty);
    });
  }

  protected createIngredientGroup() {
    return this.fb.group({
      id: [null, Validators.required],
      weight: [null, [Validators.required, Validators.min(0.1)]]
    });
  }

  ngOnInit() {
    this.createForm();

    this.fileRef.nativeElement.addEventListener('change', event => {
      const fileList: FileList = (event.target as any).files;
      if (fileList.length === 0) {
        return;
      }

      return toBase64(fileList[0]).subscribe(content => {
          this.photoUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fileList[0]));
          this.photoBytes = content;
      }, () => {}, () => this.fileRef.nativeElement.value = '');
    });
  }

  ngOnChanges() {
    this.createForm();

    const nIngredients = this.recipe && this.recipe.ingredients ? this.recipe.ingredients.length : 0;

    while (this.ingredients.length > nIngredients) {
      this.ingredients.removeAt(0);
    }

    while (this.ingredients.length < nIngredients) {
      this.ingredients.push(this.createIngredientGroup());
    }

    this.photoUrl = null;

    this.photoBytes = null;

    this.dietExpanded = this.customDiets.map(() => false);

    if (this.recipe) {
      const ingredients = this.recipe.ingredients || [];
      this.form.setValue({
        name: this.recipe.name,
        ingredients: ingredients.map(ingredient => ({
          id: ingredient.food,
          weight: ingredient.weight
        })),
        cookingMethod: this.recipe.cookingMethod,
        seasonQuality: fromBitset(this.recipe.seasonQuality),
        gastro: this.recipe.gastro,
        edible: this.recipe.edible * 100,
        preparation: this.recipe.preparation || '',
        customDiets: this.customDiets.map((diet) => this.recipe.diets?.find(dietStatus => dietStatus.dietId === diet.id)?.approved ?? null),
        seq: this.recipe.seq ?? null,
        designation: this.recipe.designation ?? '',
        source: this.recipe.source ?? '',
        author: this.recipe.author ?? '',
      });
      this.subscription.sink = this.api
        .foodFindFood({ yearId: this.yearId, ids: ingredients.map(ingredient => ingredient.food), includeIngredients: true})
        .subscribe(rsp => this.foods = rsp.foods.reduce((c, food) => {
          c[food.id] = food;
          return c;
        }, {}));

      if (this.recipe.photos?.length > 0) {
        const photo = this.recipe.photos[0];
        this.photoUrl = `${environment.photoUrl}/${this.recipe.id}-${photo.id}.${photo.type}`;
      }
      this.form.markAsPristine();
      this.dirty.emit(false);
    } else {
      this.form.reset();
    }

    this.form.markAsUntouched();
    this.form.markAsPristine();
    this.dirty.emit(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addIngredient(food: BasicFood) {
    if (this.form.value.ingredients.find(({id}) => id === food.id)) {
      return;
    }

    const group = this.createIngredientGroup();
    group.setValue({
      id: food.id,
      weight: 100
    });
    this.ingredients.push(group);
    this.foods[food.id] = food;
    this.dirty.emit(true);
  }

  removeIngredient(id) {
    const index = this.ingredients.value.findIndex(ingredient => ingredient.id === id);
    if (index !== -1) {
      this.ingredients.removeAt(index);
    }
    this.dirty.emit(true);
  }

  submit($event) {
    $event.preventDefault();

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const value = this.form.value;

    const dietStatuses: FoodDietStatus[] = [];
    this.customDiets.forEach((diet, index) => {
      if (value.customDiets[index] === null) {
        return;
      }
      dietStatuses.push({
        dietId: diet.id,
        approved: value.customDiets[index],
      });
    });

    const request: Omit<UpdateRecipeRequest, 'yearId' | 'id'> = {
      name: value.name,
      ingredients: value.ingredients.map(({id, weight}) => ({
        food: id,
        weight,
        onlyEdible: false,
        wholeWheat: false,
        soaked: false
      })),
      cookingMethod: value.cookingMethod,
      gastro: value.gastro,
      diets: dietStatuses,
      seasonQuality: toBitset(value.seasonQuality),
      specifiedWeight: 0,
      edible: value.edible / 100,
      preparation: value.preparation,
      seq: value.seq,
      designation: value.designation,
      source: value.source,
      author: value.author,
    };

    if (this.photoBytes) {
      request.newPhoto = this.photoBytes;
    } else if (this.recipe && !this.photoUrl) {
      request.removedPhoto = true;
    }

    this.save.emit(request);
  }

  removePhoto() {
    this.photoUrl = null;
  }

  expandDiet(idx: number) {
    this.dietExpanded[idx] = !this.dietExpanded[idx];
  }

  ngAfterViewChecked() {
    const natEl = this.preparationEl.nativeElement;
    natEl.style.height = 'auto';
    natEl.style.height = natEl.scrollHeight + 'px';
  }

  setEdible(value: number) {
    if (this.form.value.edible === value) {
      return;
    }
    this.form.get('edible').setValue(value);
    this.form.get('edible').markAsDirty()
    this.dirty.emit(true);
  }
}
