import {
  Component,
  forwardRef,
  ViewChild,
  ElementRef,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNumber } from 'lodash-es';

@Component({
  selector: 'app-count',
  templateUrl: './count.component.html',
  styleUrls: ['./count.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountComponent),
      multi: true
    }
  ]
})
export class CountComponent implements ControlValueAccessor, OnChanges {

  @Input()
  value: number | null = null;

  @Input()
  delta = 1;

  @Output()
  changed = new EventEmitter<number>();

  @Input()
  hasBorder = false;

  @Input()
  unit: string;

  @Input()
  align: 'left' | 'center' | 'right' = 'center';

  @Input()
  btnStyle: 'clear' | 'full' = 'clear';

  @Input()
  min: number = 0;

  @Input()
  max: number;

  @Input()
  disabled = false;

  @ViewChild('input', {static: true})
  protected input: ElementRef;

  protected changeListeners = [];

  inputClasses: string[] = [];

  constructor() { }

  protected notifyChangeListeners() {
    this.changeListeners.forEach(listener => listener(this.value));
    this.changed.emit(this.value);
  }

  ngOnChanges() {
    this.input.nativeElement.value = this.value;
    this.inputClasses = [];
    if (this.align) {
      this.inputClasses.push(`align-${this.align}`);
    }
  }

  writeValue(obj: any): void {
    if (typeof (obj) === 'number') {
      this.value = obj;
    } else {
      this.value = null;
    }
    this.input.nativeElement.value = this.value;
  }

  incr($event, delta: number) {
    if ($event.shiftKey) {
      delta *= 10;
    } else {
      delta *= this.delta;
    }

    if (delta < 0 && this.value === null) {
      delta = 0;
    }

    if (delta < 0 && this.value + delta < this.min) {
      return;
    }
    this.value += delta;
    if (isNumber(this.max) && this.value > this.max) {
      this.value = this.max;
    }
    this.input.nativeElement.value = this.value;
    this.notifyChangeListeners();
  }

  change(event) {
    this.value = +event.target.value;
    if (isNumber(this.max) && this.value > this.max) {
      event.target.value = this.max;
      this.value = this.max;
    }
    this.notifyChangeListeners();
  }

  registerOnChange(fn: any): void {
    this.changeListeners = [fn];
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  keyUp(event) {
    this.value = +event.target.value;
    this.notifyChangeListeners();
  }

  focus() {
    if (!this.input || this.input.nativeElement.offsetParent === null) {
      return;
    }
    this.input.nativeElement.focus();
  }

}
