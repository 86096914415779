import { Pipe, PipeTransform } from '@angular/core';
import { CookingMethodToName } from './api.abstract.service';
import { checkboxTitleComparator } from './checkbox-array/checkbox-array.component';

@Pipe({
  name: 'cookingMethodName'
})
export class CookingMethodNamePipe implements PipeTransform {

  public readonly options: { value: number, title: string }[];

  constructor() {
    this.options = [];
    CookingMethodToName.forEach((title, value) =>
      this.options.push(({ title, value }))
    );
    this.options = this.options.sort(checkboxTitleComparator);
  }

  transform(value: any, args?: any): any {
    return CookingMethodToName.get(value);
  }

}
