import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '@app/shared';

@Injectable({
  providedIn: 'root'
})
export class CalendarGuard implements CanActivate {

  private area = 'calendar';

  constructor(protected api: ApiService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = route.routeConfig.path;
    const defaultVisible = this.api.getHelpDefault(this.area);

    if (path === '') {
      this.api.setHelpVisible(this.area, defaultVisible);
    } else {
      this.api.setHelpVisible(this.area, false);
    }

    return true;
  }
}
