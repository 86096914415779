import { Component } from '@angular/core';
import { ApiService } from '@app/shared';

@Component({
  selector: 'app-toggle-help',
  templateUrl: './toggle-help.component.html',
  styleUrls: ['./toggle-help.component.scss']
})
export class ToggleHelpComponent {

  visible: boolean = false;

  constructor(protected api: ApiService) {}

  toggle() {
    this.visible = !this.visible;
  }
}
