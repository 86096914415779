import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AllDiets,
  AllSeasons,
  ApiService,
  CheckboxArrayOption,
  Component as Cp,
  DatePages,
  DietDetails,
  DietNamePipe,
  FavoriteMeal,
  GetMenuDayResponse,
  MealCode,
  MealEnergyGuideline,
  MealNamePipe,
  MealSize,
  MenuDietStatus,
  MenuMealFood,
  MenuMealNutrition,
  MenuSizeGuidelines,
  MenuStatus,
  SeasonNamePipe,
  serialized,
  SetMenuFoodRequest,
  SubscriptionSink,
  sumNutrients,
  Units,
  Values,
} from '@app/shared';
import { MealSizePipe } from '@app/shared/meal-size.pipe';
import * as dayjs from 'dayjs';
import { MenuFoodSearchComponent } from '../menu-food-search/menu-food-search.component';
import { MenuCopyComponent } from '@app/menu/menu-copy/menu-copy.component';
import { MenuFavoriteComponent } from '@app/menu/menu-favorite/menu-favorite.component';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, groupBy, map, mergeMap } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-menu-day',
  templateUrl: './menu-day.component.html',
  styleUrls: ['./menu-day.component.scss']
})
export class MenuDayComponent implements OnInit, OnDestroy {

  @ViewChild(MenuFoodSearchComponent, { static: true })
  search: MenuFoodSearchComponent;

  @ViewChild(MenuCopyComponent, {static: true})
  copyComponent: MenuCopyComponent;

  @ViewChild(MenuFavoriteComponent, {static: true})
  favComponent: MenuFavoriteComponent;

  yearId: number;

  date: string;

  datePages: DatePages;

  dateJs: dayjs.Dayjs;

  size: MealSize;

  dietId: number;

  mealSizes: MealSize[];

  mealCodes: MealCode[];

  diet: DietDetails;

  showDiet = false;

  diets: DietDetails[];

  mealFoods: Map<MealCode, MenuMealFood[]>;

  mealNutritions: Map<MealCode, MenuMealNutrition>;

  guidelines: MenuSizeGuidelines;

  mealEnergyGuidelines: Map<MealCode, MealEnergyGuideline>;

  dayValues: Values;

  dayUnits: Units;

  mealDietStatuses: MenuDietStatus[];

  status: MenuStatus;

  seasonOptions: CheckboxArrayOption[];

  dietOptions: CheckboxArrayOption[];

  favorites: Map<MealCode, FavoriteMeal>;

  isEmpty: boolean;

  workdays: string[];

  otherSizes: MealSize[];

  week: string;

  canUnconfirm: boolean;
  canConfirmAllSizes: boolean;
  canUnconfirmAllSizes: boolean;
  canConfirmAllDiets: boolean;
  canUnconfirmAllDiets: boolean;

  protected subscription = new SubscriptionSink();

  protected addFoodSubject = new Subject<[boolean, SetMenuFoodRequest]>();

  protected updateSubject = new Subject<Observable<GetMenuDayResponse>>();

  protected currentRouteSegments: string[];

  protected redirectAfterSearchClose = false;

  constructor(protected route: ActivatedRoute, protected router: Router, protected api: ApiService,
              protected mealName: MealNamePipe, protected mealSizeName: MealSizePipe, protected fb: FormBuilder,
              protected decimalPipe: DecimalPipe,
              seasonNamePipe: SeasonNamePipe, dietNamePipe: DietNamePipe) {

    this.seasonOptions = AllSeasons.map(value => ({
      title: seasonNamePipe.transform(value),
      value
    }));

    this.dietOptions = AllDiets.map(value => ({
      title: dietNamePipe.transform(value),
      value
    }));
  }

  ngOnInit() {
    this.route.url.subscribe(segments => this.currentRouteSegments = segments.map(s => s.path));
    this.subscription.sink = this.route
      .data
      .subscribe(({ menuDay }) => this.setValues(menuDay));
    this.subscription.sink = this.route
      .queryParams
      .subscribe(qp => {
        this.week = qp['week'];
        if (qp['meal'] && qp['food_id']) {
          this.search.initialized$.subscribe(() => {
            this.search.search(~~qp['meal'], this.size, this.dietId, undefined, ~~qp['food_id']);
            this.redirectAfterSearchClose = true;
          });
        }
      });

    this.subscription.sink = merge(
        this.addFoodSubject.pipe(
          groupBy(([all, req]) => `${all}_${req.foodId}`),
          mergeMap(x => x.pipe(debounceTime(500))),
          map(([all, req]) => all ? this.api.menuSetFoodForAllSizes(req) : this.api.menuSetFood(req))
        ),
        this.updateSubject,
      ).pipe(
        serialized()
      ).subscribe(rv => this.setValues(rv, false));

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showSearch(mealCode: MealCode, menuFood?: MenuMealFood) {
    this.search.search(mealCode, this.size, this.dietId, menuFood);
  }

  sizeSelected([size, dietId]: [MealSize, number | null]) {
    const segments = ['/menu/day', this.date, size];
    if (dietId) {
      segments.push(dietId);
    }
    this.router.navigate(segments, {queryParams: {
      week: this.week
    }});
  }

  addFood([mealCode, foodId, amount, allGroups, editId]: [MealCode, number, any, boolean, number?]) {
    const common = {
      yearId: this.yearId,
      date: this.date,
      mealCode,
      mealSize: this.size,
      dietId: this.dietId,
    };

    const values = {
      ...common,
      foodId,
      amount: parseFloat(amount),
    };

    if (editId && foodId !== editId) {
      this.updateSubject.next(this.api.menuRemoveFood({
        ...common,
        foodId: editId,
      }));
    }

    this.addFoodSubject.next([allGroups, values]);
  }

  setMeal([mealCode, favoriteMeal]: [MealCode, FavoriteMeal]) {
    this.updateSubject.next(this.api.menuCopyFavoriteMeal({
      yearId: this.yearId,
      date: this.date,
      mealSize: this.size,
      mealCode,
      dietId: this.dietId,
      favoriteMeal
    }));
  }

  removeFood(mealCode: MealCode, foodId: number) {
    this.updateSubject.next(this.api.menuRemoveFood({
      yearId: this.yearId,
      date: this.date,
      mealCode,
      mealSize: this.size,
      dietId: this.dietId,
      foodId,
    }));
  }

  removeMeal(mealCode: MealCode) {
    const n = this.mealFoods.get(mealCode).length;
    if (!n) {
      return;
    }

    if (!window.confirm(`${n} jedi bo izbranih iz ${this.mealName.transform(mealCode)}.\nSte prepričani?`)) {
      return;
    }

    this.updateSubject.next(this.api.menuRemoveMealFoods({
      yearId: this.yearId,
      date: this.date,
      mealCode,
      mealSize: this.size,
      dietId: this.dietId,
    }));
  }

  removeDay() {
    const date = this.dateJs.format('dddd, D.M.YY');
    const msg = `Vse jedi na dan ${date} za ${this.mealSizeName.transform(this.size)} bodo izbrisane.\nSte prepričani?`;
    if (!window.confirm(msg)) {
      return;
    }

    this.updateSubject.next(this.api.menuRemoveDayFoods({
      yearId: this.yearId,
      date: this.date,
      mealSize: this.size,
      dietId: this.dietId,
    }));
  }

  confirm() {
    this.updateSubject.next(this.api.menuConfirmDiet({
      yearId: this.yearId,
      date: this.date,
      mealSize: this.size,
      dietId: this.dietId,
    }));
  }

  favToggle(mealCode: MealCode) {
    if (this.favorites.has(mealCode)) {
      this.subscription.sink = this.api.menuUnfavoriteMeal({
        yearId: this.yearId,
        date: this.date,
        mealSize: this.size,
        dietId: this.dietId,
        mealCode,
      }).subscribe(() => this.favorites.delete(mealCode));
      return;
    }

    this.favComponent.show(mealCode, this.mealFoods.get(mealCode));
  }

  navigateDate(date: string) {
    this.router.navigate(['/menu/day', date, this.size]);
  }

  copyFrom(size: MealSize, mealCode: MealCode) {
    this.updateSubject.next(this.api.menuCopyMenuMeal({
      yearId: this.yearId,
      date: this.date,
      dietId: this.dietId,
      sourceSize: size,
      targetSize: this.size,
      mealCode
    }));
  }

  protected setValues(menuDay: GetMenuDayResponse, updateIngredients: boolean = true) {
    const {
      yearId,
      datePages,
      sizes,
      diets,
      availableMeals,
      size,
      dietId,
      foods,
      mealGuidelines,
      mealNutritions,
      dayDietStatuses,
      favorites,
      workdays,
    } = menuDay;

    this.yearId = yearId;
    this.date = datePages.date;
    this.datePages = datePages;
    this.dateJs = dayjs(this.date);
    this.size = size;
    this.dietId = dietId;
    this.diet = diets.find(d => d.id === dietId);
    this.showDiet = false;

    this.mealSizes = sizes;
    this.diets = diets;
    this.mealCodes = availableMeals;
    this.canUnconfirm = menuDay.canUnconfirm;
    this.canConfirmAllSizes = menuDay.canConfirmAllSizes;
    this.canUnconfirmAllSizes = menuDay.canUnconfirmAllSizes;
    this.canConfirmAllDiets = menuDay.canConfirmAllDiets;
    this.canUnconfirmAllDiets = menuDay.canUnconfirmAllDiets;

    if (updateIngredients || !this.mealFoods) {
      this.mealFoods = new Map();
    }
    this.mealCodes.forEach(meal => {
      const newFoods = foods.filter((f: MenuMealFood) => f.mealCode === meal);
      if (updateIngredients || true /* this causes flickering sometimes, without it tooltip doesn't update */) {
        this.mealFoods.set(meal, newFoods);
      } else {
        const currentFoods = this.mealFoods.get(meal) ?? [];
        newFoods
          .filter(n =>
            !currentFoods.find(c => c.foodId === n.foodId)
          )
          .forEach(f => currentFoods.push(f));

        this.mealFoods.set(meal, currentFoods.filter(c => newFoods.find(n => n.foodId === c.foodId)));
      }
    });

    this.mealNutritions = new Map();
    mealNutritions.forEach(mn =>
      this.mealNutritions.set(mn.mealCode, mn)
    );

    [this.dayValues, this.dayUnits] = sumNutrients(mealNutritions);

    this.guidelines = mealGuidelines;
    this.mealEnergyGuidelines = new Map(mealGuidelines.mealEnergy.map(g => [g.mealCode, g]));

    this.mealDietStatuses = dayDietStatuses;

    this.status = dayDietStatuses.find(d =>
      d.date === this.date && d.size === size && d.dietId === dietId
    ).status;

    this.favorites = new Map((favorites || []).map(f => [f.mealCode, f]));

    this.isEmpty = this.dayValues.length === 0;
    this.workdays = workdays;

    this.otherSizes = sizes.filter(s => s !== size);
  }

  getMealEnergy(mealCode: MealCode) {
    return (this.mealNutritions?.get(mealCode)?.values?.find(([cp]) => cp === Cp.Energy) ?? [])[1] ?? 0;
  }

  getMealValues(mealCode: MealCode): [string, string, number][] {
    const values = this.mealNutritions?.get(mealCode)?.values ?? [];

    return [
      ['OH', 'chot', values.find(([cp]) => cp === Cp.TotalCarbohydrates)?.[1] ?? 0],
      ['B', 'prot', values.find(([cp]) => cp === Cp.Protein)?.[1] ?? 0],
      ['M', 'fat', values.find(([cp]) => cp === Cp.Fat)?.[1] ?? 0],
    ]
  }

  showCopy() {
    this.copyComponent.show();
  }

  copyDay({date, mealSize, sourceYearId}: {date: string; mealSize: MealSize, sourceYearId: number}) {
    this.updateSubject.next(this.api.menuCopyMenuMealFrom({
      sourceYearId,
      targetYearId: this.yearId,
      sourceDate: date,
      sourceSize: mealSize,
      targetDate: this.date,
      targetSize: this.size,
      targetDietId: this.dietId,
    }));
  }

  onFavorite(meal: FavoriteMeal) {
    this.favorites.set(meal.mealCode, meal);
  }

  back() {
    if (this.week) {
      this.router.navigate(['/menu/week', this.week]);
    } else {
      this.router.navigate(['/menu/month', this.dateJs.month() + 1, this.size]);
    }
  }

  getIngredientTooltip(food: MenuMealFood) {
    const energy = this.decimalPipe.transform(food.energy/4184, '1.0-2');
    const fat = this.decimalPipe.transform(food.fat, '1.0-2');
    const carbo = this.decimalPipe.transform(food.totalCarbohydrates, '1.0-2');
    const protein = this.decimalPipe.transform(food.protein, '1.0-2');

    return `EN: ${energy} kcal<br>M: ${fat} g<br>OH: ${carbo} g<br>B: ${protein} g`;
  }

  confirmAll([allSizes, confirm]: [boolean, boolean]) {
    this.api.menuConfirmAllDiets({
      yearId: this.yearId,
      date: this.date,
      mealSize: this.size,
      dietId: this.dietId,
      targetMealSize: allSizes ? undefined : this.size,
      confirm,
    }).subscribe(rv => this.setValues(rv));
  }

  reportDailyMenu() {
    return this.api.reportDailyMenu(this.yearId, this.date, this.size, this.dietId);
  }

  shoppingList() {
    return '/preparation/' + this.dateJs.week();
  }

  searchClosed() {
    if (this.redirectAfterSearchClose) {
      this.redirectAfterSearchClose = false;
      this.router.navigate(['/menu'].concat(this.currentRouteSegments));
    }
  }
}
