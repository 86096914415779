import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService, Organisation, SubscriptionSink, User, toBase64, CheckboxArrayOptionSimple } from '@app/shared';
import { Observable } from 'rxjs';
import { ErrorComponent } from '@app/shared/error/error.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-organisation-list',
  templateUrl: './organisation-list.component.html',
  styleUrls: ['./organisation-list.component.scss']
})
export class OrganisationListComponent implements OnInit, OnDestroy {

  userForm: FormGroup;

  organisationForm: FormGroup;

  organisations: Organisation[];

  roles: CheckboxArrayOptionSimple[] = ['organizer', 'chef', 'secretary', 'admin'].map(v => ({
    value: v,
    title: v,
  }));

  addOrganisation = false;

  editOrganisation?: Organisation;

  logoUrl: SafeUrl | null;

  editUsers?: Organisation;

  @ViewChild(ErrorComponent)
  formError: ErrorComponent;

  @ViewChild('file', {static: true})
  fileRef: ElementRef<HTMLInputElement>;

  orgChecked: Set<number>;

  isActive = false;

  protected subscription = new SubscriptionSink();

  constructor(protected route: ActivatedRoute, protected api: ApiService, protected fb: FormBuilder, protected domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      id: [],
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      role: [null, Validators.required],
    });
    this.organisationForm = this.fb.group({
      name: [null, Validators.required],
      vatNumber: [null, Validators.pattern(/^\d{8}$/)],
      logo: [],
      removeLogo: [],
    });
    this.subscription.sink = this.route.data.subscribe(({ organisationList }) =>
      this.organisations = organisationList
    );
    this.fileRef.nativeElement.addEventListener('change', event => {
      const fileList: FileList = (event.target as any).files;
      if (fileList.length === 0) {
        return;
      }

      return toBase64(fileList[0]).subscribe(content => {
        this.logoUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fileList[0]));
        this.organisationForm.get('logo').setValue(content);
        this.organisationForm.get('removeLogo').setValue(null);
      }, () => {}, () => this.fileRef.nativeElement.value = '');
    });
    this.orgChecked = new Set();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  edit(organisation: Organisation, user: User) {
    this.cancel();
    this.userForm.setValue({
      organisation: organisation.name,
      name: user.name,
      email: user.email,
      role: user.role,
    });
  }

  doEditOrganisation(organisation: Organisation) {
    this.cancel();
    this.editOrganisation = organisation;
    this.organisationForm.setValue({
      name: organisation.name,
      vatNumber: organisation.vatNumber ?? null,
      logo: null,
      removeLogo: null,
    });
    this.logoUrl = organisation.logoUrl;
  }

  doEditUsers(org: Organisation) {
    this.editUsers = org;
    // this.editUsers.users = this.editUsers.users.concat( Array.from(Array(100)).map(() => ({
    //   name: 'Marko Kovac',
    //   email: 'some@examile.com',
    //   role: 'chef'
    // }) as any));
    if (org.users.length > 0) {
      this.doEditUser(org.users[0]);
    }
  }

  doEditUser(usr: User) {
    this.userForm.setValue({
      id: usr.id,
      name: usr.name,
      email: usr.email,
      role: usr.role,
    });
    this.userForm.markAsPristine();
    this.isActive = usr.activated;
  }

  saveUser() {
    this.formError.reset();

    if (this.userForm.invalid) {
      return;
    }

    const values = this.userForm.value;

    if (values.id) {
      this.subscription.sink = this.api.organisationUpdateUser(values).pipe(this.formError.operator()).subscribe(organisations => {
        this.organisations = organisations;
        const org = organisations.find(o => o.id === this.editUsers.id);
        this.doEditUsers(org);
        this.doEditUser(org.users.find(u => u.id === values.id));
      });
      return;
    }

    this.subscription.sink = this.api.organisationCreateUser({
      organisationId: this.editUsers.id,
      ...values,
      id: undefined,
    }).pipe(this.formError.operator()).subscribe(organisations => {
      this.organisations = organisations;
      const org = organisations.find(o => o.id === this.editUsers.id);
      this.doEditUsers(org);
      this.doEditUser(org.users.find(u => u.email === values.email));
    });
  }

  cancel() {
    if (this.formError) {
      this.formError.reset();
    }
    this.addOrganisation = false;
    this.editOrganisation = undefined;
    this.editUsers = undefined;
  }

  doAddOrganisation() {
    this.cancel();
    this.addOrganisation = true;
    this.logoUrl = null;
  }

  saveOrganisation() {
    if (this.organisationForm.invalid) {
      return;
    }

    let request: Observable<Organisation[]>;
    if (this.editOrganisation) {
      request = this.api.organisationUpdateOrganisation({
        id: this.editOrganisation.id,
        name: this.organisationForm.value.name,
        vatNumber: this.organisationForm.value.vatNumber || undefined,
        logo: this.organisationForm.value.logo,
        removeLogo: !!this.organisationForm.value.removeLogo,
      });
    } else {
      request = this.api.organisationCreateOrganisation({
        name: this.organisationForm.value.name,
        vatNumber: this.organisationForm.value.vatNumber || undefined,
        logo: this.organisationForm.value.logo,
      });
    }

    this.subscription.sink = request.subscribe(organisations => {
      this.organisations = organisations;
      this.cancel();
    });
  }

  removeLogo() {
    this.organisationForm.get('removeLogo').setValue(true);
    this.logoUrl = null;
  }

  addUser() {
    this.userForm.reset();
  }

  toggleOrg(id: number) {
    if (this.orgChecked.has(id)) {
      this.orgChecked.delete(id);
    } else {
      this.orgChecked.add(id);
    }
  }

  toggleAllOrgs() {
    if (this.orgChecked.size == 0) {
      this.organisations.forEach(org => this.orgChecked.add(org.id));
    } else {
      this.orgChecked.clear();
    }
  }

  setOrgYear() {
    const startYearStr = prompt("Zacetno leto");
    if (!startYearStr) {
      return;
    }
    const startYear = ~~startYearStr;
    if (!confirm(`Nastavljeno leto bo ${startYear}/${startYear + 1}.\nSte prepricani?`)) {
      return;
    }

    this.api.organisationSetCurrentYear({
      year: startYear,
      ids: Array.from(this.orgChecked),
    }).subscribe(organisations => this.organisations = organisations);
  }

  impersonateUser() {
    this.api.organisationImpersonateUser(this.userForm.value.id).subscribe(() => window.location.reload());
  }

  sendActivationLink() {
    this.api.organisationSendActivationEmail(this.userForm.value.id).pipe(this.formError.operator({successMessage: `Navodila za prijavo poslana na ${this.userForm.value.email}`})).subscribe();
  }
}
