import { AfterViewChecked, Component, ElementRef, forwardRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CheckboxArrayOption,
  checkboxTitleComparator, ECatalog,
  eCatalogCategoryNames,
  eCatalogSubcategoryNames,
  eCatalogSubcategoryToCategory,
} from '@app/shared';
import { RadioArrayComponent } from '@app/shared/radio-array/radio-array.component';

@Component({
  selector: 'app-ecatalog',
  templateUrl: './ecatalog.component.html',
  styleUrls: ['./ecatalog.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EcatalogComponent),
      multi: true
    }
  ]
})
export class EcatalogComponent implements OnInit, OnChanges, ControlValueAccessor, AfterViewChecked {

  protected changeListeners = [];

  level0: CheckboxArrayOption[] = [];

  level1: CheckboxArrayOption[] = [];

  value0: string | null = null;

  title0: string | null = null;

  value1: string | null = null;

  title1: string | null = null;

  @ViewChild('levelZero', {static: true})
  control0: RadioArrayComponent;

  @ViewChild('levelOne', {static: true})
  control1: RadioArrayComponent;

  @ViewChild('scrollable', {static: false})
  scrollableEl: ElementRef<HTMLDivElement>;

  protected notifyChangeListeners(value: ECatalog) {
    this.changeListeners.forEach(listener => listener(value));
  }

  protected setCode(ind: number, code: string) {
    if (ind === 0) {
      this.value0 = code;
      this.title0 = eCatalogCategoryNames.get(this.value0) ?? null;

      this.level1 = [];
      this.value1 = null;
      this.title1 = null;

      eCatalogSubcategoryToCategory.forEach((category, subcategory) => {
        if (category === this.value0) {
          this.level1.push({
            value: subcategory,
            title: eCatalogSubcategoryNames.get(subcategory),
          });
        }
      });
      this.level1.sort(checkboxTitleComparator);
    } else if (ind === 1) {
      this.value1 = code;
    }
  }

  private writeControlValues() {
    this.control0.writeValue(this.value0);
    this.control1.writeValue(this.value1);
  }

  onSelect(ind: number, code: string) {
    this.setCode(ind, code);
    this.writeControlValues();
    this.notifyChangeListeners(this.value1);
  }

  ngOnInit() {
    this.level0 = [];
    eCatalogCategoryNames.forEach((title, value) =>
      this.level0.push({value, title})
    );
    this.level0.sort(checkboxTitleComparator);

    this.level1 = [];
    this.control0.registerOnChange(this.onSelect.bind(this, 0));
    this.control1.registerOnChange(this.onSelect.bind(this, 1));
  }

  ngOnChanges() {

  }

  writeValue(value: ECatalog) {
    if (eCatalogSubcategoryNames.has(value)) {
      const category = eCatalogSubcategoryToCategory.get(value);
      this.setCode(0, category);
      this.setCode(1, value);
    } else {
      this.setCode(0, null);
    }
    this.writeControlValues();
  }

  registerOnChange(fn: any): void {
    this.changeListeners = [fn];
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  onDefault(value: ECatalog) {
    if (value) {
      this.notifyChangeListeners(value);
    }
  }

  ngAfterViewChecked() {
    if (!this.scrollableEl) {
      return;
    }

    const checked: HTMLInputElement = this.scrollableEl.nativeElement.querySelector('app-radio-array:not(.hidden) input:checked');
    if (!checked) {
      return;
    }
    this.scrollableEl.nativeElement.scrollTop = checked.parentElement.offsetTop - ~~(this.scrollableEl.nativeElement.offsetHeight / 2 - 10);
  }
}
