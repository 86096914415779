import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import {
  ApiService,
  BasicFood,
  ErrorComponent, ForeignBasicFood,
  Product,
  SubscriptionSink,
  UpdateProductRequest
} from '@app/shared';
import { omit } from 'lodash-es';
import { Observable } from 'rxjs';
import { SubmitButtonComponent } from '@app/shared/submit-button/submit-button.component';

@Component({
  selector: 'app-product-editor',
  templateUrl: './product-editor.component.html',
  styleUrls: ['./product-editor.component.scss']
})
export class ProductEditorComponent implements OnInit, OnDestroy {

  @ViewChild(ErrorComponent, { static: true })
  error: ErrorComponent;

  values: Omit<UpdateProductRequest, 'id' | 'yearId'> = null;

  foreign: {
    foreignId: string;
    foreignSource: string;
  };

  type: 'new' | 'copy' | null;

  productId: number;

  yearId: number;

  incompleteCount: number;

  incompleteId: number;

  isDirty = false;

  @ViewChild('submit', {static: false})
  submitButton: SubmitButtonComponent;

  importOpened = false;

  swapVisible = false;

  readonly swapSearch: (query: string) => Observable<any[]>;

  protected subscription = new SubscriptionSink();

  protected returnUrl: UrlTree;

  constructor(protected api: ApiService, protected route: ActivatedRoute, protected router: Router) {
    this.swapSearch = query => this.api.foodFindAnyForeign(query, 25);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['return'] && this.router.parseUrl(params['return'])) {
        this.returnUrl = this.router.parseUrl(params['return']);
      }
    });

    this.subscription.sink = this.route
      .data
      .subscribe(({ yearId, type, food }: { yearId: number, type: 'new' | null, food: Product }) => {
        this.type = type || null;
        this.yearId = yearId;
        if (food) {
          this.productId = food.id;
          this.values = {
            ...food,
            newPackaging: [],
            existingPackaging: food.packaging,
          };
        } else {
          this.productId = undefined;
          this.values = null;
        }
        this.foreign = undefined;
      });
    this.subscription.sink = this.api.incompleteFood$.subscribe(rv => {
      if (rv) {
        this.incompleteCount = rv.count;
        this.incompleteId = rv.id;
      } else {
        this.incompleteCount = 0;
        this.incompleteId = 0;
      }
    });

    this.error.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  save(request: Omit<UpdateProductRequest, 'id' | 'yearId'>) {
    let observable: Observable<Product>;

    if (this.type === 'new') {
      observable = this.api
        .foodCreateProduct({
          ...omit(request, 'existingPackaging'),
          ...this.foreign,
          yearId: this.yearId,
        });
    } else {
      observable = this.api
        .foodUpdateProduct({
          ...request,
          ...this.foreign,
          id: this.productId,
          yearId: this.yearId,
        });
    }

    this.error.reset();
    this.subscription.sink = observable.pipe(this.error.operator({button: this.submitButton})).subscribe({
      next: rv => {
        this.setDirty(false);

        if (this.returnUrl) {
          this.returnUrl.queryParams.food_id = rv.id;
          this.router.navigateByUrl(this.returnUrl);
          return;
        }

        if (this.productId === rv.id) {
          this.values = {
            ...rv,
            newPackaging: [],
            existingPackaging: rv.packaging,
          };
        } else {
          this.router.navigate(['/products', rv.id])
        }
      },
    });
  }

  copy() {
    this.type = 'new';
    this.values = {
      name: `KOPIJA - ${this.values.name}`,
      ...omit(this.values, 'id', 'yearId', 'packaging', 'name', 'existingPackaging'),
      newPackaging: this.values.existingPackaging.map(p => omit(p, 'id')),
      existingPackaging: [],
    };
  }

  remove() {
    if (!confirm(`Ste prepričani da želite izbrisati živilo ${this.values.name}?`)) {
      return;
    }

    this.subscription.sink = this.api.foodDeleteFood({
      yearId: this.yearId,
      foodId: this.productId
    }).subscribe(reason => {
      if (reason) {
        alert(reason);
        return;
      }
      this.setDirty(false);
      return this.router.navigate(['products']);
    });
  }

  showProduct(food: BasicFood) {
    this.router.navigate(['products', food.id]);
  }

  /**
   * @deprecated
   */
  deleteProduct(food: BasicFood) {
    if (!confirm(`Ste prepričani da želite izbrisati živilo ${food.name}?`)) {
      return;
    }

    this.subscription.sink = this.api.foodDeleteFood({
      yearId: this.yearId,
      foodId: food.id
    }).subscribe(reason => {
      if (reason) {
        alert(reason);
        return;
      }
      return this.router.navigate(['/products']);
    });
  }

  setDirty(d: boolean) {
    this.isDirty = d;
    this.submitButton?.setStatus(d ? 'dirty' : 'initial');
  }

  checkDirty() {
    return !this.isDirty || confirm('Spremembe niso shranjene.\nKliknite prekliči, če želite nadaljevati z urejanjem.\nS klikom na potrdi bodo spremembe izgubljene.');
  }

  toggleSwap() {
    this.swapVisible = !this.swapVisible;
  }

  swapSelected($event: ForeignBasicFood) {
    this.toggleSwap();
    this.subscription.sink = this.api.foodGetForeign({
      id: $event.id,
      source: $event.source
    }).subscribe(rv => {
      this.values = {...this.values};
      Object.keys(rv.product).forEach(prop => {
        if (rv.product[prop]) {
          this.values[prop] = rv.product[prop];
        }
      });
      this.values.newPackaging = [
        {
          ...rv.packaging,
          basePrice: null,
          tax: 9.5,
          deductable: 0,
          active: true,
          amount: null,
        },
      ];
      this.values.existingPackaging = [];
      this.foreign = {
        foreignId: rv.id,
        foreignSource: rv.source,
      }
    })
  }
}
