import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService, GetCalendarYearsResponse } from '@app/shared';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-calendar-years',
  templateUrl: './calendar-years.component.html',
  styleUrls: ['./calendar-years.component.scss']
})
export class CalendarYearsComponent implements OnInit {

  years$: Observable<GetCalendarYearsResponse[]>;

  private today: dayjs.Dayjs;

  constructor(private route: ActivatedRoute, private apiService: ApiService) { }

  ngOnInit(): void {
    this.years$ = this.route.data.pipe(map(({years}) => years));
    this.today = dayjs()
  }

  startNewYear() {
    const year = this.today.year();
    const msg = `Zaceto bo novo solsko leto ${year}/${year + 1}`;
    if (!confirm(msg)) {
      return;
    }
    this.apiService.calendarCreateYear(year).subscribe(rv => console.log(rv));
  }

  canStartYear(years: GetCalendarYearsResponse[]) {
    return !years.find(({year}) => year === this.today.year());
  }
}
