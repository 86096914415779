import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AVAILABLE_MONTHS, Day, getMonthName } from '@app/shared';

@Component({
  selector: 'app-calendar-year',
  templateUrl: './calendar-year.component.html',
  styleUrls: ['./calendar-year.component.scss']
})
export class CalendarYearComponent implements OnInit, OnChanges {

  @Input()
  allDays: Day[][];

  @Input()
  month: number;

  index: number;

  monthNames: string[];

  availableMonths = AVAILABLE_MONTHS;

  ngOnInit() {
    this.monthNames = AVAILABLE_MONTHS.map(getMonthName);
  }

  ngOnChanges() {
    this.index = AVAILABLE_MONTHS.indexOf(this.month);
  }
}
