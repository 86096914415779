import { Pipe, PipeTransform } from '@angular/core';
import { Season } from './api.abstract.service';

const names = new Map([
  [Season.Spring, 'Pomlad'],
  [Season.Summer, 'Poletje'],
  [Season.Autumn, 'Jesen'],
  [Season.Winter, 'Zima'],
]);

@Pipe({
  name: 'seasonName'
})
export class SeasonNamePipe implements PipeTransform {

  public readonly options: { value: Season, title: string }[];

  constructor() {
    this.options = [];
    names.forEach((title, value) =>
      this.options.push({ title, value })
    );
  }

  transform(value: any, args?: any): any {
    return names.get(value);
  }

}
