import { Component, Input, OnChanges } from '@angular/core';
import { get } from 'lodash-es';
import { Component as Cp, Values, ComponentGuideline } from '../api.abstract.service';

@Component({
  selector: 'app-component-bars',
  templateUrl: './component-bars.component.html',
  styleUrls: ['./component-bars.component.scss']
})
export class ComponentBarsComponent implements OnChanges {

  @Input()
  values: Values;

  @Input()
  showLegend = false;

  @Input()
  guidelines: ComponentGuideline[];

  segments: {
    cls: string;
    value: number,
    guideline: ComponentGuideline,
    name: string,
    isOutside: boolean;
  }[];

  constructor() { }

  getGuideline(component: Cp) {
    if (!this.guidelines) {
      return;
    }

    return this.guidelines.find(g => g.component === component);
  }

  ngOnChanges() {
    if (!this.values) {
      this.segments = undefined;
      return;
    }
    const value = (c: Cp) =>
      get(this.values.find(([component]) => component === c), 1, 0);

    this.segments = [
      {
        cls: 'sugar',
        value: value(Cp.TotalSugars),
        guideline: this.getGuideline(Cp.TotalSugars),
        name: 'Sladkor',
      },
      {
        cls: 'salt',
        value: value(Cp.Sodium),
        guideline: this.getGuideline(Cp.Sodium),
        name: 'Sol',
      },
      {
        cls: 'fasat',
        value: value(Cp.SaturatedFattyAcids),
        guideline: this.getGuideline(Cp.SaturatedFattyAcids),
        name: 'NMK',
      }
    ].map(v => ({
      ...v,
      isOutside: v.value > v.guideline.max || v.guideline.min && v.value < v.guideline.min
    }));
  }
}
