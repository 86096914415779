import { Pipe, PipeTransform } from '@angular/core';
import { eCatalogSubcategoryNames } from '@app/shared/api.abstract.service';

@Pipe({
  name: 'ecatalogName'
})
export class EcatalogNamePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return eCatalogSubcategoryNames.get(value) ?? '';
  }

}
