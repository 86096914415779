import { Pipe, PipeTransform } from '@angular/core';
import { Allergen, AllergenToName, AllergenToParent } from './api.abstract.service';
import { CheckboxArrayOptionSimple, checkboxTitleComparator } from './checkbox-array/checkbox-array.component';

@Pipe({
  name: 'allergenName'
})
export class AllergenNamePipe implements PipeTransform {

  transform(value: Allergen): string {
    return AllergenToName.get(value);
  }

  options(): CheckboxArrayOptionSimple[] {
    const rv: CheckboxArrayOptionSimple[] = [];

    AllergenToParent.forEach((parent, allergen) => {
      if (parent) {
        rv.push({
          title: AllergenToName.get(allergen),
          value: allergen
        })
      }
    });

    rv.sort(checkboxTitleComparator);

    return rv;
  }

}
