<form [formGroup]="form" (ngSubmit)="save()">
  <label class="form-title-field">
    Naziv zavoda:
    <span class="help-tooltip" tooltip="Vpišite ime vašega zavoda (npr. Osnovna šola Brest)."></span>
    <input type="text" formControlName="name">
  </label>
  <div class="type--label form-label">
    Tip zavoda
    <span class="help-tooltip" tooltip="Izberite tip zavoda za katerega načrtujete obroke (npr. če v okviru vaše osnovne šole deluje vrtec, boste v tem primeru izbrali tip zavoda &quot;vrtec&quot;, v kolikor boste načrtovali obroke za šolske otroke pa boste izbrali tip zavoda &quot;osnovna šola&quot;. V kolikor organizirate prehrano v večih zavodih in želite dodati nov tip zavoda, kliknite na zavihek &quot;dodaj nov zavod&quot;, ter uredite naročnike neodvisno od obstoječega zavoda. Jedilnike boste lahko načrtovali za oba zavoda hkrati."></span>
  </div>
  <app-radio-array [options]="groupTypesOptions" [horizontal]="true" formControlName="type" [disabled]="!!value"></app-radio-array>
  <div class="counts--label form-label">
    Število naročnikov brez diet
    <span class="help-tooltip" tooltip="Ko imate v vašem zavodu znano število naročnikov v tekočem šolskem letu (npr. učencev v OŠ...), jih opredelite glede na velikost obrokov (npr. v OŠ 1., 2., in 3. triada, v vrtcu 1. in 2. star. skupina) ter število naročenih dnevnih obrokov (zajtrkov, dop. malic ...)."></span>
  </div>
  <div class="counts" formArrayName="meals">
    <ng-container *ngFor="let sizeArray of asFormArray(form.get('meals')).controls; let sizeIndex=index" [formArrayName]="sizeIndex">
      <div class="counts--size">{{ sizeOptions[sizeIndex] | mealSize:'ageGroup' }}</div>
      <div class="counts--count" *ngFor="let mealArray of asFormArray(sizeArray).controls; let mealIndex=index">
        <span>{{ mealCodes[mealIndex] | mealName:true }}</span>
        <app-count [formControlName]="mealIndex" [hasBorder]="true"></app-count>
      </div>
    </ng-container>
  </div>
  <div class="counts--label form-label">
    Število naročnikov z dietami
    <span class="help-tooltip" tooltip="Na začetku šolskega leta opredelite osebe, ki imajo posebne prehranske potrebe oziroma medicinsko indicirane diete. Svetujemo vam, da jih opredelite z imenom/priimkom in enoto zavoda (npr. Martin Novak, 1.A.). Iz seznama izberite tip diete (npr. alergija na jajca) ter vpišite osnovne značilnosti diete, ki jo prejmete s strani zdravniškega izvida. Nadalje opredelite na katere obroke je oseba naročena in velikost porcije."></span>
  </div>
  <ng-container formArrayName="diets">
    <div class="custom-diet" *ngFor="let dietArray of asFormArray(form.get('diets')).controls; let dietIndex=index" [formArrayName]="dietIndex">
      <div class="custom-diet--name form-group-hor">
        <label class="custom-diet--label">Ime</label>
        <input type="text" formControlName="name">
      </div>
      <div class="custom-diet--type form-group-hor">
        <label class="custom-diet--label">Tip diete</label>
        <app-checkbox-array [options]="dietTypeOptions" formControlName="dietCodes" [compact]="true" [add]="''"></app-checkbox-array>
      </div>
      <div class="custom-diet--description form-group-hor">
        <label class="custom-diet--label">Opis diete</label>
        <div appFormClass="description">
          <textarea #dietDescription formControlName="description" rows="2"></textarea>
        </div>
      </div>
      <div class="custom-diet--group" appFormClass="mealSize">
        <span class="custom-diet--label">Skupina</span>
        <label *ngFor="let size of sizeOptions">
          <input type="radio" [value]="size" formControlName="mealSize">
          <span>{{ size | mealSize :'ageGroup' }}</span>
        </label>
      </div>
      <div class="custom-diet--meals" appFormClass="meals">
        <span class="custom-diet--label">Obroki</span>
        <app-checkbox-array [options]="mealOptions" all="Vse" formControlName="meals" altStyle="true"></app-checkbox-array>
      </div>
      <button type="button" class="custom-diet--remove" (click)="removeDiet(dietIndex)">
        <i class="icon-close-small"></i>
        Izbriši vnos z dieto
      </button>
    </div>
  </ng-container>
  <button type="button" class="btn-add-horizontal" (click)="addDiet()">Dodaj nov vnos z dieto</button>
  <app-corner-buttons>
    <button *ngIf="value" class="btn-cancel" type="button" (click)="remove.emit()">Izbriši</button>
    <ng-content></ng-content>
  </app-corner-buttons>
</form>
