import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WelcomeResponse } from '@app/shared';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  tab: 'new' | 'existing';

  welcome: WelcomeResponse;

  videoUrl: SafeUrl | null = null;

  constructor(protected route: ActivatedRoute, protected sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.tab = (sessionStorage.getItem('welcome_tab') as any) || null;
    this.route.data.subscribe(({welcome}) =>
      this.welcome = welcome
    )
  }

  setTab(tab: 'new' | 'existing') {
    this.tab = tab;
    sessionStorage.setItem('welcome_tab', tab);
  }

  setVideo(id: string) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${id}?rel=0&autoplay=1`);
  }
}
