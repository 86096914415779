<div class="home-container">
  <h1 class="home-title">Informacije</h1>

  <div class="home-content">
    Dobrodošli v aplikaciji za načrtovanje obrokov v vzgojno-izobraževalnih zavodih. Orodje omogoča načrtovanje prehrane skladno z nacionalnimi Smernicami za zdravo prehranjevanje v vzgojno-izobraževalnih zavodih. Vključuje vrsto funkcionalnosti, ki vam bodo olajšale delo pri organizaciji prehrane v vašem zavodu. Za več informacij si oglejte predstavitveni video.<br>
    <br>
    Svetujemo vam, da se na Nacionalnem inštitutu za javno zdravje pozanimate glede izvedbe kratkega usposabljanja, ki vam bo olajšalo delo pri uporabi spletnega orodja. Ne pozabite spremljati tudi novic na www.solskilonec.si, kjer bomo objavljali podrobnosti v zvezi z usposabljanji. Za morebitne dodatne informacije se obrnite na elektronski naslov:<br>
    <a href="mailto:solskilonec@nijz.si">solskilonec@nijz.si</a>.<br>
    <br>
    Želimo vam prijetno uporabo.
  </div>
</div>
