import { Pipe, PipeTransform } from '@angular/core';
import { MealSize } from './api.abstract.service';

const sizeToTitle = new Map([
  [MealSize.KindergartenSmall, ['Vrtec, majhna', 'Vrtec', '1. star. skup.', 'M']],
  [MealSize.KindergartenLarge, ['Vrtec, velika', 'Vrtec', '2. star. skup.', 'V']],
  [MealSize.ElementarySmall, ['Osn. šola, majhna', 'Osn. šola', '1. triada', 'M']],
  [MealSize.ElementaryMedium, ['Osn. šola, srednja', 'Osn. šola', '2. triada', 'S']],
  [MealSize.ElementaryLarge, ['Osn. šola, velika', 'Osn. šola', '3. triada', 'V']],
  [MealSize.MiddleSmall, ['Sred. šola, majhna', 'Sred. šola', 'Dekleta', 'M']],
  [MealSize.MiddleLarge, ['Sred. šola, velika', 'Sred. šola', 'Fantje', 'V']],
  [MealSize.AdultSmall, ['Odrasli, majhna', 'Odrasli', 'Mala', 'M']],
  [MealSize.AdultLarge, ['Odrasli, velika', 'Odrasli', 'Velika', 'V']],
]);

@Pipe({
  name: 'mealSize'
})
export class MealSizePipe implements PipeTransform {

  transform(mealSize: MealSize, type: 'full' | 'name' | 'ageGroup' | 'abbr' | 'size' = 'full'): string {
    switch (type) {
      case 'full':
        return sizeToTitle.get(mealSize)[0];
      case 'name':
        return sizeToTitle.get(mealSize)[1];
      case 'ageGroup':
        return sizeToTitle.get(mealSize)[2];
      case 'abbr':
        return sizeToTitle.get(mealSize)[3];
      case 'size':
        return {
          M: 'majhna',
          S: 'srednja',
          V: 'velika',
        }[sizeToTitle.get(mealSize)[3]];
    }
  }

}
