<div class="container" *ngIf="!compact" [style.columns]="columns" [class.alt]="altStyle" [class.has-all]="all" [class.all-selected]="allSelected">
  <label *ngIf="all">
    <input type="checkbox" [checked]="allSelected" (change)="toggleAll($event)">
    <span>{{ all }}</span>
  </label>
  <span *ngIf="all && altStyle" class="all-separator"></span>
  <ng-container *ngFor="let option of options">
    <label *ngIf="!isGroup(option)">
      <app-checkbox [checked]="selected.has(option.value)" (change)="toggleOption($event, option.value)" [disabled]="!altStyle && (option.disabled || allSelected)"></app-checkbox>
      <span [class.checked]="selected.has(option.value)">{{ option.title }}</span>
    </label>
    <ng-container *ngIf="isGroup(option)">
      <label *ngFor="let child of option.children">
        <app-checkbox [checked]="selected.has(child.value)" (change)="toggleOption($event, child.value)" [disabled]="!altStyle && (child.disabled || allSelected)"></app-checkbox>
        <span [class.checked]="selected.has(child.value)">{{ child.title }}</span>
      </label>
    </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="compact">
  <ng-container *ngFor="let value of selected">
    <div (click)="removeOption(value)" class="compact-item">
      {{ valueToTitle.get(value) }}
    </div>
  </ng-container>
  <app-select-button class="compact-picker">
    <button>{{ add }}</button>
    <select (change)="addOption($event)">
      <option></option>
      <ng-container *ngFor="let option of options">
        <option [disabled]="option.disabled" *ngIf="!isGroup(option) && !selected.has(option.value)">
          {{ indent(option.indent) + option.title }}
        </option>
        <optgroup *ngIf="isGroup(option)" [label]="option.title">
          <ng-container *ngFor="let child of option.children">
            <option [disabled]="child.disabled" *ngIf="!selected.has(child.value)">
              {{ indent(child.indent) + child.title }}
            </option>
          </ng-container>
        </optgroup>
      </ng-container>
    </select>
  </app-select-button>
</ng-container>
