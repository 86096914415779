import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FlatpickrOptions } from 'ng2-flatpickr';
import Slovenian from 'flatpickr/dist/l10n/sl.js';
import { SubscriptionSink } from '../util';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  dates: string[];

  @Input()
  weekNumbers: boolean = false;

  @Output()
  selected = new EventEmitter<string>();

  form: FormGroup;

  options: FlatpickrOptions = {
    locale: Slovenian.sl,
  };

  protected subscription = new SubscriptionSink();

  constructor(protected fb: FormBuilder) { }

  ngOnChanges() {
    this.options.enable = this.dates;
    this.options.weekNumbers = this.weekNumbers;
  }

  ngOnInit() {
    this.form = this.fb.group({
      date: [null]
    });
    this.subscription.sink = this.form.valueChanges.subscribe(v => {
      if (v.date) {
        this.selected.emit(dayjs(v.date).format('YYYY-MM-DD'));
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
