<div class="pie-container">
  <app-component-pie [values]="values"></app-component-pie>
  <div class="values">
    <span class="chot">OH</span>
    <span class="chot">{{ chot | number:'1.1-1' }} g</span>
    <span class="prot">B</span>
    <span class="prot">{{ prot | number:'1.1-1' }} g</span>
    <span class="fat">M</span>
    <span class="fat">{{ fat | number:'1.1-1' }} g</span>
  </div>
</div>
<div class="energy">
  <app-value-bar [value]="energy" [min]="guidelines.minDayEnergy" [max]="guidelines.maxDayEnergy" color="energy" [showGuide]="true"></app-value-bar>
  <div class="energy--guide">
    {{ guidelines.minDayEnergy | energy }} - {{ guidelines.maxDayEnergy | energy }} {{'unit'|energy}}
  </div>
  <div class="energy--value">
    {{ energy | energy }} {{'unit'|energy}}
  </div>
</div>

<app-component-bars [values]="values" [guidelines]="guidelines.components"></app-component-bars>
<app-pyramide-compact [units]="units"></app-pyramide-compact>
