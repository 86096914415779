import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AllMealCodes,
  ApiService,
  AVAILABLE_MONTHS,
  Day,
  daysInMonth,
  DietDetails,
  getWeekdayNames,
  GROUP_TYPE_TO_SIZES,
  GroupExceptionsException,
  GroupExceptionsResponse,
  GroupMealException,
  GroupResponse,
  mapCalendarYearToDays,
  MealCode,
  MealSize,
  serialized,
  SubscriptionSink,
  UpdateExceptionRequest
} from '@app/shared';
import * as dayjs from 'dayjs';
import { debounceTime, delay, filter, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'app-group-exceptions',
  templateUrl: './group-exceptions.component.html',
  styleUrls: ['./group-exceptions.component.scss']
})
export class GroupExceptionsComponent implements OnInit, OnDestroy {

  yearId: number;

  year: number;

  workdays: string[];

  exceptions: GroupExceptionsException[];

  groups: GroupResponse[];

  holidays: Set<string>;

  noDietId: number;

  fromDate: string;

  toDate: string;

  days: Day[];

  today: string;

  groupSizes: Map<number, MealSize[]>;

  groupDiets: Map<number, Map<number, [string, number][]>>;

  groupMinMax: Map<number, Map<number, Map<number, Map<MealCode, [number, number]>>>>;

  expandedGroups: Map<number, boolean>;

  expandedSizes: Map<number, Map<number, boolean>>;

  toggledDiets: Map<number, boolean | null>;

  form: FormGroup;

  rangeForm: FormGroup;

  monthName: string;

  prevDay: string;

  nextDay: string;

  prevMonth: string;

  nextMonth: string;

  weekDays: string[];

  AllMealCodes = AllMealCodes;

  saveGroupIndex: null | true | number = null;

  isMultiDay: boolean;

  anyWorkday: boolean;

  protected subscription = new SubscriptionSink();

  protected willRedirect$ = new Subject<void>();

  constructor(protected api: ApiService, protected route: ActivatedRoute, protected fb: FormBuilder, protected router: Router) { }

  ngOnInit() {
    this.weekDays = getWeekdayNames();

    this.today = dayjs().format('YYYY-MM-DD');

    this.form = this.fb.group({
      counts: this.fb.array([]),
    });

    this.rangeForm = this.fb.group({
      from: this.fb.group({
        day: [],
        month: [],
        year: [],
      }),
      to: this.fb.group({
        day: [],
        month: [],
        year: [],
      }),
    });
    ['from', 'to'].forEach(direction =>
      ['year', 'month', 'day'].forEach(part =>
        this.subscription.sink = this.rangeForm.get([direction, part]).valueChanges.pipe(debounceTime(150)).subscribe(this.dateRangeUpdated.bind(this, direction, part))
      )
    )

    this.subscription.sink = this.route.data
      .subscribe(data => {
        this.updateExpanded(data.exceptions);
        this.updateForm(data.exceptions);
      });

    this.subscription.sink = this.form.valueChanges
      .pipe(
        delay(0),
        tap(value => this.updateToggledDiets(value)),
        filter(data => data.counts && !this.isMultiDay),
        debounceTime(500),
        map(data => {
          const r: UpdateExceptionRequest = {
            from: this.fromDate,
            to: this.toDate,
            yearId: this.yearId,
            ...this.mapToRequest(data),
          };
          return r;
        }),
        map(r =>
          this.api.calendarSetGroupExceptions(r)
        ),
        serialized()
      ).subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected updateToggledDiets(value: any) {
    this.groups.forEach((group, groupIndex) => {
      this.groupSizes.get(groupIndex).forEach((_, sizeIndex) => {
        this.groupDiets.get(groupIndex).get(sizeIndex).forEach(([, dietId], dietIndex) => {
          if (!this.toggledDiets.has(dietId)) {
            return;
          }
          let checked: boolean | null | undefined = undefined;
          AllMealCodes.forEach((mealCode, mealIndex) => {
            if (!this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).has(mealCode)) {
              return false;
            }
            if (checked === undefined) {
              checked = value?.counts?.[groupIndex]?.[sizeIndex]?.[dietIndex]?.[mealIndex];
            } else if (checked === null) {

            } else if (checked !== value?.counts?.[groupIndex]?.[sizeIndex]?.[dietIndex]?.[mealIndex]) {
              checked = null;
            }
          });
          this.toggledDiets.set(dietId, checked);
        });
      });
    });
  }

  protected updateExpanded(data: GroupExceptionsResponse) {
    this.expandedGroups = new Map();
    this.expandedSizes = new Map();

    this.getUsedGroups(data).forEach((group, groupIndex) => {
      this.expandedGroups.set(groupIndex, true);
      this.expandedSizes.set(groupIndex, new Map());

      this.getUsedSizes(group).forEach((mealSize, sizeIndex) =>
        this.expandedSizes.get(groupIndex).set(sizeIndex, true)
      );
    });
  }

  protected getUsedGroups(data: GroupExceptionsResponse) {
    return data.groups.filter(g => g.meals.find(m => m.count > 0));
  }

  protected getUsedSizes(group: GroupResponse) {
    return GROUP_TYPE_TO_SIZES.get(group.type).filter(mealSize => group.meals.find(m => m.size === mealSize))
  }

  protected updateForm(data: GroupExceptionsResponse) {
    const fromDate = dayjs(data.fromDate);
    const toDate = dayjs(data.toDate);
    this.yearId = data.yearId;
    this.year = data.year;
    this.workdays = data.workdays;
    this.exceptions = data.exceptions;
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
    this.holidays = new Set(data.holidays);
    this.noDietId = data.noDietId;
    this.isMultiDay = this.fromDate !== this.toDate;

    this.renderCalendar(fromDate, toDate);

    this.anyWorkday = this.days.some(d => d.date >= this.fromDate && d.date <= this.toDate && d.active);

    this.groups = [];
    this.groupSizes = new Map();
    this.groupDiets = new Map();
    this.groupMinMax = new Map();
    this.toggledDiets = new Map();

    const values = [];
    const countsArray = this.form.get('counts') as FormArray;

    const usedGroups = this.getUsedGroups(data);
    usedGroups.forEach((group, groupIndex) => {
      this.groups.push(group);
      this.groupSizes.set(groupIndex, []);
      this.groupDiets.set(groupIndex, new Map());
      this.groupMinMax.set(groupIndex, new Map());
      values.push([]);

      if (groupIndex >= countsArray.length) {
        countsArray.push(this.fb.array([]));
      }
      const groupArray = countsArray.at(groupIndex) as FormArray;

      const usedSizes = this.getUsedSizes(group);
      usedSizes.forEach((mealSize, sizeIndex) => {
        values[groupIndex].push([]);
        this.groupSizes.get(groupIndex).push(mealSize);
        this.groupDiets.get(groupIndex).set(sizeIndex, []);
        this.groupMinMax.get(groupIndex).set(sizeIndex, new Map());

        if (sizeIndex >= groupArray.length) {
          groupArray.push(this.fb.array([]));
        }
        const sizeArray = groupArray.at(sizeIndex) as FormArray;

        const usedDiets: DietDetails[] = [null].concat(group?.diets ?? []).filter((diet, i) =>
          group.meals.find(m => m.size === mealSize && m.count > 0 && (diet ? m.dietIndex === i - 1 : m.dietIndex === -1))
        );

        usedDiets.forEach((diet, dietIndex) => {
          if (dietIndex >= sizeArray.length) {
            sizeArray.push(this.fb.array([]));
          }
          const dietArray = sizeArray.at(dietIndex) as FormArray;

          values[groupIndex][sizeIndex].push([]);
          if (diet) {
            this.groupDiets.get(groupIndex).get(sizeIndex).push([diet.name, diet.id]);
          } else {
            this.groupDiets.get(groupIndex).get(sizeIndex).push(['Brez diet', data.noDietId]);
          }
          this.groupMinMax.get(groupIndex).get(sizeIndex).set(dietIndex, new Map());
          AllMealCodes.map((mealCode, mealIndex) => {
            const exception = data.exceptions?.find(e => e.groupId === group.id && e.size === mealSize && (diet ? e.dietId === diet.id : e.dietId === data.noDietId) && e.meal === mealCode);
            if (exception) {
              this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).set(mealCode, [exception.min, exception.max]);
            }
            let v: number | boolean | null;
            if (diet) {
              v = this.isMultiDay ? null : (exception?.count ?? 0) > 0;
            } else {
              v = exception?.count ?? 0;
            }

            values[groupIndex][sizeIndex][values[groupIndex][sizeIndex].length - 1].push(v);

            const validators = [Validators.min(exception?.min), Validators.max(exception?.max)];
            if (mealIndex >= dietArray.length) {
              dietArray.push(this.fb.control(0, validators));
            } else {
              dietArray.at(mealIndex).setValidators(validators);
            }
          });
        });

        while (sizeArray.length > usedDiets.length) {
          sizeArray.removeAt(sizeArray.length - 1);
        }
      });

      while (groupArray.length > usedSizes.length) {
        groupArray.removeAt(groupArray.length - 1);
      }
    });
    while (countsArray.length > usedGroups.length) {
      countsArray.removeAt(countsArray.length - 1);
    }

    this.form.markAsPristine();
    this.form.setValue({
      counts: values,
    }, {emitEvent: false});

    this.rangeForm.setValue({
      from: {
        year: fromDate.year(),
        month: fromDate.month() + 1,
        day: fromDate.date(),
      },
      to: {
        year: toDate.year(),
        month: toDate.month() + 1,
        day: toDate.date(),
      }
    }, { emitEvent: false });
  }

  protected mapToRequest(value: any, onlyGroupIndex: number | null = null): Pick<UpdateExceptionRequest, 'exceptions'> {
    const groupExceptions: GroupMealException[] = [];

    this.groups.forEach((group, groupIndex) => {
      if (onlyGroupIndex !== null && onlyGroupIndex !== groupIndex) {
        return;
      }

      this.groupSizes.get(groupIndex).forEach((mealSize, sizeIndex) => {
        this.groupDiets.get(groupIndex).get(sizeIndex).forEach(([, dietId], dietIndex) => {
          AllMealCodes.forEach((mealCode, mealIndex) => {
            if (!this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).has(mealCode)) {
              return;
            }

            let count: boolean | number = value.counts[groupIndex][sizeIndex][dietIndex][mealIndex];
            let v: number;
            if (typeof(count) === 'boolean') {
              if (count) {
                v = 1;
              } else if (this.isMultiDay) {
                v = -1;
              } else {
                v = 0;
              }
            } else {
              v = count;
            }

            groupExceptions.push({
              groupId: group.id,
              size: mealSize,
              meal: mealCode,
              count: v,
              dietId
            });
          });
        });
      });
    });

    return {
      exceptions: groupExceptions,
    };
  }

  protected dateRangeUpdated(edge: 'from' | 'to', part: 'year' | 'month' | 'day', value: number) {
    let {year, month, day}: {year: number, month: number, day: number} = this.rangeForm.get(edge).value;
    if (!year && !month && !day) {
      return;
    }

    const minDate = dayjs(new Date(this.year, 8, 1, 12));
    const maxDate = dayjs(new Date(this.year + 1, 7, 31, 12));

    const other = this.rangeForm.get(edge === 'from' ? 'to' : 'from').value;
    const otherDate = dayjs(new Date(other.year, other.month - 1, other.day, 12));

    if (part === 'day') {
      day = value;
      if (value <= 0) {
        day = 1;
      } else if (day > daysInMonth(year, month)) {
        day = daysInMonth(year, month);
      }
    } else if (part === 'month') {
      month = value;
      if (value <= 0) {
        month = 1;
      } else if (value > 12) {
        month = 12;
      }
    } else if (part === 'year') {
      year = value;
      if (value < this.year) {
        year = this.year;
      } else if (value > this.year + 1) {
        year = this.year + 1;
      }
    }

    if (day > daysInMonth(year, month)) {
      day = daysInMonth(year, month);
    }

    let date = dayjs(new Date(year, month - 1, day, 12));

    if (date.isBefore(minDate, 'date')) {
      date = minDate;
    } else if (date.isAfter(maxDate, 'date')) {
      date = maxDate;
    }

    let fromDate: dayjs.Dayjs;
    let toDate: dayjs.Dayjs;

    if (edge === 'from') {
      fromDate = date;
      toDate = otherDate;
    } else {
      fromDate = otherDate;
      toDate = date;
    }

    if (fromDate.isAfter(toDate, 'date')) {
      [fromDate, toDate] = [toDate, fromDate];
    }

    const newFormValue = {
      from: {
        year: fromDate.year(),
        month: fromDate.month() + 1,
        day: fromDate.date(),
      },
      to: {
        year: toDate.year(),
        month: toDate.month() + 1,
        day: toDate.date(),
      }
    };

    if (!isEqual(newFormValue, this.rangeForm.value)) {
      this.rangeForm.setValue(newFormValue);
    }

    const from = fromDate.format('YYYY-MM-DD');
    let to = toDate.format('YYYY-MM-DD');

    if (from === to) {
      this.router.navigate(['/absence', from]);
    } else {
      this.router.navigate(['/absence', from, to]);
    }

    this.fromDate = from;
    this.toDate = to;
    this.isMultiDay = from !== to;
  }

  protected renderCalendar(fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) {
    this.days = mapCalendarYearToDays({year: this.year, workdays: this.workdays}, fromDate.month() + 1);
    if (fromDate.isSame(toDate, 'month')) {
      this.monthName = fromDate.format('MMMM YYYY');
    } else if (fromDate.isSame(toDate, 'year')) {
      this.monthName = fromDate.format('MMMM') + ' - ' + toDate.format('MMMM YYYY');
    } else {
      this.monthName = fromDate.format('MMMM YYYY') + ' - ' + toDate.format('MMMM YYYY');
    }

    const workdayIndex = this.workdays.indexOf(fromDate.format('YYYY-MM-DD'));
    if (workdayIndex > 0) {
      this.prevDay = this.workdays[workdayIndex - 1];
    } else {
      this.prevDay = '';
    }
    if (workdayIndex < this.workdays.length - 1) {
      this.nextDay = this.workdays[workdayIndex + 1];
    } else {
      this.nextDay = '';
    }

    const filterDates = (month: number) =>
      this.workdays.filter(d => new RegExp(`-0?${month}-`).test(d));

    const monthIndex = AVAILABLE_MONTHS.indexOf(fromDate.month() + 1);
    if (monthIndex > 0) {
      this.prevMonth = filterDates(AVAILABLE_MONTHS[monthIndex - 1])[0] ?? ''
    } else {
      this.prevMonth = '';
    }
    if (monthIndex < AVAILABLE_MONTHS.length - 1) {
      this.nextMonth = filterDates(AVAILABLE_MONTHS[monthIndex + 1])[0] ?? ''
    } else {
      this.nextMonth = '';
    }
  }

  toggleGroup(groupIndex: number) {
    this.expandedGroups.set(groupIndex, !this.expandedGroups.get(groupIndex));
  }

  toggleSize(groupIndex: number, sizeIndex: number) {
    const sizeMap = this.expandedSizes.get(groupIndex);
    sizeMap.set(sizeIndex, !sizeMap.get(sizeIndex));
  }

  toggleDiet(groupIndex: number, sizeIndex: number, dietIndex: number, isChecked: boolean | null) {
    const mealArray = (((this.form.get('counts') as FormArray).at(groupIndex) as FormArray).at(sizeIndex) as FormArray).at(dietIndex) as FormArray;
    const newValue = mealArray.value.slice();
    const anyUnchecked = newValue.some((v, i) => this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(AllMealCodes[i]) && !v);
    AllMealCodes.forEach((mealCode, index) => {
      const defaultCount = this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode);
      if (defaultCount) {
        newValue[index] = anyUnchecked;
      }
    });
    mealArray.setValue(newValue);
    if (this.isMultiDay) {
      this.form.get(['counts', groupIndex, sizeIndex]).markAsDirty();
    }
  }

  incrementCount(groupIndex: number, sizeIndex: number, dietIndex: number, delta: number) {
    const mealArray = this.form.get(['counts', groupIndex, sizeIndex, dietIndex]) as FormArray;
    const newValue = mealArray.value.slice();

    AllMealCodes.forEach((mealCode, index) => {
      const [min, max] = this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode) ?? [0, 0];
      newValue[index] = Math.min(Math.max((newValue[index] ?? 0) + delta, min), max);
    });
    mealArray.setValue(newValue);
    if (this.isMultiDay) {
      this.form.get(['counts', groupIndex, sizeIndex]).markAsDirty();
    }
  }

  navigateTo(date: string) {
    this.willRedirect$.next();
    this.router.navigate(['/absence', date]);
  }

  incDateRange(edge: 'from' | 'to', part: 'year' | 'month' | 'day', delta: number) {
    const value = this.rangeForm.get(edge).value;
    value[part] += delta;
    let date = dayjs(new Date(value.year, value.month - 1, value.day, 12)).add(delta, 'day').format('YYYY-MM-DD');
    if (delta >= 0) {
      date = this.workdays.find(wd => wd >= date);
      if (!date) {
        date = this.workdays[this.workdays.length - 1];
      }
    } else {
      date = this.workdays.slice().reverse().find(wd => wd <= date);
      if (!date) {
        date = this.workdays[0];
      }
    }

    const targetDate = dayjs(date).toDate();
    const newValue = {
      year: targetDate.getFullYear(),
      month: targetDate.getMonth() + 1,
      day: targetDate.getDate(),
    };

    const otherEdge = edge == 'from' ? 'to' : 'from';
    const otherValue = this.rangeForm.value[otherEdge];
    const otherDate = dayjs(new Date(otherValue.year, otherValue.month - 1, otherValue.day, 12)).add(delta, 'day').format('YYYY-MM-DD');
    if (edge === 'from' && date > otherDate || edge === 'to' && date < otherDate) {
      this.rangeForm.get(edge).setValue(otherValue);
      this.rangeForm.get(otherEdge).setValue(newValue);
    } else {
      this.rangeForm.get(edge).setValue(newValue);
    }
  }

  saveAllGroups() {
    const r: UpdateExceptionRequest = {
      from: this.fromDate,
      to: this.toDate,
      yearId: this.yearId,
      ...this.mapToRequest(this.form.value),
    };

    this.api.calendarSetGroupExceptions(r).subscribe(data => {
      this.updateForm(data);
      this.form.markAsPristine();
    });
  }

  canSaveGroup(groupIndex: number) {
    return this.isMultiDay && (
      this.form.get(['counts', groupIndex]).dirty
    );
  }

  saveGroupAt(groupIndex: number) {
    this.api.calendarSetGroupExceptions({
      from: this.fromDate,
      to: this.toDate,
      yearId: this.yearId,
      ...this.mapToRequest(this.form.value, groupIndex),
    }).subscribe(data => {
      this.updateForm(data);
      this.form.markAsPristine();
    });
  }

  groupHidden(groupIndex: number) {
    return !this.expandedGroups.get(groupIndex);
  }

  sizeHidden(groupIndex: number, sizeIndex: number) {
    return this.groupHidden(groupIndex) || !this.expandedSizes.get(groupIndex).get(sizeIndex);
  }

  isDietIndeterminate(groupIndex: number, sizeIndex: number, dietIndex: number, mealCode: MealCode) {
    return  this.isMultiDay &&
            this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode)?.[0]===-1 &&
            this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode)?.[1]===1;
  }

  saveConfirmed() {
    if (this.saveGroupIndex === true) {
      this.saveAllGroups();
    } else if (this.saveGroupIndex !== null) {
      this.saveGroupAt(this.saveGroupIndex);
    }
    this.saveGroupIndex = null;
  }

  isDietChecked(groupIndex, sizeIndex, dietIndex) {
    let prevValue: boolean | null | undefined = undefined;
    const anyDiff = AllMealCodes.some((mealCode, mealIndex) => {
      if (!this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).has(mealCode)) {
        return false;
      }
      const v = this.form.value.counts[groupIndex][sizeIndex][dietIndex][mealIndex];
      if (prevValue === undefined) {
        prevValue = v;
        return false;
      }
      return prevValue !== v;
    });
    if (anyDiff) {
      return null;
    }
    return prevValue;
  }

  isSizeAbsent(groupIndex: number, sizeIndex: number) {
    return !this.form.value.counts[groupIndex][sizeIndex].some((dietValues, dietIndex) =>
      AllMealCodes.some((mealCode, mealIndex) =>
        this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).has(mealCode) &&
          (dietIndex > 0 && !dietValues[mealIndex] || dietValues[mealIndex] < this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode)[1])
      )
    );
  }

  toggleSizeAbsent(groupIndex: number, sizeIndex: number, $event: boolean) {
    const value = {...this.form.value};
    this.form.value.counts[groupIndex][sizeIndex].forEach((dietValues, dietIndex) =>
      AllMealCodes.forEach((mealCode, mealIndex) => {
        if (!this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).has(mealCode)) {
          return;
        }
        let newValue: number | boolean;
        if (dietIndex > 0) {
          newValue = $event;
        } else {
          newValue = this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode)[$event ? 1 : 0];
        }
        value.counts[groupIndex][sizeIndex][dietIndex][mealIndex] = newValue;
      })
    );
    this.form.setValue(value);
    this.form.get(['counts', groupIndex, sizeIndex]).markAsDirty();
  }

  isGroupAbsent(groupIndex: number) {
    return !this.groupSizes.get(groupIndex).some((mealSize, sizeIndex) =>
      !this.isSizeAbsent(groupIndex, sizeIndex)
    );
  }

  toggleGroupAbsent(groupIndex: number, $event: boolean) {
    const value = {...this.form.value};
    this.form.value.counts[groupIndex].forEach((sizeValues, sizeIndex) =>
      sizeValues.forEach((dietValues, dietIndex) =>
        AllMealCodes.forEach((mealCode, mealIndex) => {
          if (!this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).has(mealCode)) {
            return;
          }
          let newValue: number | boolean;
          if (dietIndex > 0) {
            newValue = $event;
          } else {
            newValue = $event ? this.groupMinMax.get(groupIndex).get(sizeIndex).get(dietIndex).get(mealCode)[1] : 0;
          }
          value.counts[groupIndex][sizeIndex][dietIndex][mealIndex] = newValue;
        })
      )
    );
    this.form.setValue(value);
    this.form.get(['counts', groupIndex]).markAsDirty();
  }

  toggleWorkday() {
    const anyInactive = !this.days.some(d => d.date >= this.fromDate && d.date <= this.toDate && !d.active)

    this.api.calendarSetWorkday({
      yearId: this.yearId,
      fromDate: this.fromDate,
      toDate: this.toDate,
      isWorkday: !anyInactive
    }).subscribe(rv => {
      this.updateForm(rv);
      this.form.markAsPristine();
    });
  }
}
