import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService, FoodKind } from '@app/shared';

@Injectable({
  providedIn: 'root'
})
export class RecipeGuard implements CanActivate {

  private recipe = 'recipe';

  constructor(protected api: ApiService, protected router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = route.routeConfig.path;

    if (path !== '') {
      return true;
    }

    return this.api.getYearId()
      .pipe(
        switchMap(yearId =>
          this.api.foodFindFood({
            yearId,
            kind: FoodKind.Recipe,
            limit: 1,
          })
        ),
        map(rv => {
          if (rv.foods.length > 0) {
            return this.router.createUrlTree([this.recipe, rv.foods[0].id]);
          }
          return this.router.createUrlTree([this.recipe, 'new']);
        })
      );
  }

}
