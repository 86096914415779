import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { ApiService } from '@app/shared';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(protected api: ApiService, protected router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.api
      .user$.pipe(
        switchMap(user => {
          if (user) {
            return of(true);
          }

          return this.router.navigate(['/login'], {
            queryParams: {
              ret: state.url
            }
          });
        })
      );
  }
}
