import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiService, ForeignBasicFood, SubscriptionSink, validateGs1 } from '@app/shared';
import { interval, Observable, Subject, switchMap} from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import-product',
  templateUrl: './import-product.component.html',
  styleUrls: ['./import-product.component.scss']
})
export class ImportProductComponent implements OnInit, OnDestroy {

  @Input()
  yearId: number;

  @Output()
  done = new EventEmitter<void>();

  cache: Map<string, ForeignBasicFood> = new Map();

  source: 'search' | 'barcode' = 'search';

  foods: ForeignBasicFood[] = [];

  fetch$: Subject<string> = new Subject();

  barcodeCodes: string[];

  subscription = new SubscriptionSink();

  importing = false;

  failed = false;

  protected file: File;

  constructor(protected api: ApiService, protected router: Router) { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.sink = this.fetch$.pipe(
      filter(code => !this.cache.has(code)),
      mergeMap(code =>
        this.api.foodFindAnyForeign(code, undefined, 'nutribase_ean'),
      ),
      filter(rv => rv.length > 0),
      map(rv => rv[0]),
    ).subscribe(rv => {
      this.cache.set(rv.barcode, rv);
      this.updateBarcodeFoods();
    });
  }

  addFood(food: ForeignBasicFood) {
    this.failed = false;
    if (!this.foods.find(f => f.source === food.source && f.id === food.id)) {
      this.foods.push(food);
    }
    if (food.barcode) {
      this.cache.set(food.barcode, food);
    }
  }

  removeFood(food: ForeignBasicFood) {
    const index = this.foods.findIndex(f => f.source === food.source && f.id === food.id);
    if (index !== -1) {
      this.foods.splice(index, 1);
    }
  }

  onBarcodeChange($event: Event) {
    this.barcodeCodes = (($event.target as HTMLTextAreaElement).value.match(/\d+/g) ?? []).filter(code => validateGs1(code));
    this.barcodeCodes.forEach(code => this.fetch$.next(code));
    this.updateBarcodeFoods();
  }

  protected updateBarcodeFoods() {
    this.foods = this.barcodeCodes.map(c => this.cache.get(c)).filter(f => !!f);
  }

  get anyFood() {
    return this.foods.length > 0 || !!this.file;
  }

  close() {
    this.done.emit();
  }

  startImport() {
    this.importing = true;
    this.failed = false;

    let obs: Observable<number>;

    if (this.file) {
      obs = this.api.foodImportBarCode(this.yearId, this.file);
    } else {
      obs = this.api.foodImportForeign({
        yearId: this.yearId,
        source: 'nutribase',
        ids: this.foods.map(f => f.id),
      })
    }

    this.subscription.sink = obs.pipe(switchMap(importId => interval(1e3).pipe(switchMap(() => this.api.foodGetImportResult(importId))))).subscribe({
      next: rv => {
        if (!rv.completed) {
          return;
        }
        this.done.next();
        if (rv.foodIds) {
          this.router.navigate(['/products', rv.foodIds[0]]);
        } else {
          this.router.navigate(['/products']);
        }
      },
      error: err => {
        this.failed = true;
      },
      complete: () => {
        this.importing = false;
      }
    })
  }

  onFileSelected($event: Event) {
    const files = ($event.target as HTMLInputElement).files;
    if (!files?.length) {
      return;
    }
    this.file = files[0];
    this.foods = [];
    this.failed = false;
  }
}
