<div class="box" *ngIf="visible$ | async">
  <div class="title">
    <span>Navodila</span>
    <label>
      <input type="checkbox" (change)="toggleDefault()" [checked]="isDefault">
      Vsakič prikaži navodila
    </label>
  </div>
  <button (click)="close()" class="btn-cancel"></button>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
