<app-title text="Koledar šolskega leta">
  <app-toggle-help></app-toggle-help>
</app-title>

<app-main-with-aside>
  <div aside>
    <!-- <div class="circle-buttons">
      <button class="btn-full-circle-import" (click)="showCreate = true">Ustvari</button>
    </div> -->
    <div class="years">
      <!-- <button class="years--prev" (click)="nextYear(-1)"></button> -->
      {{ year }}/{{ year + 1 }}
      <!-- <button class="years--next" (click)="nextYear(1)" *ngIf="year < maxYear"></button> -->
    </div>
    <app-calendar-year [allDays]="allDays" [month]="month"></app-calendar-year>
  </div>
  <ng-container main>
    <app-calendar-month [class.hidden]="help$ | async" [days]="allDays[index]" [month]="month" (remove)="remove()" [prev]="prev" [next]="next" (active)="setActive($event)"></app-calendar-month>
    <app-instructions>
      <p>
        Koledar šolskega leta je pri organizaciji prehrane v vzgojno-izobraževalnem zavodu
        pomemben dejavnik, saj pomembno vpliva na delo v kuhinji.
      </p>
      <p>
        <i>Zakaj koledar v načrtovalcu šolskih obrokov?</i><br>
        Smernice navajajo, da naj bodo šolski obroki pestri po sestavi, jedilniki pa naj se ne
        ponovijo prej kot v petih do šestih tednih. Vzgojno-izobraževalni zavod na ta način
        lahko zagotovi pestrost vključevanja živil v obrokih in sezonsko dostopnost, poleg
        tega pa se izogne tedenskemu/mesečnemu prilagajanju jedilnikov, in s tem
        neobvladljivosti pri sestavi jedilnikov. Že pripravljeni mesečni jedilnik lahko enostavno
        ponovite v naslednjem mesecu, ne da bi s tem posegli v kreativnost kuharja, lokalno
        značilnost receptur in prehransko kakovost jedilnikov. S takim pristopom vzgojno-
        izobraževalni zavod v tekočem šolskem letu potrebuje štiri sezonske jedilnike, ki so
        prilagojeni za zimske, pomladne, poletne in jesenske jedilnike.
      </p>
      <p>
        <i>Kaj omogoča koledar v načrtovalcu obrokov?</i>
        Ko boste v naslednjih fazah vzpostavili jedilnike, vam koledar omogoča, da jih
        enostavno prekopirate v naslednji mesec ali v naslednje šolsko leto. Koledar
        omogoča tudi prilagoditve na ravni dneva. V primeru, da želite posamezni dan v
        mesecu spremeniti (npr. športni dan), kliknite na želeni dan (npr. 6. januar 2020), in
        ga aktivirate s klikom. Izbrani dan na koledarju se bo obarval z modro barvo, kar
        pomeni, da gre ta dan za izjemo (npr. praznik ali počitnice), ko šolska kuhinja ne
        deluje.
      </p>
      <p>
        Svetujemo vam, da šolski koledar uredite na začetku novega šolskega leta v okolju
        Google koledar. V marsikaterem vzgojno-izobraževalnem zavodu gre za rutino, saj
        koledar tekočega šolskega leta objavijo tudi na svojih spletnih straneh.
      </p>
      <p>
        Predpripravljeni koledar lahko vsako leto sproti uvozite preko .csv datoteke v
        načrtovalec obrokov, jedilnike preteklega šolskega leta pa enostavno prekopirate v
        aktualno šolsko leto. S tem si prihranite mnogo dragocenega časa. Kako urediti
        koledar v Google okolju se pozanimate pri vašem informatiku oziroma nam pišite na
        e-naslov: <a href="mailto:solski.lonec@nijz.si">solski.lonec@nijz.si</a>.
      </p>
    </app-instructions>
  </ng-container>
</app-main-with-aside>

<app-calendar-generate *ngIf="showCreate" [year]="year" (select)="create($event)"></app-calendar-generate>
