import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '@app/shared';
import { Subscription } from 'rxjs';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'energy'
})
export class EnergyPipe implements PipeTransform {

  private energyUnit: string;

  private energyUnit$: Subscription;

  constructor(private apiService: ApiService, private decimalPipe: DecimalPipe) {
    this.energyUnit$ = this.apiService.user$.subscribe(u => this.energyUnit = u.energyUnit);
  }

  ngDestroy() {
    this.energyUnit$.unsubscribe();
  }

  transform(value: number | 'unit'): string {
    if (value === 'unit') {
      return this.energyUnit === 'kcal' ? 'kcal' : 'kJ';
    }

    if (this.energyUnit === 'kcal') {
      return this.decimalPipe.transform(value / 4184, '1.0-0');
    } else {
      return this.decimalPipe.transform(value / 1000, '1.0-0');
    }
  }

}
