import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  ApiService,
  BasicFood,
  COMPONENT_DEFAULTS,
  ComponentNamePipe,
  DEFAULT_COMPONENTS,
  Product,
  Recipe,
} from '@app/shared';
import { map, switchMap } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-prep-replace',
  templateUrl: './prep-replace.component.html',
  styleUrls: ['./prep-replace.component.scss']
})
export class PrepReplaceComponent implements OnInit {

  @Output()
  selected: EventEmitter<[number, number]> = new EventEmitter();

  visible: boolean;

  yearId: number;

  food: Product | Recipe;

  values: [string, string][];

  replacementFood: Product | Recipe;

  replacementValues: [string, string][];

  constructor(protected apiService: ApiService, protected componentName: ComponentNamePipe, protected decimal: DecimalPipe) { }

  ngOnInit(): void {
  }

  show(id: number) {
    this.apiService.getYearId().pipe(
      switchMap(yearId =>
        this.apiService.foodGetFood({
            yearId,
            foodId: id,
          }).pipe(map(food => ({
            yearId,
            food,
          }))),
      ),
    ).subscribe(({ yearId, food }) => {
      this.yearId = yearId;
      this.food = food;
      this.visible = true;
      this.values = this.mapValues(food);
    });
  }

  protected mapValues(food: Product | Recipe): [string, string][] {
    return DEFAULT_COMPONENTS.map(cp => {
      const def = COMPONENT_DEFAULTS.get(cp);
      const value = food.values.find(([c, v]) => c === cp)?.[1];
      const label = def.label ?? this.componentName.transform(cp);

      return [
        label,
        value ? this.decimal.transform(value * (def.factor ?? 1), '1.0-2') + ' ' + def.unit : '-',
      ];
    });
  }

  setReplacement(food: BasicFood) {
    this.apiService.foodGetFood({
        yearId: this.yearId,
        foodId: food.id,
      })
      .subscribe(food => {
        this.replacementFood = food;
        this.replacementValues = this.mapValues(food);
      })
  }

  submit() {
    this.visible = false;
    this.selected.emit([this.food.id, this.replacementFood.id]);
  }
}
