import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiService,
  BasicFood,
  CheckboxArrayOptionSimple,
  FoodKind,
  ForeignBasicFood,
  SubscriptionSink,
  eCatalogCategoryNames,
  checkboxTitleComparator
} from '@app/shared';
import { debounceTime, first, map, switchMap, tap } from 'rxjs/operators';
import { merge } from 'rxjs';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {

  @Input()
  selected: number;

  @Input()
  canDelete: boolean;

  @Input()
  includeIncomplete: boolean = false;

  @Output()
  pick: EventEmitter<BasicFood> = new EventEmitter<BasicFood>();

  @Output()
  delete: EventEmitter<BasicFood> = new EventEmitter<BasicFood>();

  yearId: number;

  productList: (BasicFood | ForeignBasicFood)[] = [];

  searchForm: FormGroup;

  anyFilter = false;

  allECatalogCategories: CheckboxArrayOptionSimple[];

  protected offset = 0;

  protected limit = 30;

  protected count: number;

  protected subscription = new SubscriptionSink();

  constructor(protected api: ApiService, protected fb: FormBuilder, protected router: Router) {
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      query: [],
      eCatalogCategory: [],
    });

    this.subscription.sink = merge(
      this.searchForm
        .valueChanges
        .pipe(
          tap((v) =>
            this.anyFilter = !!v.eCatalogCategory
          ),
        ),
      this.api.foodImport$,
    ).pipe(
      debounceTime(250),
      switchMap(() => this.fetch(0))
    ).subscribe()

    this.subscription.sink = this.fetch().subscribe();

    this.subscription.sink = this.api
      .food$
      .pipe(
        switchMap(() => this.fetch())
      )
      .subscribe();

    this.allECatalogCategories = [];
    eCatalogCategoryNames.forEach((title, value) =>
      this.allECatalogCategories.push({title, value})
    )
    this.allECatalogCategories.sort(checkboxTitleComparator);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showProduct(food: BasicFood) {
    this.pick.emit(food);
  }

  deleteProduct(food: BasicFood) {
    this.delete.emit(food);
  }

  resetSearchForm() {
    this.searchForm.patchValue({
      eCatalogCategory: null,
    });
  }

  clearFilter(name: string) {
    this.searchForm.get(name).setValue(null, {});
  }

  loadMore() {
    this.subscription.sink = this.fetch(this.offset + this.limit).subscribe();
  }

  protected fetch(offset?: number) {
    const query = this.searchForm.value.query;

    this.offset = offset ?? 0;
    if (this.offset === 0) {
      this.productList = [];
    }

    return this.api.getYearId().pipe(
      tap(yearId => this.yearId = yearId),
      switchMap(yearId =>
        this.api.foodFindFood({
          yearId,
          query,
          kind: FoodKind.Product,
          offset: this.offset,
          limit: this.limit,
          eCatalogCategory: this.searchForm.value.eCatalogCategory,
          includeIncomplete: this.includeIncomplete
        })
      ),
      map(response => {
        let newFoods = 0;
        response.foods.forEach(f => {
          if (!this.productList.find(p => p.id === f.id)) {
            newFoods++;
            this.productList.push(f);
          }
        });
        this.count = response.count;
        return newFoods;
      }),
      first(),
    );

  }
}
