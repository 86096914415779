import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import Slovenian from 'flatpickr/dist/l10n/sl.js';
import { FlatpickrOptions } from 'ng2-flatpickr';

const defaultDateOptions: FlatpickrOptions = {
  locale: Slovenian.sl,
  dateFormat: 'j. n. Y',
};


@Component({
  selector: 'app-calendar-generate',
  templateUrl: './calendar-generate.component.html',
  styleUrls: ['./calendar-generate.component.scss']
})
export class CalendarGenerateComponent implements OnChanges {

  @Input()
  year: number;

  @Output()
  select = new EventEmitter<[Date, Date] | null>();

  dateFrom: FlatpickrOptions;

  dateTo: FlatpickrOptions;

  form: FormGroup;

  constructor(fb: FormBuilder) {
    this.form = fb.group({
      from: [null, [Validators.required, this.validateRange.bind(this, 'from')]],
      to: [null, [Validators.required, this.validateRange.bind(this, 'to')]]
    });
  }

  validateRange(name: string, control: AbstractControl) {
    if (!this.form) {
      return {};
    }

    const value = {
      ...this.form.value,
      [name]: control.value,
    };

    if (
      value.from === null ||
      value.to === null ||
      value.from[0].getTime() <= value.to[0].getTime()
    ) {
      return {};
    }

    return {
      invalidRange: true
    };
  }

  ngOnChanges() {
    const minDate = dayjs(`${this.year}-09-01`).toDate();
    const maxDate = dayjs(`${this.year + 1}-08-31`).toDate();

    this.dateFrom = {
      ...defaultDateOptions,
      minDate,
      maxDate,
      defaultDate: minDate
    };

    this.dateTo = {
      ...defaultDateOptions,
      minDate,
      maxDate,
      defaultDate: maxDate
    };

    this.form.setValue({
      from: [minDate],
      to: [maxDate],
    });
  }

  save() {
    this.select.emit([
      this.form.value.from,
      this.form.value.to,
    ]);
  }

  cancel() {
    this.select.emit(null);
  }

}
