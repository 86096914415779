<div *ngIf="visible" class="container">
  <div class="dialog">
    <button class="btn-close" (click)="visible = false"></button>
    <div class="current">
      <h1>Zamenjaj</h1>
      <div class="food-name">
        {{ food.name }}
        <app-component-pie [values]="food.values"></app-component-pie>
      </div>
      <table class="values">
        <thead><tr>
          <th></th><th>na 100 g</th>
        </tr></thead>
        <tbody>
        <tr *ngFor="let v of values">
          <td>{{ v[0] }}</td>
          <td>{{ v[1] }}</td>
        </tr>
        </tbody>
      </table>
      <div class="packages">
        Pakiranja
        <span *ngFor="let pkg of food?.packaging">
          <ng-container *ngIf="!pkg.divisible">
            {{ pkg.amount | number : '1.0-2' }}g
          </ng-container>
          <ng-container *ngIf="pkg.divisible">/kg</ng-container>
        </span>
      </div>
    </div>

    <div class="new">
      <h1>Za</h1>
      <div class="new-panes">
        <app-product-list (pick)="setReplacement($event)" [selected]="replacementFood?.id" ></app-product-list>
        <div>
          <ng-container *ngIf="replacementFood">
            <div class="food-name">
              {{ replacementFood.name }}
              <app-component-pie [values]="replacementFood.values"></app-component-pie>
            </div>
            <table class="values">
              <thead><tr>
                <th></th><th>na 100 g</th>
              </tr></thead>
              <tbody>
              <tr *ngFor="let v of replacementValues">
                <td>{{ v[0] }}</td>
                <td>{{ v[1] }}</td>
              </tr>
              </tbody>
            </table>
            <div class="packages">
              Pakiranja
              <span *ngFor="let pkg of replacementFood?.packaging">
                <ng-container *ngIf="!pkg.divisible">
                  {{ pkg.amount | number : '1.0-2' }}g
                </ng-container>
                <ng-container *ngIf="pkg.divisible">/kg</ng-container>
              </span>
            </div>
            <div class="warning">
              Pozor: hranilne vrednosti in cena v jedilniku se bodo spremenile!
            </div>
            <button (click)="submit()" class="submit" type="button">Zamenjaj</button>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</div>
