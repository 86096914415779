import { Component, Input, OnChanges } from '@angular/core';
import { ApiService } from '@app/shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnChanges {

  visible$: Observable<boolean>;

  isDefault: boolean;

  @Input()
  area: string;

  constructor(protected api: ApiService) {
  }

  ngOnChanges() {
    this.visible$ = this.api.getHelpVisible(this.area);
    this.isDefault = this.api.getHelpDefault(this.area);
  }

  toggleDefault() {
    this.isDefault = this.api.toggleHelpDefault(this.area);
  }

  close() {
    this.api.setHelpVisible(this.area, false);
  }
}
