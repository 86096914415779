import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SubscriptionSink } from '@app/shared';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnDestroy {

  jwt: string;

  form: FormGroup;

  protected subscription = new SubscriptionSink();

  constructor(
    protected fb: FormBuilder,
    protected api: ApiService,
    protected route: ActivatedRoute,
    protected router: Router
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', Validators.required],
      password2: ['', Validators.required],
    });

    this.subscription.sink = this.route.paramMap.subscribe(params => this.jwt = params.get('jwt'));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    if (this.form.value.password !== this.form.value.password2) {
      alert('Gesli se ne ujemata!');
      return;
    }

    this.subscription.sink = this.api.authActivate({
      jwt: this.jwt,
      password: this.form.value.password
    }).subscribe(() =>
      this.router.navigate(['/'])
    );
  }

}
