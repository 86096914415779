<div class="banner">
  <img src="assets/graphics/welcome_banner.jpg">
  <div class="text">
    <div class="title">
      Dobrodošli
    </div>
    <div class="desc">
      v aplikaciji za načrtovanje<br> šolske prehrane
    </div>
    <button class="play-button" (click)="setVideo('bZ24P8zL6-c')">
      <i class="icon-play"></i>
      Poglej video predstavitev
    </button>
  </div>
</div>
<div class="tabs" [class.active]="tab">
  <div class="tab" [class.selected]="tab === 'new'" (click)="setTab('new')">
    <img src="assets/graphics/new_user_btn.jpg" *ngIf="!tab">
    <div class="text">
      <div class="title">NOV UPORABNIK</div>
      <img src="assets/graphics/new_user_icon.svg" width="140" *ngIf="!tab">
      <img src="assets/graphics/new_user_icon_plain.svg" width="50" *ngIf="tab">
      <button class="play-button">
        Vzpostavitev<br>baze
      </button>
    </div>
  </div>
  <div class="tab" [class.selected]="tab === 'existing'" (click)="setTab('existing')">
    <img src="assets/graphics/existing_user_btn.jpg" *ngIf="!tab">
    <div class="text">
      <div class="title">OBSTOJEČ UPORABNIK</div>
      <img src="assets/graphics/existing_user_icon.svg" width="140" *ngIf="!tab">
      <img src="assets/graphics/existing_user_icon_plain.svg" width="50" *ngIf="tab">
      <button class="play-button">
        Načrtovanje<br>prehrane
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="tab === 'new'">
  <div class="sub_banner">
    <img src="assets/graphics/new_user_banner.jpg">
    <div class="text">
      <div class="title">Začnite z vnosom podatkov</div>
      <div class="desc">potrebnih za delovanje aplikacije</div>
      <button class="play-button" (click)="setVideo('b70Xi8Acy78')"><i class="icon-play"></i>Poglej video demonstracijo</button>
    </div>
  </div>

  <div class="step step-alt">
    <div class="step-icon">
      <img src="assets/graphics/groups_icon.svg" width="87">
    </div>
    <div class="step-number">
      <span>1.</span>
    </div>
    <div class="step-text">
      <div class="step-title">Naročniki</div>
      <div class="step-desc">Vnesite podatke o številu naročnikov obrokov v vašem zavodu</div>
      <div class="step-attrs">
        <img src="assets/graphics/clock.svg" width="30">
        <div class="attr-label">Predviden<br>čas vnosa</div>
        <div class="attr-value">5-10 min.<br>/recept</div>
        <img src="assets/graphics/count.svg" width="30">
        <div class="attr-label">Trenutno<br>vnešenih</div>
        <div class="attr-value">{{ welcome.users }} naročnikov</div>
        <a class="step-link" [routerLink]="['/groups']">
          <img src="assets/graphics/welcome_link_button_bg.svg">
          <span>Vnesi naročnike</span>
        </a>
      </div>
    </div>
  </div>

  <div class="step">
    <div class="step-icon">
      <img src="assets/graphics/products_icon.svg" width="87">
    </div>
    <div class="step-number">
      <span>2.</span>
    </div>
    <div class="step-text">
      <div class="step-title">Živila</div>
      <div class="step-desc">Uredite bazo živil vašega zavoda</div>
      <div class="step-attrs">
        <img src="assets/graphics/clock.svg" width="30">
        <div class="attr-label">Predviden<br>čas vnosa</div>
        <div class="attr-value">2-3 min<br>/živilo</div>
        <img src="assets/graphics/count.svg" width="30">
        <div class="attr-label">Trenutno<br>vnešenih</div>
        <div class="attr-value">{{ welcome.products }} živil</div>
        <a class="step-link" [routerLink]="['/products']">
          <img src="assets/graphics/welcome_link_button_bg.svg">
          <span>Vnesi živila</span>
        </a>
      </div>
    </div>
  </div>

  <div class="step step-alt">
    <div class="step-icon">
      <img src="assets/graphics/recipe_icon.svg" width="98">
    </div>
    <div class="step-number">
      <span>3.</span>
    </div>
    <div class="step-text">
      <div class="step-title">Recepti</div>
      <div class="step-desc">Vnesite, katere recepte uporabljate pri pripravi hrane</div>
      <div class="step-attrs">
        <img src="assets/graphics/clock.svg" width="30">
        <div class="attr-label">Predviden<br>čas vnosa</div>
        <div class="attr-value">5-10 min.<br>/recept</div>
        <img src="assets/graphics/count.svg" width="30">
        <div class="attr-label">Trenutno<br>vnešenih</div>
        <div class="attr-value">{{ welcome.recipes }} receptov</div>
        <a class="step-link" [routerLink]="['/recipe']">
          <img src="assets/graphics/welcome_link_button_bg.svg">
          <span>Vnesi recepte</span>
        </a>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tab === 'existing'">
  <div class="sub_banner">
    <img src="assets/graphics/existing_user_banner.jpg">
    <div class="text">
      <div class="title">Z vnešenimi podatki lahko organizirate prehrano</div>
      <div class="desc">v vašem vzgojno-izobraževalnem zavodu</div>
      <button class="play-button" (click)="setVideo('xiPZ3pbk2Qc')"><i class="icon-play"></i>Poglej video demonstracijo</button>
    </div>
  </div>

  <div class="step step-alt">
    <div class="step-icon">
      <img src="assets/graphics/menu_icon.svg" width="87">
    </div>
    <div class="step-number">
      <span>1.</span>
    </div>
    <div class="step-text">
      <div class="step-title">Jedilniki</div>
      <div class="step-desc">Načrtujte uravnotežene dnevne, tedenske ali mesečne jedilnike,<br>ki so skladni s smernicami</div>
      <div class="step-attrs">
        <a class="step-link" [routerLink]="['/menu']">
          <img src="assets/graphics/welcome_link_button_bg.svg">
          <span>Sestavi jedilnike</span>
        </a>
      </div>
    </div>
  </div>

  <div class="step">
    <div class="step-icon">
      <img src="assets/graphics/preparation_icon.svg" width="87">
    </div>
    <div class="step-number">
      <span>2.</span>
    </div>
    <div class="step-text">
      <div class="step-title">Dobava</div>
      <div class="step-desc">Na osnovi jedilnikov pripravite in natisnite<br>dobavni seznam za nakup živil</div>
      <div class="step-attrs">
        <a class="step-link" [routerLink]="['/preparation']">
          <img src="assets/graphics/welcome_link_button_bg.svg">
          <span>Preveri dobavo</span>
        </a>
      </div>
    </div>
  </div>

  <div class="step step-alt">
    <div class="step-icon">
      <img src="assets/graphics/absence_icon.svg" width="87">
    </div>
    <div class="step-number">
      <span>3.</span>
    </div>
    <div class="step-text">
      <div class="step-title">Odsotnost</div>
      <div class="step-desc">Vodite dnevno odsotnost<br>naročnikov na dnevne obroke</div>
      <div class="step-attrs">
        <a class="step-link" [routerLink]="['/absence']">
          <img src="assets/graphics/welcome_link_button_bg.svg">
          <span>Vodi odsotnost</span>
        </a>
      </div>
    </div>
  </div>
</ng-container>

<div class="video-cnt" *ngIf="videoUrl">
  <div class="video-dlg">
    <iframe width="960" height="540" [src]="videoUrl" frameborder="0"></iframe>
    <button class="btn-close" (click)="videoUrl=null"></button>
  </div>
</div>
