import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiService,
  DatePages, downloadUrl, GastroToName,
  GetShoppingListResponse,
  ShoppingListProduct,
  SubscriptionSink
} from '@app/shared';
import { range } from 'lodash-es';
import * as dayjs from 'dayjs';
import { PrepReplaceComponent } from '@app/prep/prep-replace/prep-replace.component';

@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  styleUrls: ['./shopping-list.component.scss']
})
export class ShoppingListComponent implements OnInit, OnDestroy {

  @ViewChild(PrepReplaceComponent)
  replacer: PrepReplaceComponent;

  yearId: number;

  week: number;

  prevWeek: number;

  nextWeek: number;

  products: ShoppingListProduct[];

  pkgIndex: number[];

  currentDate: dayjs.Dayjs;

  totalCost: number;

  sortCol = 'supplier';

  sortAsc = true;

  workdates: {
    name: string,
    date: string;
  }[];

  allWorkdates: string[];

  protected subscription = new SubscriptionSink();

  constructor(protected route: ActivatedRoute, protected fb: FormBuilder, protected api: ApiService, protected router: Router) { }

  ngOnInit() {
    this.subscription.sink = this.route
      .data
      .subscribe(({ shoppingList }) => this.setValues(shoppingList));
  }

  protected setValues(response: GetShoppingListResponse) {
    this.yearId = response.yearId;
    this.week = response.week;
    this.prevWeek = response.prevWeek;
    this.nextWeek = response.nextWeek;
    this.currentDate = dayjs(response.startDate)
    this.products = response.products;
    this.pkgIndex = range(Math.max(1, ...response.products.map(p => p.packaging?.length ?? 0)));
    this.totalCost = response.totalCost;
    this.allWorkdates = response.allWorkDates;

    this.workdates = (response.workDates ?? []).map(date => {
      return {
        name: dayjs(date).format('dddd'),
        date,
      };
    });

    this.sort();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleSort(col: keyof ShoppingListProduct) {
    if (this.sortCol === col) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortCol = col;
      this.sortAsc = false;
    }
    this.sort();
  }

  protected sort() {
    if (this.products.length === 0) {
      return;
    }

    let kind: 'number' | 'string' | 'gastro';

    if (this.sortCol === 'gastro') {
      kind = 'gastro';
    } else if (['name', 'supplier', 'producer'].includes(this.sortCol)) {
      kind = 'string';
    } else {
      kind = 'number';
    }

    const dir = this.sortAsc ? 1 : -1;

    this.products.sort((a, b) => {
      const v1 = a[this.sortCol];
      const v2 = b[this.sortCol];

      if (kind === 'number') {
        return ((v1 ?? 0) - (v2 ?? 0)) * dir;
      } else if (kind === 'string') {
        return (v1 ?? '').localeCompare(v2 ?? '') * dir;
      } else if (kind === 'gastro') {
        return (GastroToName.get(v1) ?? '').localeCompare(GastroToName.get(v2) ?? '') * dir;
      }

      return 0;
    });
  }

  sortCls(col: string) {
    if (this.sortCol !== col) {
      return 'asc';
    } else if (this.sortAsc) {
      return 'asc active';
    }
    return 'desc active';
  }


  update(productId: number, packageId: number, amount: number) {
    this.api.menuSetShoppingPackageCount({
      yearId: this.yearId,
      week: this.week,
      productId,
      packageId,
      count: amount,
    }).subscribe(rv => this.setValues(rv));
  }

  replace([currentId, newId]: [number, number]) {
    this.api.menuReplaceShoppingProduct({
      yearId: this.yearId,
      week: this.week,
      oldProductId: currentId,
      newProductId: newId,
    }).subscribe(rv => this.setValues(rv));
  }

  reportWeek() {
    return this.api.reportShoppingList(this.yearId, this.week);
  }

  reportRecipes($event: Event) {
    const selectEl = $event.target as HTMLSelectElement;
    const date = selectEl.value;
    selectEl.selectedIndex = 0;
    downloadUrl(this.api.menuReportRecipes(this.yearId, date));
  }

  navigateDate(date: string) {
    const isoWeek = dayjs(date).isoWeek();
    this.router.navigate(['/preparation', isoWeek]);
  }

  reportYear() {
    return this.api.reportShoppingYear(this.yearId);
  }

  editProduct(id) {
    this.router.navigate(['/products', id], {queryParams: {return: this.router.url}});
  }
}
