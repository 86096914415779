import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MealSize } from '@app/shared';

@Component({
  selector: 'app-menu-size-picker',
  templateUrl: './menu-size-picker.component.html',
  styleUrls: ['./menu-size-picker.component.scss']
})
export class MenuSizePickerComponent implements OnInit {

  @Input()
  sizes: MealSize[];

  @Output()
  selected = new EventEmitter<MealSize>();

  constructor() { }

  ngOnInit(): void {
  }

  handleChange($event) {
    const index = $event.target.selectedIndex;
    if (this.sizes[index - 1]) {
      this.selected.emit(this.sizes[index - 1]);
    }
    $event.target.selectedIndex = 0;
  }

}
