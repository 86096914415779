import {
  AfterViewChecked,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AllGroupTypes,
  AllMealCodes,
  ApiService, CheckboxArrayOption, DietDetails, DietNamePipe, GROUP_TYPE_TO_SIZES,
  GroupMealRequest,
  GroupType, GroupTypePipe, MealNamePipe,
  MealSize,
  UpdateGroupRequest, AllDiets
} from '@app/shared';
import { pick, range } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type GroupFormValues = Omit<UpdateGroupRequest, 'id'> & {
  id?: number;
};

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnChanges, OnDestroy, AfterViewChecked {

  @ViewChildren('dietDescription')
  dietDescriptionElements: QueryList<ElementRef<HTMLTextAreaElement>>;

  @Input()
  yearId: number;

  @Input()
  value?: GroupFormValues;

  @Output()
  changed = new EventEmitter<GroupFormValues>();

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  remove = new EventEmitter<void>();

  @Output()
  dirty = new EventEmitter<boolean>();

  mealCodes = AllMealCodes;

  mealOptions: any;

  form: FormGroup;

  destroy$: Subject<void> = new Subject();

  groupTypesOptions: CheckboxArrayOption[];

  dietTypeOptions: CheckboxArrayOption[];

  constructor(private fb: FormBuilder, protected api: ApiService, groupTypePipe: GroupTypePipe, dietNamePipe: DietNamePipe, mealNamePipe: MealNamePipe) {
    this.groupTypesOptions = AllGroupTypes.map(type => ({
      title: groupTypePipe.transform(type),
      value: type,
    }));

    this.dietTypeOptions = AllDiets.map(diet => ({
      value: diet,
      title: dietNamePipe.transform(diet),
    }));

    this.mealOptions = mealNamePipe.shortOptions;
  }

  get sizeOptions(): MealSize[] {
    return GROUP_TYPE_TO_SIZES.get(this.form.value.type) ?? [];
  }

  asFormArray(ctrl: AbstractControl): FormArray {
    return (ctrl as FormArray);
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      console.warn(this.form.errors);
      return;
    }

    const values = this.form.value;

    const mealConfigs: GroupMealRequest[] = [];
    this.sizeOptions.forEach((mealSize, sizeIndex) =>
      AllMealCodes.forEach((mealCode, mealIndex) => {
        const count = values.meals[sizeIndex][mealIndex];
        if (count > 0) {
          mealConfigs.push({
            count,
            dietIndex: -1,
            meal: mealCode,
            size: mealSize,
          });
        }
      })
    );

    const dietConfigs: DietDetails[] = [];
    values.diets.forEach((diet, dietIndex) => {
      diet.meals.forEach(meal =>
        mealConfigs.push({
          dietIndex,
          count: 1,
          size: diet.mealSize,
          meal
        })
      );

      dietConfigs.push({
        id: diet.id,
        name: diet.name,
        description: diet.description,
        dietCodes: diet.dietCodes,
        mealSize: diet.mealSize,
      })
    });

    const group: GroupFormValues = {
      ...pick(values, ['name', 'type']),
      meals: mealConfigs,
      diets: dietConfigs,
    };

    this.changed.emit(group);
  }

  ngOnChanges() {
    if (!this.value) {
      this.form = this.createForm(0, 0);
      this.form.get('type').setValue(GroupType.Kindergarten);
      return;
    }

    const sizes = GROUP_TYPE_TO_SIZES.get(this.value.type);
    this.form = this.createForm(sizes.length, this.value.diets?.length ?? 0);

    const values = {
      ...pick(this.value, ['name', 'type']),
      meals: sizes.map(size =>
        AllMealCodes.map(meal => this.value.meals.find(m => m.size === size && m.meal === meal && m.dietIndex === -1)?.count ?? null)
      ),
      diets: this.value.diets?.map((diet, dietIndex) => {
        let meals = AllMealCodes.filter(meal => this.value.meals.findIndex(
          m => m.size === diet.mealSize && m.dietIndex === dietIndex && m.meal === meal && m.count > 0) !== -1
        );

        return {
          description: '',
          ...pick(diet, ['id', 'name', 'description', 'dietCodes', 'mealSize']),
          meals
        };
      }) ?? [],
    };

    this.form.setValue(values, { emitEvent: false });
    this.dirty.emit(false);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected uniqueDietName(index: number, control: AbstractControl) {
    if (this.form?.value?.diets?.some((d, i) => i !== index && d.name === control.value)) {
      return {
        nonUniqueDietName: true,
      };
    }
    return {};
  }

  protected createDietGroup(index: number) {
    return this.fb.group({
      id: [],
      name: [null, [Validators.required, this.uniqueDietName.bind(this, index)]],
      description: [null, Validators.required],
      mealSize: [null, Validators.required],
      dietCodes: [null, Validators.required],
      meals: [null, Validators.required],
    });
  }

  protected createForm(sizeCount: number, dietCount: number) {
    this.destroy$.next();

    const controls: { [k: string]: any } = {
      type: [null, Validators.required],
      name: [null, Validators.required],
      meals: this.createMealFormGroup(sizeCount),
      diets: this.fb.array(range(dietCount).map(index => this.createDietGroup(index))),
    };

    const form = this.fb.group(controls);

    form.get('type')
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((newType: GroupType) => {
        this.form.removeControl('meals');
        this.form.addControl('meals', this.createMealFormGroup(GROUP_TYPE_TO_SIZES.get(newType).length));
      });

    form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.dirty.emit(form.dirty));

    return form;
  }

  protected createMealFormGroup(sizeCount: number) {
    return this.fb.array(
      range(sizeCount).map(() =>
        this.fb.array(range(AllMealCodes.length).map(() => []))
      )
    );
  }

  addDiet() {
    const fa = (this.form.get('diets') as FormArray);
    fa.push(this.createDietGroup(fa.length));
    fa.markAsDirty();
    this.dirty.emit(true);
  }

  removeDiet(index: number) {
    this.asFormArray(this.form.get('diets')).removeAt(index);
    this.form.get('diets').markAsDirty();
    this.dirty.emit(true);
  }

  ngAfterViewChecked(): void {
    this.dietDescriptionElements?.forEach(el =>  {
      const natEl = el.nativeElement;
      natEl.style.height = 'auto';
      natEl.style.height = natEl.scrollHeight + 'px';
    });
  }

}
