import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberComponent),
      multi: true
    }
  ]
})
export class NumberComponent implements ControlValueAccessor {

  @Input()
  min = 0;

  @Input()
  max?: number;

  @Input()
  placeholder: string = '';

  value: number | null = null;

  disabled = false;

  protected changeListeners = [];

  constructor() { }

  protected notifyChangeListeners() {
    this.changeListeners.forEach(listener => listener(this.value));
  }

  writeValue(obj: any): void {
    if (typeof(obj) === 'number') {
      this.value = obj;
    } else {
      this.value = null;
    }
  }

  incr(delta: number) {
    if (this.min !== undefined && delta < 0 && this.value === null) {
      delta = 0;
    }

    if (delta < 0 && this.min !== undefined && this.value + delta < this.min) {
      return;
    }
    this.value += delta;
    if (this.max) {
      this.value = Math.min(this.value, this.max);
    }
    this.notifyChangeListeners();
  }

  change(event) {
    this.value = +event.target.value;
    if (this.value < this.min) {
      this.value = this.min;
    } else if (this.max && this.value > this.max) {
      this.value = this.max;
    }
    this.notifyChangeListeners();
  }

  registerOnChange(fn: any): void {
    this.changeListeners = [fn];
  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


}
