import { Component, Input, OnChanges } from '@angular/core';
import { Component as FoodComponent, Values } from '../api.abstract.service';
import { get } from 'lodash-es';

const commonParams = {
  [FoodComponent.TotalCarbohydrates]: {
    name: 'OH',
    cls: 'chot',
    color: '#AAD486',
  },
  [FoodComponent.Protein]: {
    name: 'P',
    cls: 'prot',
    color: '#FBD367',
  },
  [FoodComponent.Fat]: {
    name: 'M',
    cls: 'fat',
    color: '#AEE0F2',
  },
};

interface Components {
  componentTotalCarbohydrates: number;
  componentProtein: number;
  componentFat: number;
}

@Component({
  selector: 'app-component-pie',
  templateUrl: './component-pie.component.html',
  styleUrls: ['./component-pie.component.scss']
})
export class ComponentPieComponent implements OnChanges {

  @Input()
  values: Values | Components;

  @Input()
  showLegend = false;

  segments: {
    value: number,
    guideline?: [FoodComponent, number, number],
    name: string,
    cls: string,
    ratio: number,
    color: string,
  }[];

  constructor() { }

  ngOnChanges() {
    if (Array.isArray(this.values)) {
      const values = this.values;
      const value = (c: FoodComponent) =>
        get(values.find(([component]) => component === c), 1, 0);

      this.segments = [
        {
          value: value(FoodComponent.TotalCarbohydrates) * 4,
          ...commonParams[FoodComponent.TotalCarbohydrates]
        },
        {
          value: value(FoodComponent.Protein) * 4,
          ...commonParams[FoodComponent.Protein]
        },
        {
          value: value(FoodComponent.Fat) * 9,
          ...commonParams[FoodComponent.Fat]
        }
      ].map(v => ({
        ...v,
        ratio: 0,
      }));
    } else if (this.values) {
      this.segments = [
        {
          value: this.values.componentTotalCarbohydrates * 4,
          ...commonParams[FoodComponent.TotalCarbohydrates]
        },
        {
          value: this.values.componentProtein * 4,
          ...commonParams[FoodComponent.Protein]
        },
        {
          value: this.values.componentFat * 9,
          ...commonParams[FoodComponent.Fat]
        }
      ].map(v => ({
        ...v,
        ratio: 0,
      }));
    } else {
      this.segments = undefined;
    }
    if (this.segments) {
      const total = this.segments.reduce((c, s) => c + s.value, 0);
      if (total > 0) {
        this.segments.forEach(s => s.ratio = s.value / total);
      }
      this.segments = this.segments.filter(({value}) => value > 0);
    }
  }

}
